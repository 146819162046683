import axios from 'axios';
import { getUserToken } from '../utils/token';

const api = axios.create({
  baseURL: window.location.host.includes('stage')
    ? process.env.REACT_APP_HOST_STAGE
    : process.env.REACT_APP_HOST,
  // baseURL: "http://localhost:3010",
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS,POST,GET,PUT,PATCH,DELETE',
    // Authorization: `Bearer ${process.env.REACT_APP_ECOMMERSYS_APP_TOKEN}`,
  },
});

api.interceptors.request.use((config: any) => {
  const token = getUserToken();

  if (config.headers === undefined) config.headers = {};

  if (token) {
    config.headers['x-access-token'] = token;
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const affiliatedCode = getCookie('affilatedCode');
  config.headers['x-affiliate-code'] = affiliatedCode;

  config.headers[
    'Authorization'
  ] = `Bearer ${process.env.REACT_APP_ECOMMERSYS_APP_TOKEN}`;

  return config;
});

export { api };
