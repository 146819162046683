import { Button } from '@mui/material';

const Tests = () => {
  const getProducts = () => {};
  return (
    <>
      <Button onClick={getProducts}>Trigger</Button>
    </>
  );
};

export default Tests;
