import { useEffect } from 'react';
import { NotificationsProvider } from 'src/contexts/NotificationsContext';
import { GetRoutes } from 'src/routes/routes';
import { BoxShowNotifications } from '../BoxShowNotifications';

const App = () => {
  useEffect(() => {
    function setCookie(name, value, days) {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Expira em "days" dias
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    }

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    if (
      window.location.search.includes('partner') &&
      !getCookie('affilatedCode')
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const affilatedCode = urlParams.get('partner');

      setCookie('affilatedCode', affilatedCode, 1); // Define o cookie para expirar em 1 dia
    }

    // Função para desabilitar o zoom de dois dedos
    const disablePinchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    // Adicionar o evento ao documento
    document.addEventListener('touchmove', disablePinchZoom, {
      passive: false,
    });

    // Remover o evento quando o componente for desmontado
    return () => {
      document.removeEventListener('touchmove', disablePinchZoom);
    };
  }, []);

  return (
    <>
      <NotificationsProvider>
        <BoxShowNotifications />
        <GetRoutes />
      </NotificationsProvider>
    </>
  );
};

export { App };
