import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Grid, Typography } from '@mui/material';
import { notificationTypes } from 'ecommersys/dist/Entities/notification.entitie';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNotifications } from 'src/contexts/NotificationsContext';
import { useUser } from 'src/contexts/UserContext';
import { PopUpDefault } from '../Dialogs/PopUpDefault';

const ShowNotifyComponent = ({
  notifySelected,
  backgroundColorComponent = 'rgba(255,0,0,0.5)',
  handleReadNotification,
}: {
  notifySelected: Partial<notificationTypes>;
  backgroundColorComponent?: string;
  handleReadNotification: Function;
}) => {
  return (
    <Box
      className="box_notification"
      key={notifySelected.title + notifySelected._id}
      sx={{
        width: '100%',
        background: backgroundColorComponent,
        backdropFilter: 'blur(7px)',
        mb: 0,
        padding: '10px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Grid
            container
            sx={{ width: '100%', justifyContent: 'center' }}
            spacing="1rem"
          >
            <Grid item sx={{ alignSelf: 'center', justifyContent: 'center' }}>
              <Typography variant="h5" color="#FFFF">
                {notifySelected?.title}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                alignSelf: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="body1" color="#FFFF">
                {notifySelected?.message}
              </Typography>
            </Grid>
            <Grid item sx={{ alignSelf: 'center', justifyContent: 'center' }}>
              {typeof notifySelected.path !== 'undefined' && (
                <Link
                  style={{
                    color: '#fff',
                    border: '1px solid #fff',
                    padding: '2px 15px',
                    borderRadius: '10px',
                  }}
                  onClick={() => handleReadNotification(notifySelected._id)}
                  to={notifySelected?.path}
                >
                  Clique aqui
                </Link>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box>
          {!notifySelected.isFixed && (
            <Button
              onClick={() => handleReadNotification(notifySelected._id)}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                padding: '6px 8px',
              }}
            >
              <HighlightOffIcon fontSize="small" htmlColor="#fff" />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const BoxShowNotifications = () => {
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);

  const {
    globalAllNotifications,
    sellerAllNotifications,
    userAllNotifications,
    handleGetGlobalAllNotifications,
    handleGetSellerAllNotifications,
    handleGetUserAllNotifications,
    handleReadNotification,
    notification,
    setNotification,
  } = useNotifications();

  const { pathname } = useLocation();

  const { user, isAuthenticated } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        handleGetGlobalAllNotifications();
        handleGetSellerAllNotifications();
        handleGetUserAllNotifications();
      }, 5000);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (
      globalAllNotifications?.length > 0 ||
      sellerAllNotifications?.length > 0 ||
      userAllNotifications?.length > 0
    ) {
      if (user.storeId !== '' && sellerAllNotifications?.length > 0) {
        setNotification(
          sellerAllNotifications[sellerAllNotifications?.length - 1],
        );
      } else {
        setNotification(userAllNotifications[userAllNotifications?.length - 1]);
      }

      setIsShowNotification(true);
    } else {
      setNotification({});
    }
  }, [globalAllNotifications, sellerAllNotifications, userAllNotifications]);

  const verifySomeNotification =
    globalAllNotifications?.length > 0 ||
    sellerAllNotifications?.length > 0 ||
    userAllNotifications?.length > 0;

  if (!isShowNotification) {
    return <></>;
  } else {
    return (
      <Box
        sx={{
          display:
            (notification?._id && !notification?.openPopup) ||
            verifySomeNotification
              ? 'flex'
              : 'none',
          flexDirection: 'column',
          marginBottom:
            (notification?._id && !notification?.openPopup) ||
            verifySomeNotification
              ? '0px'
              : '0px',
        }}
      >
        {globalAllNotifications?.length > 0 && (
          <ShowNotifyComponent
            backgroundColorComponent="#c52cf2"
            notifySelected={
              globalAllNotifications[globalAllNotifications?.length - 1]
            }
            handleReadNotification={handleReadNotification}
          />
        )}

        {notification && !notification.openPopup && (
          <Box
            sx={{
              zIndex: 999999,
            }}
          >
            <ShowNotifyComponent
              notifySelected={notification}
              handleReadNotification={handleReadNotification}
            />
          </Box>
        )}

        {notification && notification.openPopup && (
          <Box
            sx={{
              zIndex: 999999,
            }}
          >
            <PopUpDefault
              data={notification}
              handleReadNotification={handleReadNotification}
            />
          </Box>
        )}
      </Box>
    );
  }
};
