import { ptAdminJSON } from './pt_pages/admin';
import { ptCategoriesJSON } from './pt_pages/categories';
import { ptClientsJSON } from './pt_pages/clients';
import { ptCommissionJSON } from './pt_pages/commission';
import { ptCouponsJSON } from './pt_pages/coupons';
import { ptDocumentsJSON } from './pt_pages/documents';
import { ptMyAccountJSON } from './pt_pages/myAccount';
import { ptNotificationsJSON } from './pt_pages/notications';
import { ptPoliciesJSON } from './pt_pages/policies';
import { ptProductsJSON } from './pt_pages/products';

const enJSON = {
  ...ptCategoriesJSON,
  ...ptProductsJSON,
  ...ptCouponsJSON,
  ...ptClientsJSON,
  ...ptPoliciesJSON,
  ...ptDocumentsJSON,
  ...ptMyAccountJSON,
  ...ptAdminJSON,
  ...ptCommissionJSON,
  ...ptNotificationsJSON,
  Blueprints: 'Planos',
  'Extended Sidebar': 'Menu Lateral',
  'Mega menu': 'Mega menu',
  Doctors: 'Doutores',
  Reports: 'Informes',
  'Custom dashboard built using the included components':
    'Panel de controle personalizado construido com os componentes incluidos.',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Last year': 'Ano Anterior',
  Expenses: 'Gastos',
  Statistics: 'Estadísticas',
  General: 'Geral',
  Automation: 'Automatização',
  'Data Display': 'Visualização de Dados',
  Calendar: 'Calendário',
  Mailbox: 'Caixa de Mensagens',
  Dashboards: 'Dashboard',
  Analytics: 'Analítico',
  Banking: 'Financeiro',
  Commerce: 'Loja',
  Crypto: 'Cripto',
  Finance: 'Finças',
  Fitness: 'Atividades Físicas',
  Healthcare: 'Cuidado com a Saúde',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descrição do hospital',
  Helpdesk: 'Tickets Suporte',
  Learning: 'Aprendizado',
  Monitoring: 'Supervisão',
  Tasks: 'Tarefas',
  Applications: 'Aplicaciones',
  'Jobs Platform': 'Plataforma de trabalhos',
  Messenger: 'Mensagens',
  'Projects Board': 'Painel de projeto',
  Management: 'Administração',
  Users: 'Usuários',
  'List All': 'Listar tudo',
  'User Profile': 'Perfil do usuário',
  Projects: 'Projetos',
  Shop: 'Venda',
  Invoices: 'Faturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticação',
  Login: 'Acesso',
  Basic: 'Básico',
  Cover: 'Cobrir',
  Register: 'Registrar',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar senha',
  Status: 'Status',
  Foundation: 'Fundação',
  'Extra Pages': 'Páginas extras',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Coming Soon',
  Maintenance: 'Manutenção',
  Overview: 'Visão geral',
  'Layout Starters': 'Layout Inicial',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentacão',
  Welcome: 'Bem Vindo',
  Help: 'Ajuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Suporte de contato',
  Version: 'Versão',
  Search: 'Buscar',
  Notifications: 'Notificações',
  Settings: 'Ajustes',
  'Language Switcher': 'Seletor de idioma',
  'Sign out': 'Desconectar',
  'Change Theme': 'Mudar de tema',
  'View all notifications': 'Ver todas as notificações',
  'Tokyo NextJS Typescript Admin Dashboard': 'Panel de administração',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Visão em tempo real',
  'Key Features': 'Características chave',
  dashboards: 'Dashboard',
  applications: 'Aplicações',
  'management sections': 'Gestão de Sessões',
  'dark/light themes': 'temas escuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',
  'Active Referrals': 'Vendas Ativas',
  'Pending Invitations': 'Vendas Pendentes',
  'Bounce Rate': 'Taxa de rejeição',
  Conversion: 'Taxa de Conversão',
  'Sessions by Country': 'Vendas por País',
  'Audience Overview': 'Histórico de Visualizações',
  'New users': 'Usuários novos',
  'Page views': 'Visualizações de página',
  'See referring domains': 'Ver Vendas Ativas',
  'See all invitations': 'Ver Vendas Pendentes',
  'See all links': 'Ver todas as taxas de rejeição',
  'See products': 'Ver todas as taxas de conversão',
  'Sales Today': 'Vendas Hoje',
  'Customers per month': 'Vendas ao mês',
  'Earnings Report': 'Relatório de recebíveis',
  'My account': 'Minha conta',
  'Active tasks': 'Tarefas ativas',
  'Profile settings': 'Ajustes de Perfil',
  'total value': 'valor total',
  'Search messages': 'Procurar mensagens',
  'Mark all as seen': 'Marcar todas como visto',
  'View all participants': 'Ver todas as mensagens',
  Timeline: 'Linha do tempo',
  'View all': 'Ver tudo',
  'TASKS FOR TODAY': 'TAREFAS PARA HOJE',
  Details: 'Detalhes',
  Create: 'Criar',
  View: 'Visualizar',
  Period: 'Periodo de',
  'Most Buyed Items': 'Itens mais comprados',
  'Customer Satisfaction': 'Satisfação do Cliente',
  'See how satisfied are customers with this helpdesk':
    'Veja como está a satisfação dos seus clientes',
  "We couldn't find any products matching your search criteria":
    'Não conseguimos encontrar nenhum produto com essa requisição',
  Code: 'Código',
  Description: 'Descrição',
  'Type of Discount': 'Tipo de desconto',
  'Allow free shipping': 'Permitir frete grátis',
  Store: 'Loja',
  'Show store': 'Mostrar loja',
  Type: 'Tipo',
  Amount: 'Montante',
  'Limit of use': 'Limite de Uso',
  'Expiration date': 'Vencimento do Cupom',
  Actions: 'Ações',
  'Additional Informations': 'Informações adicionais',
  Inventory: 'Inventário',
  Shipping: 'Envio',
  Standard: 'Padrão',
  Publish: 'Publicar',
  Edit: 'Editar',
  Visible: 'Visível',
  Visibility: 'Visibilidade',
  'SEO Score': 'Índice de SEO',
  Good: 'Bom',
  Peview: 'Peview',
  'Save draft': 'Salvar rascunho',
  'Publish now': 'Publicar agora',
  Categories: 'Categorias',
  'Add category': 'Adicionar categoria',
  Electronics: 'Eletrônicos',
  Appliances: 'Eletrodomésticos',
  'Outdoor equipment': 'Equipamento ao ar livre',
  'Digital products': 'Produtos digitais',
  'Software memberships': 'Associações de software',
  'Drag & drop files here': 'Arraste e solte o arquivo aqui',
  new: 'novo',
  fresh: 'fresco',
  electronics: 'eletrônicos',
  computer: 'computador',
  software: 'programas',
  Preview: 'Visualizar',
  Save: 'Salvar',
  description: 'Descrição',
  'Option 1': 'Opção 1',
  'Option 2': 'Opção 2',
  Quantity: 'Quantidade',
  'Add to card': 'Adicionar ao carrinho',
  'Shop Storefront': 'LOJA',
  Share: 'Compartilhe',
  'Write review': 'Escrever Avaliação',
  Reviews: 'Avaliações',
  Helpful: 'Útil ',
  'Customer Reviews': 'Avaliações',

  Hello: 'Olá',
  'Check the latest banking stats under this beautiful dashboard!':
    'Verifique as últimas estatísticas bancárias neste belo painel!',
  Transfers: 'Transferencias',
  Bills: 'Contas',
  Requests: 'Solicitações ',
  Payments: 'Pagamento',
  ' more than last week': ' mais do que na semana passada',
  ' less than last week': ' menos que na semana passada',
  'Main Account': 'Conta principal',
  Available: 'Disponível',
  'Link accounts': 'Vincular contas',
  'Transfer money': 'Transferir dinheiro',
  'Deutsche Bank': 'Banco alemão',
  'Wells Fargo': 'Wells Fargo',
  'Transactions Statistics': 'Estatísticas de Transações',
  Investments: 'Investimentos',
  Income: 'Renda',
  Taxes: 'Impostos',
  'View all investments': 'Ver todos os investimentos',
  income: 'Renda',
  expenses: 'Despesas',
  'Gross Sales': 'Vendas brutas',
  Reimbursement: 'Reembolso',
  'increase this month': 'aumentar este mês',
  'decrease in orders amounts': 'diminuição nos valores dos pedidos',
  'compared to previous month': 'em relação ao mês anterior',
  'Last month': 'Mês passado',
  Yesterday: 'Ontem',
  Today: 'Hoje',
  'Overall Status': 'Status geral',

  'Invoices - Management': 'Faturas - Gestão',
  Invoice: 'Fatura',
  'All recent invoices can be found below':
    'Todas as faturas recentes podem ser encontradas abaixo',
  'Create invoice': 'Criar recibo',
  ' Use this modal dialog to create a new invoice':
    'Use esta caixa de diálogo modal para criar uma nova fatura',
  'The invoice number field is required':
    'O campo do número da fatura é obrigatório',
  'Write here any additional informations you might have...':
    'Escreva aqui qualquer informação adicional que você possa ter...',
  'Additional informations': 'Informações adicionais',
  'Preview invoice': 'Visualizar fatura',
  "We couldn't find any invoices matching your search criteria":
    'Não encontramos nenhuma fatura que corresponda aos seus critérios de pesquisa',
  'Invoice Details - Management': 'Detalhes da Fatura - Gestão',
  'Show all': 'Mostre tudo',
  'Pending Payment': 'Pagamento Pendente',
  Completed: 'Concluído',
  Draft: 'Esboço, projeto',
  'In Progress': 'Em progresso',
  'Search invoices by client name ...':
    'Pesquise faturas pelo nome do cliente...',
  'View all invoices': 'Ver todas as faturas',
  Storefront: 'Loja',
  'This is a list of all commerce products':
    'Esta é uma lista de todos os produtos comerciais',
  'Change cover': 'Mudar capa',
  'Price Range': 'Faixa de preço',
  Showing: 'Mostrando',
  summary: 'resumo',
  'Add to cart': 'Adicionar ao carrinho',
  Chats: 'Chat',
  All: 'Todo',
  Unread: 'Não lida',
  Archived: 'Arquivado',
  Meetings: 'Reuniões ',
  'Investors Council Meeting': 'Reunião do Conselho de Investidores',
  Attend: 'Comparecer',
  'Write your message here...': 'Branco sua mensagem aqui...',
  Invisible: 'Invisível',

  Progress: 'Progresso',
  'Users Analytics': 'Análise de usuários',
  'Weekly sales': 'Vendas semanais',
  'Reports for what we sold this week':
    'Relatórios do que vendemos esta semana',
  Cars: 'carros',
  Outdoor: 'Exterior',
  'View complete report': 'Ver relatório completo',
  Accounts: 'Contas',
  Subscriptions: 'Assinaturas',
  'High performance React template built with lots of powerful components across multiple product niches for fast & perfect apps development processes':
    'Modelo React de alto desempenho construído com muitos componentes poderosos em vários nichos de produtos para processos de desenvolvimento de aplicativos rápidos e perfeitos',
  'Live transations': 'Transações ao vivo',
  'Total Visitors': 'Total de visitantes',
  'Total Orders': 'Pedidos Totais',
  'Total Budget': 'Orçamento total',
  'Total Deliveries': 'Entregas totais',
  'Navigation Pills': 'Pílulas de navegação',
  'This is yet another example for navigation':
    'Este é mais um exemplo de navegação',
  'Recent Activity': 'Atividade recente',
  'Your recent crypto activities': 'Suas atividades criptográficas recentes',
  Activity: 'Atividade',
  Trading: 'Negociação',
  'View more items': 'Ver mais itens',
  'View all activity': 'Ver todas as atividades',
  'Latest issues': 'Últimos problemas',
  'Users list': 'Lista de usuários',
  'You added a product to cart': 'Você adicionou o produto ao carrinho',
  'In Stock': 'Em estoque',
  "You won't be able to revert after deletion":
    'Você não poderá reverter após a exclusão',
  Delete: 'Excluir',
  Delet: 'Excluir',
  Cancel: 'Cancelar',
  'Sold individually ': 'Vendido Separadamente',
  "'Enable this to only allow one of this item to be bought in a single order'":
    'Habilite isso para permitir que apenas um deste item seja comprado em um único pedido',
  'Do you really want to delete this invoice':
    'Você quer realmente deletar essa fatura ',

  'Last wish': 'Último Pedido',
  'Money spent': 'Dinheiro gasto',
  Localization: 'Localização',
  User: 'Usuario',
  'Order notes': 'Notas do pedido',
  'Additional Information (optional)': 'Informações adicionais  (opcional)',
  'Shipping Addresses': 'Endereços de Entrega',
  'saved addresses': 'endereços salvos',
  'Saved Locations': 'Locais Salvos',
  Manage: 'Configurar',
  Cards: 'Cartões',
  'saved cards': 'cartão salvos',
  'Product Name': 'Nome do Produto',
  price: 'Preço',
  stock: 'Estoque',
  rating: 'Avaliação',
  Category: 'Categoria',
  'Category Hierarchy': 'Hierarquia da categoria',
  Name: 'Nome',
  user: 'Usuario',
  store: 'Loja',
  localization: 'Localização',
  requests: 'Solicitações',
  'money spent': 'Dinheiro Gasto',
  'last wish': 'Último Pedido',
  'Rows per page': 'Linhas por página',
  'Issued Date': 'data de emissão',
  number: 'numero',
  'Due Date': 'data de vencimento',
  'Client Name': 'nome do cliente ',
  amount: 'montante',
  currency: 'moeda',
  status: 'status',
  'Type of Coupons': 'Tipo do Cupom',
  'Coupon Start': 'Date de inicio do cupom',
  Coupons: 'Cupons',
  'Select Product': 'Selecione o produto',
  'Select Category': 'Selecione a Categoria',
  'Select Client': 'Selecione o cliente',
  'Choose a client': 'Escolha um cliente',
  'Choose a category': 'Escolha uma  Categoria',
  'Choose a product': 'Escolha um produto',
  'Coupon usage limit': 'Limite de uso do cupom',
  'Usage limit per customert': 'Limite de uso por cliente',
  'Minimum spend for use': 'Gasto mínimo',
  'Maximum spend for use': 'Gasto máximo',
  'Free Shipping': 'Frete grátis',
  Fixed: 'Fixo',
  Percentage: 'Porcentagem',
  'Coupon validity': 'Data de termino do Cupom',
  'Client Assigned': 'Cliente',
  'Create product': 'Criar Produto',
  "We couldn't find any order matching your search criteria":
    'Não conseguimos encontrar nenhum pedido',
  'List of Orders': 'Lista de Pedidos',
  'Use this page to manage your Orders, the fast and east way':
    'Use este meio para ver seus pedidos',
  products: 'produtos',
  Addresses: 'Endereços',
  'Complete your order here!': 'Complete seu pedido aqui!',
  "We couldn't find any clients matching your search criteria":
    'Não foi possível encontrar nenhum cliente que corresponda aos seus critérios de pesquisa',
  'Use this page to manage your Orders, the fast and easy way.':
    'Use esta página para gerenciar seus pedidos de maneira rápida e fácil.',
  'On this screen you manage (insert/change/delete) all products, images, values, dimensions, category of your store.':
    'Nesta tela você gerencia (inserir/alterar/excluir) todos os produtos, imagens, valores, dimensões, categorias da sua loja.',
  'Take care at this stage, because your customer will see it in your store as stated here':
    'Cuidado nesta etapa, pois seu cliente verá em sua loja conforme informado aqui',
  'There is no product created to present. Register your first product using the button above - Register Product':
    'Não existe produto criado para apresentar. Cadastre seu primeiro produto no botão acima - Cadastrar Produto',
  'Register Product': 'Cadastrar Produto',
  'Write tags that identify your product. They will not be presented to the client, but will be used to improve the category indexing experience and the assertiveness of the search engines. write single words and click the + button': `
  Escreva tags que identifiquem seu produto. Eles não serão apresentados ao cliente, mas servirão para melhorar a experiência de indexação de categorias e a assertividade dos buscadores. Escreva palavras únicas e clique no botão +`,
  'Write the name of the product that will be displayed...':
    'Escreva o nome do produto que será exibido...',
  'Describe all the details of this product...':
    'Descreva todos os detalhes deste produto...',
  'Active Product': 'Ativar Produto',
  'Clear Categories': 'Limpar Categoria',
  'Regular price': 'Valor normal R$',
  'Regular price here ...': 'Valor normal aqui ...',
  'Sale price': 'Valor com desconto',
  'Sale price here ...': 'Valor com desconto aqui ...',
  'Stock Quantity': 'Quantidade no Estoque',
  'Stock quantity here ...': 'Quantidade no Estoque aqui ...',
  'Attention: The values ​​declared here affect the value of the freight, so be precise in the information declared here':
    'Atenção: Os valores decladores aqui, influenciam no valor do frete,portante seja preciso(a) nas informações decladaras aqui',
  'Declare the weight of the package in KGs. If grams, use decimal. Ex: 500 g = 0.5':
    'Declare o peso da embalagem em KGs. Caso seja gramas, utilizar decimal. Ex: 500 gr = 0,5',
  Height: 'Altura',
  'Write Height ...': 'Escreva Altura...',
  width: 'Largura',
  'Write width ...': 'Escreva Largura...',
  Length: 'Comprimento',
  'Write Length ...': 'Escreva Comprimento...',
  'Help!': 'Ajuda!',
  'Encourage new sales by offering differentiated conditions through coupons.':
    'Incentive novas vendas oferecendo condições diferenciadas através de cupons.',
  'Coupons are accepted and applied in the marketplace checkout session.':
    'Os cupons são aceitos e aplicados na sessão de checkout do marketplace.',
  'There is no coupon created. Register your first product using the button above - Add Coupon':
    ' Não existe cupom criado. Cadastre seu primeiro produto no botão acima - Adicionar Cupom',
  'Add Coupon': ' Adicionar Cupom',
  'Percentage: Defines the percentage discount value to be applied to the total order value.':
    'Define o valor percentual de desconto a ser aplicado no valor total do pedido.',

  'Fixed: Defines a fixed value ': 'Define um valor fixo em Reais(R$) ',

  'Free Shipping: It exempts the customer from the freight amount due, calculated at checkout.':
    'Isenta o cliente do valor de frete devido, calculado no checkout.',

  'POLICY DEVOLUTION': 'POLÍTICA DE DEVOLUÇÃO ',
  'POLICY PAYMENT': 'POLÍTICA DE PAGAMENTO ',
  'POLICY REPAYMENT': 'POLÍTICA DE REEMBOLSO',
  'POLICY EXCHANGE': 'POLÍTICA DE TROCA',
  devolution: 'devolução',
  payment: 'pagamento',
  repayment: 'reembolso',
  exchange: 'troca',
  'DEVOLUTION / EXCHANGE': 'Devolução / Troca',
  DEVOLUTION: 'devolução',
  PAYMENT: 'pagamento',
  REPAYMENT: 'reembolso',
  EXCHANGE: 'troca',
  'Name / Coupon code': 'Nome / código do cupom',
  Used: 'Utilizado(s)',
  'Your orders': 'Seus Pedidos',
  'Last Name': 'Sobrenome',
  'Type of Person': 'Tipo de pessoa',
  'Nº PRODUCTS': 'Nº Produtos',
  PAYMENT_CONFIRMED: 'Pagamento Confirmado',
  CREDIT_CARD: 'Cartão de Crédito',
  CONFIRMED: 'Confirmado',
  Payment: 'Pagamento',

  OVERDUE: 'ATRASADO',
  PENDING: 'PENDENTE',
  RECEIVED: 'RECEBIDO',

  'Confirm Payment': 'Confirmação de pagamento',
  'View Invoice': 'Ver fatura',
  'Invoice for': 'Fatura para',
  'Issued on': 'Emitida em',

  Stores: 'Lojas',
  'Use this page to manage your Stores, the fast and easy way.':
    'Use esta página para gerenciar suas Lojas, de forma rápida e fácil.',
  'Orders list': 'Lista de pedidos',
  'Check the orders placed here': 'Confira aqui os pedidos feitos',
  'Search  by client name ...': 'Pesquise pelo nome do cliente...',
  'Search by order...': 'Pesquise por ordem...',
  'Show All': 'Mostrar Tudo',

  Zipcode: 'CEP',
  Adress: 'Endereço',
  Number: 'Número',
  Neighborhood: 'Bairro',
  State: 'Estado',
  Complement: 'Complemento',
  City: 'Cidade',
  Phone: 'Telefone',
  'Delivery Rules ': 'Regras de Entrega',
  'Payment rules ': 'Regras de pagamento ',
  'Is Free shipping ?': 'Frete gratis ?',
  'Min value Delivery': 'Valor minimo para entrega.',
  'Payment Installments': 'Limite de Parcelas',
  'Min value for Payment': 'Valor minimo para parcelas',
  'Remove Rule': 'Remover regra',
  'Remove rule': 'Remover regra',

  'Search for a store ...': 'Procure uma loja...',
  'Select One': 'Selecione um Status',
  'Search request...': 'Solicitação de pesquisa...',
  'Go back to all store': 'Voltar para todas as lojas',

  Client: 'Cliente',
  Order: 'Pedido',
  'Go back to all stores': 'Voltar para todas as lojas',
  'Client Info': 'Informações do Cliente',
  'Client Adress': 'Endereço do Cliente',
  'Store Adress': 'Endereço da Loja',
  'Store Details': 'Informações da Loja',

  'Management Client': 'Gestão do Cliente',

  'Person type': 'Tipo',

  orders: 'Pedidos',

  'Payment Status': 'Status de Pagamento',

  'Client Email': 'Email',
  'Client Phone': 'Telefone',

  'Total Buyed': 'Total Comprado',
  'Last Wish': 'Ultimo Pedido',

  'Do you really want to delete this document':
    'Deseja realmente excluir este documento ',

  'Do you really want to delete this Coupom':
    'Deseja realmente excluir este cupom ',

  'Do you really want to delete this Product':
    'Deseja realmente excluir este produto ',

  Active: 'Ativo',

  'Min Value:': 'Valor mínimo: ',
  'Max Value:': 'Valor máximo: ',
  'No Free Shipping': 'Sem Frete Gratis ',
  'Limite for Use:': 'Limite de Uso ',
  'Description: ': 'Descrição: ',
  'Product Select:': 'Seleção de produto: ',
  'Category Select:': 'Seleção de categoria: ',
  'Client Select:': 'Seleção de cliente: ',
  'Start date:': 'Data de início: ',
  'End date:': 'Data final: ',

  'Type: fixed': 'Tipo: fixo',
  'Type: percentage': 'Tipo: porcentage',
  'Type: shipping': 'Tipo: envio',
  'Value: ': 'Valor:',
  'Edit info store': 'Editar dados da loja',
  'Auditing...': 'Analisando',
  Auditing: 'Analisando',
  Valid: 'Aprovado',
  Invalid: 'Recusado',
  'POLICY DEVOLUTION / EXCHANGE': 'POLÍTICA DE DEVOLUÇÃO OU TROCA',
  'Add Rule': 'Adicionar Regra',
  'Devolution or Exchange': 'devolução ou troca',
  'POLICY OF DEVOLUTION OR EXCHANGE': 'POLÍTICA DE DEVOLUÇÃO OU TROCA',

  RECEITUARIO: 'RECEITUÁRIO(S)',
  Filename: 'Nome do Arquivo',

  'Date Upload': 'Data de atualização',
  'Updated at': 'Atualizado em',

  Validate: 'Validar',
  'Manage Solicitations': 'Gerenciar Solicitações',
  'Use this page to manage your seller solicitations.':
    'Use esta página para gerenciar suas solicitações de vendedor.',

  "We couldn't find any sellersolicitation matching your search criteria":
    'Não foi possível encontrar nenhuma solicitação de vendedor que corresponda aos seus critérios de pesquisa',

  'Search by sellersolicitation...': 'Pesquise por solicitação do vendedor...',

  Files: 'Documentos',
  'Check the orders placed here.': 'Confira aqui os pedidos feitos.',
  'Search  by doc ...': 'Procurar por documento...',
  of: 'de',

  Invalidate: 'Recusar',

  cnpj: 'Cnpj',
  cpf: 'Cpf',
  identidadePessoal: 'Indentidade Pessoal',
  contratoSocial: 'Contrato Social',
  cro: 'Cro',
  crm: 'Crm',
  crbm: 'Crbm',
  crf: 'Crf',
  crt: 'Crt',
  alvara: 'Alvará',
  receituario: 'Receituário ',
  cartaCiencia: 'Carta Ciência',
  lgpd: 'Lgpd',
  certificado: 'Certificado ',

  clients: 'clientes',
  coupons: 'cupons',
  stores: 'lojas',
  Solicitations: 'solicitações',
  policies: 'políticas',
  categories: 'categorias',
  REFUNDED: 'Devolvido',
};

export default enJSON;
