import { useEffect, useState } from 'react';

const useViewport = () => {
  const [viewport, setViewport] = useState({
    height: 0,
    isSmallMobile: false,
    isMobile: false,
    isTablet: false,
    width: 0,
  });

  const handleResize = () => {
    const isSmallMobile: boolean = window.innerWidth <= 576;
    const isMobile: boolean = window.innerWidth < 768;
    const isTablet: boolean =
      window.innerWidth >= 768 && window.innerWidth < 991;

    setViewport({
      height: window.innerHeight,
      isMobile: isMobile,
      isSmallMobile: isSmallMobile,
      isTablet: isTablet,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewport;
};

export default useViewport;
