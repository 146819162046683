import { CircularProgress, useTheme } from '@mui/material';
import axios from 'axios';
import { Address, User } from 'ecommersys/dist/Entities';
import {
  PaymentMethodType,
  tokenizeType,
} from 'ecommersys/dist/Entities/paymentMethod.entitie';
import React, {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sdkManager } from 'src/configs/ecommersys.config';
import { sdk } from 'src/sdkProvider';
import {
  getUserToken,
  removeUserToken,
  updateUserToken,
} from 'src/utils/token';
import { validateToken } from 'src/utils/validateToken';
import { api } from '../services/axiosInstance';
import { useSDK } from './sdkContext';

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    sdkManager.userToken(accessToken);
  } else {
    localStorage.removeItem('accessToken');
    delete api.defaults.headers['x-access-token'];
  }
};

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [notRememberToken, setNotRememberToken] = useState();
  const [UserData, setUserData] = useState<UserData>(InitialUserData);
  const { t }: { t: any } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [addresses, setAddresses] = useState<Partial<Address>[]>([
    InitialAddress,
  ]);

  const [paymantMethod, setPaymantMethod] =
    useState<tokenizeType>(InitialPaymantMethod);

  const [cards, setCards] = useState<Partial<PaymentMethodType>[]>([
    InitialCards,
  ]);

  const [card, setCard] = useState<Partial<tokenizeType>>(InitialPaymantMethod);

  const [singleAddress, setSingleAddress] =
    useState<Partial<Address>>(InitialAddress);

  const { initialize: initializeSDK } = useSDK();

  const theme = useTheme();

  const handleInputsCard = (event: any): void => {
    setCard((state) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        [event.target.name]: event?.target?.value,
      },
    }));
  };

  const handleInputsCreditCardHolderInfo = (event: any): void => {
    setCard((state) => ({
      ...state,
      creditCardHolderInfo: {
        ...state.creditCardHolderInfo,
        [event.target.name]: event?.target?.value,
      },
    }));
  };

  const handleInputsAddress = (event: any): void => {
    setSingleAddress((state) => ({
      ...state,
      [event.target.name]: event?.target?.value,
    }));
  };

  const getMyCards = () => {
    sdk.User.account.getMyPaymentMethod((res) => {
      setCards(res.result);
    });
  };

  const getMyAddress = () => {
    sdk.User.account.getMyAddress((res) => {
      setAddresses(res.result);
    });
  };

  const handleSubmitNewAddress = (fc: Function) => {
    const verifyContainsEmptyValue = Object.keys(singleAddress).filter(
      (key) => {
        if (key === 'complement') return false;

        if (!singleAddress[key]) return true;

        if (singleAddress[key] && singleAddress[key].trim() === '') return true;

        return false;
      },
    );

    if (verifyContainsEmptyValue[0]) {
      toast(`Ops, todos os campos devem ser preenchidos!`, {
        type: 'error',
        autoClose: 3000,
      });
      return;
    }
    sdk.User.account.addAddress(singleAddress, (res) => {
      getMyAddress();
    });

    fc();
    toast('Endereço cadastrado com sucesso.', {
      type: 'success',
      autoClose: 3000,
    });
  };

  const handleSubmitNewPaymentMethod = async (): Promise<boolean> => {
    let paymantMethodCard = paymantMethod;

    paymantMethodCard.customer = UserData.user.gatewayPagId;
    paymantMethodCard.creditCard = card.creditCard;
    paymantMethodCard.creditCardHolderInfo = card.creditCardHolderInfo;

    return new Promise((resolve) => {
      sdk.User.account.addPaymentMethod(
        paymantMethodCard,
        (res) => {
          const returnText = t('You successfully add the Payment Method');
          toast(returnText, { type: 'success' });
          getMyCards();
          resolve(true);
        },
        (err) => {
          let returnText = err.message;

          toast(returnText, { type: 'error' });
          resolve(false);
        },
      );
    });
  };

  const handleSearchAddressForCEP = async () => {
    await axios
      .get(`https://viacep.com.br/ws/${singleAddress.zipCode}/json/`)
      .then((res) => {
        if (res.data) {
          setSingleAddress((state) => ({
            ...state,
            address: res.data.logradouro,
            city: res.data.localidade,
            state: res.data.uf,
            zipCode: res.data.cep,
            district: res.data.bairro,
            country: 'Brasil',
          }));
        } else {
          toast(
            'Não foi possível encontrar seu endereço, digite de forma manual.',
            { type: 'error', autoClose: 3000 },
          );
        }
      })
      .catch((err) => {
        toast(
          'Não foi possível encontrar seu endereço, digite de forma manual.',
          { type: 'error', autoClose: 3000 },
        );
      });
  };

  const initialize = async (accessToken: string): Promise<void> => {
    setLoadingMessage('Iniciando serviços...');
    await initializeSDK();

    const doNotHaveLogged = () => {
      setLoading(false);
    };
    if (!accessToken) {
      return doNotHaveLogged();
    } else {
      setSession(getUserToken());
      try {
        const validate = await validateToken(accessToken);
        setLoadingMessage('Sua sessão está iniciada, validando dados.');

        if (!validate) {
          removeUserToken();
          return doNotHaveLogged();
        }
        const user: User = await getMyUser();

        if (!user) {
          removeUserToken();
          toast('não foi encontrado usuário', { type: 'error' });
          throw new Error('não foi encontrado usuário');
        }

        setTimeout(() => {
          setUserData((storeData) => {
            storeData = { isAuthenticated: true, user };
            return storeData;
          });

          setLoading(false);
        }, 2000);
      } catch (err) {
        removeUserToken();
        setUserData((storeData) => {
          storeData = { isAuthenticated: false, user: null };
          return storeData;
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const accessToken = getUserToken();
    initialize(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyUser = async () =>
    await sdk.User.account.getMyUser(async (result) => {
      setUserData((userD: any) => ({ ...userD, user: result }));
      return result;
    });

  const updateUser = async (data: any, key?: string) => {
    // setUserData((storeData) => {
    //   const update = key ? { ...storeData.user, [key]: data } : data;
    //   storeData = { ...storeData, user: update };
    //   return storeData;
    // });
    // const updateUser = await sdk.User.account.updateUserInfo(
    //   UserData.user._id,
    //   {
    //     [key]: data,
    //   }
    // );
    // if (updateUser.isError) throw new Error(updateUser.isError);
  };

  const handleSetDefault = (
    id: string,
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
    callbackSelectedAddress?: Function,
  ) => {
    e.preventDefault();
    if (UserData.user.userInfo.defaultAddress !== id) {
      sdk.User.account.setDefaultAddress(id, () => {
        toast('Endereço atualizado com sucesso!', { type: 'success' });
        getMyUser();
        callbackSelectedAddress && callbackSelectedAddress();
      });
    }
  };

  const login = async ({
    username,
    password,
    loading,
  }: {
    username: string;
    password: string;
    loading?: any;
  }): Promise<void> => {
    // const response = await api.post("auth", { username, password });

    if (!username || !password) {
      throw new Error('Preencha todos os dados para continuar');
    }
    const alreadyExistAffilatedCode =
      window.localStorage.getItem('affilatedCode');

    await sdk.User.account.authUser(
      { password, username, affilatedCode: alreadyExistAffilatedCode },
      async (response) => {
        const { accessToken } = response;

        setSession(accessToken);
        updateUserToken(accessToken);

        // await initialize(accessToken);

        toast.update(loading, {
          render: 'Autenticado com sucesso! Seja bem-vindo!',
          type: 'success',
          isLoading: false,
          autoClose: 1000,
        });

        setUserData((storeData) => {
          storeData.isAuthenticated = true;
          return storeData;
        });

        setTimeout(() => {
          document.location.reload();
        }, 1000);
      },
      (err) => {
        toast.update(loading, {
          render: 'Login ou Senha inválidos...',
          type: 'error',
          isLoading: false,
          autoClose: 1000,
        });
      },
    );
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    setUserData((storeData) => {
      storeData = { isAuthenticated: false, user: null };
      return storeData;
    });
  };

  const register = async (AuthData): Promise<void> => {
    const form = new FormData();
    for (let data in AuthData) {
      if (data !== 'userInfo') {
        form.append(data, AuthData[data]);
      } else {
        for (let userData in AuthData.userInfo) {
          form.append(`${data}[${userData}]`, AuthData.userInfo[userData]);
        }
      }
    }

    const response = await api.post('/users', AuthData);
    // await User.account.createNewUser(form);

    // if (response.isError) throw new Error("não foi possível criar usuário");
    // api.post("addUser", AuthData);

    // window.localStorage.setItem("accessToken", accessToken);

    setUserData((storeData) => {
      storeData = { isAuthenticated: false, user: response.data };
      return storeData;
    });
  };

  const handleUpdateUserImage = (e: any) =>
    sdk.User.account.updateUserImage(
      { id: UserData.user._id, img: e[0] },
      () => {
        toast('Imagem de perfil atualizada com sucesso!', { type: 'success' });
        getMyUser();
      },
    );

  const values = {
    loading,
    user: UserData.user,
    isAuthenticated: UserData.isAuthenticated,
    setUser: updateUser,
    getMyUser,
    register,
    login,
    logout,
    notRememberToken,
    setNotRememberToken,
    singleAddress,
    handleInputsAddress,
    handleSubmitNewAddress,
    getMyAddress,
    addresses,
    handleSearchAddressForCEP,
    handleSetDefault,
    handleUpdateUserImage,
    handleSubmitNewPaymentMethod,
    handleInputsCard,
    handleInputsCreditCardHolderInfo,
    card,
    cards,
    getMyCards,
    setCard,
  };
  return (
    <UserContext.Provider value={values}>
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '1rem',
          opacity: loading ? 1 : 0,
          position: 'absolute',
          transition: 'all 2s ease',
          pointerEvents: loading ? 'auto' : 'none',
          zIndex: 1000,
          backgroundColor: '#FFFF',
        }}
      >
        {loading && (
          <>
            <CircularProgress
              size={100}
              style={{ color: theme.colors.primary.dark }}
            />
            <p style={{ color: '#aaa', fontFamily: 'Roboto' }}>
              {loadingMessage}
            </p>
          </>
        )}
      </div>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

const UserContext = createContext<userValues>(null);

type userValues = {
  loading: boolean;
  user: User;
  isAuthenticated: boolean;
  setUser: Function;
  setCard: React.Dispatch<React.SetStateAction<tokenizeType>>;
  getMyUser: Function;
  register: Function;
  login: Function;
  logout: Function;
  handleSubmitNewPaymentMethod: () => Promise<boolean>;
  notRememberToken: boolean;
  setNotRememberToken: Function;
  singleAddress: Partial<Address>;
  card: Partial<tokenizeType>;
  handleInputsCreditCardHolderInfo: (
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  handleInputsCard: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInputsAddress: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmitNewAddress: (fc: Function) => void;
  getMyAddress: () => void;

  getMyCards: () => void;
  addresses: Partial<Address>[];
  cards: Partial<PaymentMethodType>[];
  handleSearchAddressForCEP: () => void;
  handleSetDefault: (
    id: string,
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
    callbackSelectedAddress?: Function,
  ) => void;
  handleUpdateUserImage: (img: File) => void;
};

type UserData = {
  isAuthenticated: boolean;
  user: User;
};

const InitialUserData = {
  isAuthenticated: false,
  user: null,
};

const InitialAddress = {
  address: '',
  number: null,
  city: '',
  state: '',
  country: '',
  zipCode: '',
};

const InitialCards = {
  _id: '',
  owner: '',
  creditCardNumber: '',
  creditCardBrand: '',
  creditCardToken: '',
};

const InitialPaymantMethod = {
  customer: '',
  creditCard: {
    holderName: '',
    number: '',
    expiryMonth: '',
    expiryYear: '',
    ccv: '',
  },
  creditCardHolderInfo: {
    name: '',
    email: '',
    cpfCnpj: '',
    postalCode: '',
    addressNumber: '',
    addressComplement: '',
    phone: '',
    mobilePhone: '',
  },
};

type AuthData = {
  username: '';
  password: '';
  userInfo: {
    name: '';
    lastName: '';
    cpf: '';
    address: '';
    number: '';
    complement: '';
    city: '';
    UserData: '';
    cep: '';
    email: '';
  };
};

export { AuthProvider, useUser };
