import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ConfirmPopOver } from 'src/components/PopOvers/ConfirmPopOver';
import { useCart } from 'src/contexts/UserCartContext';

export const CouponBox = () => {
  const [couponInput, setCouponInput] = useState('');
  const { insertCoupon, cart } = useCart();

  return (
    <Grid container spacing={1} py={2}>
      <Grid item xs={12} sx={{ textAlign: 'left' }}>
        <Typography>Você tem cupom de desconto?</Typography>
        <Typography>Se você tiver um cupom, o adicione abaixo:</Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          id="outlined-password-input"
          fullWidth
          value={couponInput}
          onChange={(e) => setCouponInput(e.target.value)}
          inputProps={{ style: { padding: 10 } }}
        />
      </Grid>
      <Grid item xs={3}>
        <ConfirmPopOver
          component={(handleClose) => {
            return (
              <ConfirmBox
                handleClose={handleClose}
                handleConfirm={() => insertCoupon(couponInput)}
              />
            );
          }}
          validate={couponInput.length > 0}
        >
          <Button variant="contained">Aplicar</Button>
        </ConfirmPopOver>
      </Grid>
      <Grid item xs={12}>
        {cart &&
          cart.coupons &&
          cart.coupons[0] &&
          cart.coupons.map((item) => {
            return (
              <Chip
                key={item}
                label={item.split('/')[1]}
                variant="outlined"
                onDelete={() => insertCoupon(item)}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

const ConfirmBox = ({
  handleClose,
  handleConfirm,
}: {
  handleClose: Function;
  handleConfirm: Function;
}) => {
  return (
    <Grid container>
      <Grid
        p={2}
        display="grid"
        sx={{ placeItems: 'center', width: '100%' }}
        gap={4}
      >
        <Typography variant="h3">Deseja aplicar o cupom?</Typography>
        <Box display="flex">
          <Button color="error" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button
            color="success"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Aplicar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
