/* eslint-disable */
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { SellerProductsProvider } from "src/contexts/SellerProductsContext";
import { SellerClientsProvider } from "src/contexts/SellerClientsContext";
import { SellerCouponsProvider } from "src/contexts/SellerCouponsContext";
import { SellerOrdersProvider } from "src/contexts/SellerOrdersContext";
import { AdminCommissionProvider } from "src/contexts/AdminCommissionContext";

import * as Imports from "./Imports";
import { SolicitationProvider } from "src/contexts/SolicitationsContext";
import { UserDocumentsProvider } from "src/contexts/UserDocumentsContext";
import { AdminNotificationsProvider } from "src/contexts/AdminNotificationsContext";
import { AdminStoresProvider } from "src/contexts/AdminStoresContext";
import { AdminOrdersProvider } from "src/contexts/AdminOrdersContext";
import { AdminCLientsProvider } from "src/contexts/AdminClientsContext";
import { ProductsProvider } from "src/contexts/productsContext";
import { EditStoreProvider } from "src/contexts/EditStoreContext";

export const adminRoutes: RouteObject[] = [
  {
    path: "admin",
    element: (
      <SellerOrdersProvider>
        <ProductsProvider>
          <AdminCommissionProvider>
            <AdminOrdersProvider>
              <Outlet />
            </AdminOrdersProvider>
          </AdminCommissionProvider>
        </ProductsProvider>
      </SellerOrdersProvider>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="resume" replace />,
      },

      {
        path: "resume",
        element: <Imports.HomeDashboardAdmin />,
      },

      {
        path: "checkout",
        element: <Imports.Checkout />,
      },

      {
        path: "chatbox",
        element: <Imports.ChatBox />,
      },

      {
        path: "files",
        element: <Imports.Files />,
      },

      {
        path: "banking",
        element: <Imports.Banking />,
      },

      {
        path: "files",
        element: (
          <UserDocumentsProvider>
            <Outlet />
          </UserDocumentsProvider>
        ),
        children: [
          {
            path: "",
            element: <Imports.AdminFiles />,
          },
        ],
      },

      {
        path: "myStore",
        children: [
          {
            path: "edit",
            element: (
              <EditStoreProvider>
                <Imports.StoreInfo />
              </EditStoreProvider>
            ),
          },
        ],
      },

      {
        path: "products",
        element: (
          <SellerProductsProvider>
            <Outlet />
          </SellerProductsProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            children: [
              {
                path: "",
                element: <Imports.Product />,
              },
              {
                path: ":id",
                element: <Imports.Pedidos />,
              },
            ],
          },

          { path: ":id", element: <Imports.CreateProduct /> },

          { path: "create", element: <Imports.CreateProduct /> },

          {
            path: "preview/:id",
            element: <Imports.SingleProduct />,
          },

          {
            path: "shop",
            element: <Imports.ShopProducts />,
          },

          {
            path: "wishlist",
            element: <Imports.WishList />,
          },

          {
            path: "politics",
            element: <Imports.Politics />,
          },
        ],
      },
      {
        path: "coupons",
        element: (
          <SellerCouponsProvider>
            <Outlet />
          </SellerCouponsProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            element: <Imports.Coupons />,
          },

          {
            path: "create",
            element: <Imports.CreateCoupons />,
          },

          {
            path: ":id",
            element: <Imports.CreateCoupons />,
          },
        ],
      },

      {
        path: "categories",
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            element: <Imports.Categories />,
          },

          {
            path: ":id",
            element: <Imports.CreateCategories />,
          },

          {
            path: "create",
            element: <Imports.CreateCategories />,
          },
        ],
      },
      {
        path: "clients",
        element: (
          <AdminCLientsProvider>
            <AdminOrdersProvider>
              <Outlet />
            </AdminOrdersProvider>
          </AdminCLientsProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            element: <Imports.adminClients />,
          },

          {
            path: "Show",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowClient />,
              },
            ],
          },

          {
            path: "edit",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowClientEdit />,
              },
            ],
          },

          {
            path: "invoice",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowClientInvoice />,
              },
            ],
          },

          {
            path: "order",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowClientOrder />,
              },
            ],
          },
        ],
      },

      {
        path: "stores",
        element: (
          <AdminStoresProvider>
            <AdminOrdersProvider>
              <SellerOrdersProvider>
                <Outlet />
              </SellerOrdersProvider>
            </AdminOrdersProvider>
          </AdminStoresProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            element: <Imports.Stores />,
          },

          {
            path: "Show",
            children: [
              {
                path: ":id",
                element: <Imports.ShowStores />,
              },
            ],
          },

          {
            path: "edit",
            children: [
              {
                path: ":id",
                element: <Imports.EditStores />,
              },
            ],
          },

          {
            path: "order",
            children: [
              {
                path: ":id",
                element: <Imports.ShowOrder />,
              },
            ],
          },

          {
            path: "invoices",
            children: [
              {
                path: ":id",
                element: <Imports.ShowInvoices />,
              },
            ],
          },

          {
            path: "products",
            children: [
              {
                path: ":id",
                element: <Imports.productStore />,
              },
            ],
          },
        ],
      },

      {
        path: "orders",
        element: (
          <AdminOrdersProvider>
            <SellerOrdersProvider>
              <Outlet />
            </SellerOrdersProvider>
          </AdminOrdersProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },

          {
            path: "list",
            children: [
              {
                path: "",
                element: <Imports.adminOrders />,
              },
              {
                path: ":id",
                element: <Imports.adminOrders />,
              },
            ],
          },

          {
            path: "Show",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowOrderst />,
              },
            ],
          },
          {
            path: "Invoices",
            children: [
              {
                path: ":id",
                element: <Imports.adminShowInvoice />,
              },
            ],
          },
        ],
      },

      {
        path: "store",
        children: [
          {
            path: "solicitations",
            element: (
              <SolicitationProvider>
                <Outlet />
              </SolicitationProvider>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: <Imports.Solicitations />,
              },
              {
                path: ":id",
                element: <Imports.SolicitationsView />,
              },
            ],
          },
        ],
      },

      {
        path: "orders",
        element: (
          <SellerOrdersProvider>
            <Outlet />
          </SellerOrdersProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },
          {
            path: "list",
            element: <Imports.Pedidos />,
          },

          {
            path: ":id",
            element: <Imports.SinglePedidos />,
          },
          {
            path: "status",
            element: <Imports.StatusOrders />,
          },
          {
            path: "purchase",
            element: <Imports.Purchase />,
          },
        ],
      },

      {
        path: "commission",
        element: (
          <AdminCommissionProvider>
            <Outlet />
          </AdminCommissionProvider>
        ),
        children: [
          {
            path: "",
            element: <Imports.Commission />,
            // element: <Navigate to="list" replace />,
          },
        ],
      },

      {
        path: "notifications",
        element: (
          <AdminNotificationsProvider>
            <Outlet />
          </AdminNotificationsProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },
          {
            path: "list",
            element: <Imports.Notifications />,
          },
          {
            path: ":id",
            element: <Imports.CreateNotifications />,
          },
          {
            path: "create",
            element: <Imports.CreateNotifications />,
          },
        ],
      },
    ],
  },
];
