import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import * as Imports from './Imports';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Tests from 'src/components/tests';
import { AdminStoresProvider } from 'src/contexts/AdminStoresContext';
import { EditStoreProvider } from 'src/contexts/EditStoreContext';
import { SellerClientsProvider } from 'src/contexts/SellerClientsContext';
import { SellerOrdersProvider } from 'src/contexts/SellerOrdersContext';
import { SellerPolicyProvider } from 'src/contexts/SellerPolicyContext';
import { SellerProductsProvider } from 'src/contexts/SellerProductsContext';
import { UserDocumentsProvider } from 'src/contexts/UserDocumentsContext';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const sellerRoutes = [
  {
    path: 'seller',
    element: (
      <SellerOrdersProvider>
        <SellerPolicyProvider>
          <Outlet />
        </SellerPolicyProvider>
      </SellerOrdersProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="inicio" replace />,
      },
      {
        path: 'inicio',
        element: <Imports.HomeDashboard />,
      },
      {
        path: 'myAccount',
        element: <Imports.MyAccount />,
      },
      {
        path: 'checkout',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Imports.Checkout />,
          },
        ],
      },
      {
        path: 'tests',
        element: <Tests />,
      },
      {
        path: 'store',
        element: <Imports.Loja />,
      },
      // {
      //   path: "chatbox",
      //   element: <Imports.ChatBox />,
      // },
      {
        path: 'files',
        element: (
          <UserDocumentsProvider>
            <Outlet />
          </UserDocumentsProvider>
        ),
        children: [
          {
            path: '',
            element: <Imports.Files />,
          },
        ],
      },
      {
        path: 'myStore',
        children: [
          {
            path: 'edit',
            element: (
              <EditStoreProvider>
                <AdminStoresProvider>
                  <Imports.StoreInfo />
                </AdminStoresProvider>
              </EditStoreProvider>
            ),
          },
        ],
      },

      {
        path: 'banking',
        element: <Imports.Banking />,
      },
      {
        path: 'products',
        element: (
          <SellerProductsProvider>
            <Outlet />
          </SellerProductsProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },

          {
            path: 'list',
            children: [
              {
                path: '',
                element: <Imports.Product />,
              },
              {
                path: ':id',
                element: <Imports.Product />,
              },
            ],
          },

          { path: ':id', element: <Imports.CreateProduct /> },
          { path: 'create', element: <Imports.CreateProduct /> },
          {
            path: 'preview/:id',
            element: <Imports.SingleProduct />,
          },
          {
            path: 'shop',
            element: <Imports.ShopProducts />,
          },
          {
            path: 'wishlist',
            element: <Imports.WishList />,
          },
          {
            path: 'politics',
            element: <Imports.Politics />,
          },
        ],
      },
      // {
      //   path: "coupons",
      //   element: (
      //     <SellerCouponsProvider>
      //       <Outlet />
      //     </SellerCouponsProvider>
      //   ),
      //   children: [
      //     {
      //       path: "",
      //       element: <Navigate to="list" replace />,
      //     },
      //     {
      //       path: "list",
      //       element: <Imports.Coupons />,
      //     },
      //     {
      //       path: "create",
      //       element: <Imports.CreateCoupons />,
      //     },
      //     {
      //       path: ":id",
      //       element: <Imports.CreateCoupons />,
      //     },
      //   ],
      // },
      {
        path: 'categories',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Categories />,
          },
          {
            path: ':id',
            element: <Imports.CreateCategories />,
          },
          {
            path: 'create',
            element: <Imports.CreateCategories />,
          },
        ],
      },
      {
        path: 'clients',
        element: (
          <SellerClientsProvider>
            <Outlet />
          </SellerClientsProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Clients />,
          },
          {
            path: 'show',
            children: [
              {
                path: ':id',
                element: <Imports.ShowClient />,
              },
            ],
          },

          {
            path: 'order',
            children: [
              {
                path: ':id',
                element: <Imports.ShowClientOrder />,
              },
            ],
          },
        ],
      },
      {
        path: 'orders',
        element: (
          <UserDocumentsProvider>
            <Outlet />
          </UserDocumentsProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            children: [
              {
                path: '',
                element: <Imports.Pedidos />,
              },
              {
                path: ':id',
                element: <Imports.Pedidos />,
              },
            ],
          },
          {
            path: ':id',
            element: <Imports.SinglePedidos />,
          },
          {
            path: 'status',
            element: <Imports.StatusOrders />,
          },
          {
            path: 'purchase',
            element: <Imports.Purchase />,
          },
          {
            path: 'invoices/:id',
            element: <Imports.InvoiceOrders />,
          },
        ],
      },

      {
        path: 'requests',
        element: (
          <SellerOrdersProvider>
            <Outlet />
          </SellerOrdersProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Requests />,
          },

          {
            path: ':id',
            element: <Imports.SingleRequests />,
          },
          {
            path: 'invoices/:id',
            element: <Imports.InvoiceOrdersRequests />,
          },
        ],
      },

      {
        path: 'policies',
        element: (
          <SellerPolicyProvider>
            <Outlet />
          </SellerPolicyProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Policies />,
          },
          {
            path: ':id',
            element: <Imports.CreatePolicies />,
          },
          {
            path: 'create',
            element: <Imports.CreatePolicies />,
          },
        ],
      },

      {
        path: 'checkout',
        element: <Imports.Checkout />,
      },
      {
        path: 'chat',
        element: <Imports.Messenger />,
      },
      {
        path: 'calendar',
        element: <Imports.Calendar />,
      },
    ],
  },
];

export default sellerRoutes;
