// import { Copyright } from "@mui/icons-material";
import { Box, CssBaseline } from '@mui/material/';
import { Link } from 'react-router-dom';
import { FormRegister } from 'src/components/Forms/FormRegister';
import { RegisterProvider } from 'src/contexts/RegisterContext';
import { imgs } from '../../assets/imgs';

const RegisterPage = () => {
  return (
    <Box
      style={{
        display: 'flex',
        // height: "100vh",
        padding: '2rem 0',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
      }}
    >
      <RegisterProvider>
        <CssBaseline />
        <Box
          sx={{
            // margin: "1em 0",
            minWidth: '350px',
            maxWidth: '700px',
            display: 'flex',
            padding: '10px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              margin: '1rem',
              gap: '1.2em',
              width: '100%',
            }}
          >
            <Link
              to="/login"
              style={{
                width: '85px',
                height: '85px',
                display: 'flex',
                borderRadius: '10px',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                boxShadow: '0 4px 5px 0 rgba(0,0,0,0.1)',
              }}
            >
              <img
                src={`${imgs.logoRedondo3}`}
                style={{ width: '200px', height: '200px' }}
                alt="logo"
              />
            </Link>
            <Box>
              <h1 style={{ fontWeight: 'bold', fontSize: '1.4em' }}>
                Crie sua conta aqui.
              </h1>
              <p style={{ color: '#999', fontSize: '0.8em' }}>
                Preencha os campos abaixo.
              </p>
            </Box>
          </Box>
          <Box>
            <FormRegister />
          </Box>
        </Box>
      </RegisterProvider>
    </Box>
  );
};

export default RegisterPage;
