import ecommersys from 'ecommersys';
import { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { sdk } from 'src/sdkProvider';

const SDKContext = createContext<contextSDKTypes>(null);

export const SDKProvider = ({ children }: React.PropsWithChildren) => {
  const [connected, setConnected] = useState(false);
  const SDK = new ecommersys();
  const Global = sdk.Global;
  const Seller = sdk.Seller;
  const User = sdk.User;

  const initialize = async () => {
    const connect = await SDK.connect({
      appToken: process.env.REACT_APP_ECOMMERSYS_APP_TOKEN,
    });

    if (connect.isError) return toast('não foi possível conectar');
    setConnected(connect.isSuccess);
  };

  const values = { connected, Global, Seller, User, initialize };
  return <SDKContext.Provider value={values}>{children}</SDKContext.Provider>;
};

export const useSDK = () => useContext(SDKContext);

type contextSDKTypes = {
  connected: boolean;
  Global: typeof sdk.Global;
  Seller: typeof sdk.Seller;
  User: typeof sdk.User;
  initialize: () => Promise<any>;
};
