import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from 'src/theme/ThemeProvider';
import 'swiper/css/bundle';
import { App } from './components/App/App';
import CartShop from './components/Drawers/CartShop';
import { SidebarProvider } from './contexts/SidebarContext';
import { CartProvider } from './contexts/UserCartContext';
import { AuthProvider } from './contexts/UserContext';
import { SDKProvider } from './contexts/sdkContext';
import './sass/principal.scss';
import store from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ThemeProvider>
    <SnackbarProvider
      maxSnack={6}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <SDKProvider>
        <Provider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <SidebarProvider>
                <HelmetProvider>
                  <CartProvider>
                    <StyledEngineProvider injectFirst>
                      <CartShop />
                      <App />
                    </StyledEngineProvider>
                  </CartProvider>
                </HelmetProvider>
              </SidebarProvider>
            </AuthProvider>
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
            />
          </BrowserRouter>
        </Provider>
      </SDKProvider>
    </SnackbarProvider>
  </ThemeProvider>,
);
