import * as Imports from 'src/routes/Imports';

const SingleStore = () => {
  return (
    <section className="lowPadding">
      <Imports.Loja />
    </section>
  );
};

export default SingleStore;
