import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DeleteTwoTone, LaunchTwoTone } from '@mui/icons-material';
import { Suspense, lazy, useState } from 'react';
import {
  formatReal,
  pathCurrentOrNextNavigate,
  showDateTimeString,
  statusActive,
} from 'src/utils/functions';

import { BoxConfirm } from '../Dashboard/Policies/BoxConfirm';
import { ConfirmDialog } from './ConfirmDialog';
import { Coupon } from 'ecommersys/dist/Entities';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import SuspenseLoader from '../SuspenseLoader';
import { useSellerCoupons } from 'src/contexts/SellerCouponsContext';
import { useTranslation } from 'react-i18next';

export const CouponDialong = ({
  children,
  data,
  review,
}: {
  children: React.ReactNode;
  data: Partial<Coupon>;
  review?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { handleDeleteCompleted } = useSellerCoupons();
  const toggleOpen = () => setOpen((state) => !state);
  return (
    <>
      <Box onClick={toggleOpen}>{children}</Box>
      <Dialog
        sx={{
          height: '100vh',
        }}
        PaperProps={{
          style: { minWidth: '30vw', padding: '2rem' },
        }}
        open={open}
        onClose={toggleOpen}
      >
        <Grid container>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Typography variant="h3">{t(`Cupom: ${data.name}`)}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ pb: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="h5">{t(`Type: ${data.type}`)}</Typography>
            <Typography variant="h5" sx={{ ml: 2 }}>
              {t(`Value: `)} {formatReal(data.value)}
            </Typography>
            <Typography variant="h5" sx={{ ml: 2 }}>
              {statusActive(data.isActive)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ pb: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="subtitle2">
              {t(`Start date:`)} {showDateTimeString(data.createdAt)}
            </Typography>
            <Typography variant="subtitle2" sx={{ ml: 2 }}>
              {t(`End date:`)}
              {showDateTimeString(data.endDate)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Box display={'flex'}>
              <Typography variant="h5" sx={{ pr: 0.5 }}>
                {t(`Min Value:`)}
              </Typography>
              <Typography sx={{ pr: 2 }}>
                {formatReal(data.minValue)}
              </Typography>
            </Box>

            <Box display={'flex'}>
              <Typography variant="h5" sx={{ pr: 0.5 }}>
                {t(`Max Value:`)}
              </Typography>
              <Typography sx={{ pr: 2 }}>
                {formatReal(data.minValue)}
              </Typography>
            </Box>

            {!data.isFreeShipping ? (
              <Typography variant="h5" sx={{ pr: 2 }}>
                {t(`No Free Shipping`)}
              </Typography>
            ) : (
              <Typography variant="h5" sx={{ pr: 2 }}>
                {t(`Free Shipping`)}
              </Typography>
            )}

            <Box display={'flex'}>
              <Typography variant="h5" sx={{ pr: 0.5 }}>
                {t(`Limite for Use:`)}
              </Typography>
              <Typography sx={{ pr: 2 }}>{data.limitForUse}</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="h5" sx={{ pr: 2 }}>
              {t(`Description: `)}
            </Typography>
            <Typography>{data.description}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="h5" sx={{ pr: 2 }}>
              {t(`Product Select:`)}
            </Typography>
            <Typography>{data.productsAssigned}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="h5" sx={{ pr: 2 }}>
              {t(`Category Select:`)}
            </Typography>

            <Typography>
              {t(
                `${data.categoriesAssigned.map(
                  (categories) => categories.split('/')[1],
                )}`,
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
          >
            <Typography variant="h5" sx={{ pr: 4 }}>
              {t(`Client Select:`)}
              {data.clientsAssigned}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 2, wordWrap: 'break-word' }}
            display={'flex'}
            justifyContent={'right'}
          >
            <ConfirmDialog
              component={(handleClose) => {
                return (
                  <BoxConfirm
                    title="Coupom"
                    confirmDelete={() => handleDeleteCompleted(data._id)}
                    handleClose={handleClose}
                  />
                );
              }}
            >
              <Tooltip title={t('Delete')} arrow>
                <IconButton color="primary">
                  <DeleteTwoTone fontSize="medium" />
                </IconButton>
              </Tooltip>
            </ConfirmDialog>
            <Tooltip title={t('Edit')} arrow>
              <IconButton
                component={RouterLink}
                to={`${pathCurrentOrNextNavigate()}/coupons/${data._id}`}
                color="primary"
              >
                <EditIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
