import { sdk } from '../sdkProvider';
// import { t } from "i18next";
import {
  ChangeEvent,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { getAllProps } from 'ecommersys/dist/interfaces';

import { useTranslation } from 'react-i18next';

import { Coupon } from 'ecommersys/dist/Entities';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { initialCoupomState } from 'src/components/Dashboard/Coupons/configs/tableConfig';
import { AdminClientType } from 'src/models/client';
import { ProductType } from 'src/models/product';
import { api } from 'src/services/axiosInstance';

const SellerCouponsContext = createContext<props>(null);

export const SellerCouponsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { t }: { t: any } = useTranslation();

  const [coupon, setCoupon] = useState<Partial<Coupon>>(initialCoupomState);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [selectedItems, setSelectedCoupon] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [clientList, serClientsList] = useState<AdminClientType[]>([]);

  const [query, setQuery] = useState<getAllProps>({
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });

  const getSellerCoupons = async () => {
    const queryParam = { ...query, page: query.page + 1 };
    // sdk.Seller.dashboard.coupon.getMyCoupons(queryParam, (res) => {
    //   setCoupons(res.result);
    // });

    const getCoupons = await api.get('/coupons/all', {
      params: query,
    });

    setCoupons(getCoupons.data.result);
  };

  const handleClientGetAll = async (
    queryProps: getAllProps = query,
    owner?: string,
  ) => {
    const getClients = await api.get('/users/all', {
      params: { page: 0, size: 1000, fields: 'userInfo username img' },
    });

    serClientsList(getClients.data.result);
  };

  const handleSelectAllcoupons = (): void => {
    const selectAll: string[] = coupons.map((coupon) => coupon._id as string);
    setSelectedCoupon((selecteds) => (selecteds.length === 0 ? selectAll : []));
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        ...state.filter,
        key: 'name',
        value: event.target.value,
      },
    }));
  };

  const handleSearchByQuery = (e) => {
    e.preventDefault();
    getSellerCoupons();
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = async (couponId: string) => {
    await api.patch(`/coupons/${couponId} `, { isActive: false });
  };

  const handleSelectCoupons = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedCoupon(
      event.target.checked ? coupons.map((coupon) => coupon._id) : [],
    );
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage }));

    getSellerCoupons();
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value) }));
    getSellerCoupons();
  };

  const submitValues = () => {
    if (coupon.value === '') {
      toast('Ops, esqueceu de adicionar um valor ao seu cupom!', {
        type: 'error',
        autoClose: 3000,
      });
      return;
    }

    if (coupon.name === '') {
      toast('Ops, esqueceu de adicionar um nome ao seu cupom!', {
        type: 'error',
        autoClose: 3000,
      });
      return;
    }

    const globalRegex = new RegExp('^([A-Z0-9|_|%|@]|\\s)+$', 'gi');
    if (!globalRegex.test(coupon.name)) {
      toast('Ops, o nome de cupom digitado não é válido!', {
        type: 'error',
        autoClose: 3000,
      });
      return;
    }

    if (coupon._id) {
      sdk.Seller.dashboard.coupon.updateSingleCoupon(
        {
          couponId: `${coupon._id}`,
          data: coupon,
        },
        (result) => {
          toast('Coupon atualizado com sucesso!', { type: 'success' });
          setTimeout(() => {
            navigate('/dashboard/admin/coupons', { replace: true });
          }, 1000);
        },
      );
    } else {
      sdk.Seller.dashboard.coupon.createSingleCoupon(
        coupon as Coupon,
        (result) => {
          toast('Coupon publicado com sucesso!', { type: 'success' });
          setTimeout(() => {
            navigate('/dashboard/admin/coupons', { replace: true });
          }, 1000);
        },
      );
    }
  };

  const updateAssigneds = ({ name, value }) => {
    setCoupon((state) => ({ ...state, [name]: value }));
  };

  const updateCouponFields = (event: ChangeEvent<HTMLInputElement>) => {
    var { value, name, checked } = event.target;

    const dateStrings = ['endDate', 'startDate'];

    const checkInputs = ['isFreeShipping', 'isCachBack', 'isActive'];

    if (name === 'name') {
      const globalRegex = new RegExp('^([A-Z0-9|_|%|@]|\\s)+$', 'gi');

      value = value.replace(/\s/, '_');

      if (!globalRegex.test(value)) {
        value = value.substring(0, value.length - 1);
      }

      value = value.toUpperCase();
    }

    setCoupon((state) => ({
      ...state,
      [name]: dateStrings.includes(name)
        ? new Date(value)
        : checkInputs.includes(name)
        ? checked
        : value,
    }));
  };

  const getSellerProducts = () => {
    const queryParam = { ...query };
    sdk.Seller.dashboard.product.getMyProducts(queryParam, (res) => {
      setProducts(res.result);
    });
  };

  const getGlobalCategories = () => {
    sdk.Global.getAllGlobalCategories(
      {
        page: 0,
        size: 10,
        filter: {
          key: 'isGlobal',
          value: 'true',
          fields: '',
        },
      },
      (res) => {
        setCategories(res.result);
      },
    );
  };

  const getCoupon = (id: string) => {
    if (!id) return setCoupon(initialCoupomState);
    sdk.Seller.dashboard.coupon.getSingleCoupon(
      { key: '_id', value: id },
      (res: Coupon) => {
        setCoupon(() => res);
      },
    );
  };

  const values: props = {
    coupon,

    coupons,
    setCoupons,
    query,
    categories,
    getGlobalCategories,
    setQuery,
    setSelectedCoupon,
    selectedItems,
    products,
    getSellerProducts,
    getSellerCoupons,
    handleQueryChange,
    handleSearchByQuery,
    handleSelectCoupons,
    handlePageChange,
    handleSelectAllcoupons,
    handleLimitChange,
    handleDeleteCompleted,
    openConfirmDelete,
    setOpenConfirmDelete,
    closeConfirmDelete,
    updateAssigneds,
    updateCouponFields,
    submitValues,
    handleClientGetAll,
    getCoupon,
    clientList,
  };

  return (
    <SellerCouponsContext.Provider value={values}>
      {children}
    </SellerCouponsContext.Provider>
  );
};

export const useSellerCoupons = () => useContext(SellerCouponsContext);

type props = {
  coupon: Partial<Coupon>;
  clientList: any;
  products: ProductType[];
  handleClientGetAll: any;
  coupons: Coupon[];
  setCoupons: React.Dispatch<SetStateAction<Coupon[]>>;
  getCoupon: (id: string) => void;
  query: getAllProps;
  setQuery: React.Dispatch<SetStateAction<getAllProps>>;
  getSellerCoupons: any;
  handleQueryChange: any;
  categories: any;
  getGlobalCategories: any;
  handleSearchByQuery: any;
  handleSelectCoupons: any;
  getSellerProducts: any;
  handlePageChange: any;
  handleLimitChange: any;
  submitValues: any;
  selectedItems: any;
  setSelectedCoupon: any;
  handleSelectAllcoupons: any;
  handleDeleteCompleted: (id: string) => void;
  openConfirmDelete: any;
  setOpenConfirmDelete: any;
  closeConfirmDelete: any;
  updateAssigneds: any;
  updateCouponFields: any;
};

export type objRefs = {
  name: MutableRefObject<any>;
  description: MutableRefObject<any>;
  price: MutableRefObject<any>;
  regularPrice: MutableRefObject<any>;
  status: MutableRefObject<any>;
  shippingInfo: {
    weight: MutableRefObject<any>;
    height: MutableRefObject<any>;
    width: MutableRefObject<any>;
  };
  stockInfo: {
    qnt: MutableRefObject<any>;
    sku: MutableRefObject<any>;
    SoldIndividually: MutableRefObject<any>;
  };
  virtualProduct: MutableRefObject<any>;
};
