/* eslint-disable */
import { Suspense } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Layout } from 'src/layouts/Layouts';

import { adminRoutes } from './adminRoutes';
import sellerRoutes from './sellerRoutes';
import userRoutes from './userRoutes';

import { DashboardProvider } from 'src/contexts/DashboardContext';

import SingleStore from 'src/pages/lojas/singleStore';
import * as Imports from './Imports';

import { SellerOrdersProvider } from 'src/contexts/SellerOrdersContext';
import { SellerPolicyProvider } from 'src/contexts/SellerPolicyContext';
import { SellerProductsProvider } from 'src/contexts/SellerProductsContext';
import { ProductsProvider } from 'src/contexts/productsContext';
import About from 'src/pages/about';
import RegisterPage from 'src/pages/register/registerPage';

const routesObject: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProductsProvider>
        <SellerPolicyProvider>
          <Layout.home />
        </SellerPolicyProvider>
      </ProductsProvider>
    ),
    children: [
      {
        path: '/',
        element: <Imports.HomePage />,
        children: [{ path: 'produto/:id', element: <Imports.HomePage /> }],
      },

      {
        path: '/store',
        children: [{ path: ':id', element: <SingleStore /> }],
      },
      {
        path: '/quem-somos',
        element: <About />,
      },
    ],
  },

  {
    path: '/login',
    element: <Layout.register />,
    children: [{ path: '/login', element: <Imports.LoginPage /> }],
  },
  {
    path: '/register',
    element: <Layout.home />,
    children: [{ path: '', element: <RegisterPage /> }],
  },
  {
    path: '/product/:id',
    element: <Imports.SingleProduct />,
  },

  {
    path: '/dashboard',
    element: (
      <>
        <ProductsProvider>
          <DashboardProvider>
            <Suspense fallback={<SuspenseLoader />}>
              <Layout.dashboard />
            </Suspense>
          </DashboardProvider>
        </ProductsProvider>
      </>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="seller/inicio" replace />,
      },
      {
        path: 'seller/inicio',
        element: (
          <SellerOrdersProvider>
            <SellerProductsProvider>
              <Imports.HomeDashboard />
            </SellerProductsProvider>
          </SellerOrdersProvider>
        ),
      },
      ...sellerRoutes,
      ...adminRoutes,
      ...userRoutes,
    ],
  },
];

const GetRoutes = () => {
  const rotas = useRoutes(routesObject);
  return rotas;
};

export { GetRoutes };
