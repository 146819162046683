import { Store, storeInfo } from "ecommersys/dist/Entities";
import { createContext, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sdk } from "src/sdkProvider";
import { api } from "src/services/axiosInstance";

const EditStoreContext = createContext<contextValues>(null);

export const EditStoreProvider = ({ children }: React.PropsWithChildren) => {
  const [store, setStore] = useState<any>(initialStoreState);

  const [storePayment, setStorePayment] =
    useState<Partial<Store>>(initialStoreState);

  const [storeInfo, setStoreInfo] = useState<Partial<storeInfo>>(
    initialStoreInfoState
  );
  const [paymentCount, setPaymentmentCount] = useState(0);
  const [installmentValue, setInstallmentValue] = useState("");
  const navigate = useNavigate();

  const getStore = () => {
    sdk.Seller.store.getMyStore((res: any) => setStore(res));
  };

  const handleUptadeStore = (e) => {
    const { name, value } = e.target;
    return setStore((state) => ({
      ...state,
      storeInfo: {
        ...state.storeInfo,
        [name]: value,
      },
    }));
  };

  const handleUptadeStoreValues = (installmentCount: number) => {
    let installmentRules = Array.from(store.installmentRules);

    installmentRules.push({ installmentCount, installmentValue });

    if (paymentCount > 0) {
      store.installmentRules.find(
        (rules) => rules.installmentCount == paymentCount
      ).installmentValue = installmentValue;
    }

    if (paymentCount <= 0) {
      setStore((state: any) => ({ ...state, installmentRules }));
    }
  };

  const handleUptadeStoreShipping = (e) => {
    const { name, value } = e.target;
    return setStore((state) => ({
      ...state,
      freeShippingMinPrice: {
        ...state.freeShippingMinPrice,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (handleClose?: () => void) => {
    // sdk.User.account.updateUserInfo({ id: store._id, data: obj }, () => {
    //   toast("Seus dados foram atualizados com sucesso", { type: "success" });
    //   setTimeout(() => {
    //     getStore();
    //     handleClose
    //       ? handleClose()
    //       : navigate("/dashboard/user/myAccount", { replace: true });
    //   }, 600);
    // });

    await api.patch(`sellers/${store._id}`, store);
    toast("Atualizado  com sucesso!", { type: "success" });
    getStore();
  };

  const handleDeletePaymentRule = async (index: number) => {
    // sdk.User.account.updateUserInfo({ id: store._id, data: obj }, () => {
    //   toast("Seus dados foram atualizados com sucesso", { type: "success" });
    //   setTimeout(() => {
    //     getStore();
    //     handleClose
    //       ? handleClose()
    //       : navigate("/dashboard/user/myAccount", { replace: true });
    //   }, 600);
    // });

    if (index > -1) {
      store?.installmentRules.splice(index, 1);
    }

    await api.patch(`sellers/${store._id}`, store);
    toast("Regra de Pagamento deleta com sucesso!", { type: "success" });
    getStore();
  };

  const values: contextValues = {
    handleSubmit,
    handleUptadeStore,
    handleUptadeStoreValues,
    store,
    getStore,
    handleUptadeStoreShipping,
    setStore,
    handleDeletePaymentRule,

    setPaymentmentCount,
    setInstallmentValue,
    installmentValue,
    paymentCount,
  };

  return (
    <EditStoreContext.Provider value={values}>
      {children}
    </EditStoreContext.Provider>
  );
};

export const useEditStore = () => useContext(EditStoreContext);

type contextValues = {
  handleSubmit: (handleClose?: () => void) => void;
  handleDeletePaymentRule: any;
  store: any;
  handleUptadeStore: any;
  handleUptadeStoreValues: any;
  handleUptadeStoreShipping: any;
  getStore: any;
  setStore: any;
  setInstallmentValue: any;
  setPaymentmentCount: any;
  paymentCount: any;
  installmentValue: any;
};

export const initialStoreState: any = {
  asaasApiKey: "",
  asaasID: "",
  asaasWalletId: "",
  banner: "",
  img: "",
  messages: [],
  name: "",
  openOrders: [],
  owner: "",
  products: [],
  productsCount: 0,
  salesHistory: [],
  salesToSend: [],
  segments: [],
  storeInfo: {
    cnpj: "",
    cnae: "",
    address: "",
    number: "",
    cpf: "",
    complement: "",
    enterpriseSocial: "",
    enterpriseName: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    district: "",
    zipCode: "",
    email: "",
  },
  _id: "",
  freeShippingMinPrice: {
    value: 0,
    hasFreeShipping: false,
  },
  installmentRules: [],
};

export const initialStoreInfoState: Partial<storeInfo> = {
  cnpj: "",
  cnae: "",
  address: "",
  number: "",
  cpf: "",
  complement: "",
  enterpriseSocial: "",
  enterpriseName: "",
  phone: "",
  city: "",
  state: "",
  country: "",
  district: "",
  zipCode: "",
  email: "",
};
