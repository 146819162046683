import { HomeLayout } from "./home";
import { RegisterLayout } from "./register";
import DashboardLayout from "./DashboardLayout";

const Layout: any = {
  home: HomeLayout,
  register: RegisterLayout,
  dashboard: DashboardLayout,
};

export { Layout };
