import {
  Category,
  Product as ProductType,
  ProductsReturn,
  Store,
} from 'ecommersys/dist/Entities';
import {
  ChangeEvent,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { sdk } from '../sdkProvider';

import { getAllProps } from 'ecommersys/dist/interfaces';

import { Zoom } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useRefMounted from 'src/hooks/useRefMounted';
import { AdminClientType } from 'src/models/client';
import { api } from 'src/services/axiosInstance';
import { initialProductState } from './SellerProductsContext';

const AdminOrderContext = createContext<props>(null);

export const AdminOrdersProvider = ({ children }: React.PropsWithChildren) => {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();

  const [product, setProduct] =
    useState<Partial<ProductType>>(initialProductState);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);

  const [order, setOrder] = useState<Partial<Sales>>(initialSalesState);
  const [orders, setOrders] = useState<Sales[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<getAllProps>({
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });
  const [queryProducts, setQueryProducts] = useState<getAllProps>({
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });

  const [totalItems, setTotalItems] = useState(0);

  const [selectedItems, setSelectedOrders] = useState<string[]>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [clientList, serClientsList] = useState<AdminClientType[]>([]);
  const [storeList, setStoreList] = useState<Partial<Store[]>>([]);
  const [selectStore, setSelectStore] = useState<Partial<Store> | null>(null);

  function compare(a, b) {
    return new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime();
  }

  const handleStoreGetAll = (filter) => {
    sdk.Global.getAllSellers({ filter, page: 0, size: 10 }, (res) => {
      if (res.result.length === 0) {
        toast('Nenhuma loja encontrada para essa pesquisa!', {
          type: 'info',
          delay: 5000,
        });
      } else {
        setStoreList(res.result.sort(compare));
      }
    });
  };

  const handleSetSelectStore = (name: string) => {
    const filterStoreSelect: Partial<Store> = storeList.find(
      (item) => item.name === name,
    );

    if (filterStoreSelect?._id) {
      getOrderByStore(filterStoreSelect._id);
    } else {
      getAllSells();
    }
  };

  const handleSetSelectStoreOrState = async (name: string, status: string) => {
    const stores = await sdk.Global.getAllSellers(
      { page: 0, size: 1000 },
      (res) => {
        if (res.result.length === 0) {
          toast('Nenhuma loja encontrada para essa pesquisa!', {
            type: 'info',
            delay: 5000,
          });
        } else {
          return res.result;
        }
      },
    );

    if (name !== undefined && name !== 'undefined' && name !== '') {
      const filterStoreSelect = stores.result.find(
        (item) => item.name === name,
      );

      const queryParam = {
        ...query,
        filter: {
          key: 'storeIds ' + 'paymentStatus ',
          value: `${filterStoreSelect._id} ` + `${status}`,
        },
      };

      if (
        filterStoreSelect?._id ||
        status === 'PENDING' ||
        status === 'CONFIRMED' ||
        status === 'OVERDUE'
      ) {
        const response = await api.get('/orders/all', {
          params: queryParam,
        });
        setOrders(response.data.result);
      } else {
        getAllSells();
      }
    } else {
      const queryParam = {
        ...query,
        filter: {
          key: 'paymentStatus',
          value: `${status}`,
        },
      };

      if (
        status === 'PENDING' ||
        status === 'CONFIRMED' ||
        status === 'OVERDUE'
      ) {
        const response = await api.get('/sales/all', {
          params: queryParam,
        });
        setOrders(response.data.result);
      } else {
        getAllSells();
      }
    }
  };

  const handleClientGetAll = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter, fields: 'userInfo username img' },
    };

    const getClients = await api.get('/users/all', { params: queryParam });

    setTotalItems(getClients.data.totalItems);
    serClientsList(getClients.data.result);
  };

  const handleSetSelectClient = (name: string) => {
    const filterStoreSelect: Partial<AdminClientType> = clientList.find(
      (item) => item.userInfo.name === name,
    );

    if (filterStoreSelect?._id) {
      getOrderByClient(filterStoreSelect._id);
    } else {
      getAllSells();
    }
  };

  const getAllSells = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter },
    };

    const response = await api.get('/orders/all', {
      params: queryParam,
    });

    setOrders(response.data.result);
    setTotalItems(response.data.totalItems);
  };

  const getUserOrders = async (id: string) => {
    const queryParam = {
      ...query,
      filter: {
        key: 'userId ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    // /products/all key: owner value: storeId _

    const response = await api.get('/orders/all', {
      params: queryParam,
    });

    setOrders(response.data.result.sort(compare));

    setTotalItems(response.data.result.length);
  };

  const HandlegetOrdersByStore = async (
    queryProps: getAllProps = query,
    id: string,
  ) => {
    const queryParam = {
      ...queryProps,
      filter: {
        key: 'storeIds ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const response = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrders(response.data.result);

    setTotalItems(response.data.result.length);
  };

  const getOrdersByStore = async (id: string) => {
    const queryParam = {
      ...query,
      filter: {
        key: 'storeIds ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const response = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrders(response.data.result.sort(compare));

    setTotalItems(response.data.result.length);
  };

  const getProductsStore = async (storeId: string) => {
    const queryParam = {
      ...queryProducts,
      filter: {
        key: 'owner ' + queryProducts.filter.key,
        value: `${storeId} ` + queryProducts.filter.value,
      },
    };

    // /products/all key: owner value: storeId _

    const response = await api.get('/products/all', {
      params: queryParam,
    });

    setProducts(response.data.result);

    setTotalItems(response.data.result.length);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the order'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Zoom,
    });
  };

  const handleQueryChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        key: 'id',
        value: `${event.target.value}`,
      },
    }));
  };

  const handleQueryChangeProducts = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    event.persist();
    setQueryProducts((state) => ({
      ...state,
      filter: {
        key: 'name',
        value: `${event.target.value}`,
      },
    }));
  };

  const handleSelectAllOrders = (): void => {
    const selectAll: string[] = orders.map((order) => order._id as string);
    setSelectedOrders((selecteds) => (selecteds.length === 0 ? selectAll : []));
  };

  const handleSelectOneclient = (
    event: ChangeEvent<HTMLInputElement>,
    clientId: string,
  ): void => {
    if (!selectedItems.includes(clientId)) {
      setSelectedOrders((prevSelected) => [...prevSelected, clientId]);
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((id) => id !== clientId),
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage }));
    getAllSells({ ...query, page: newPage });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value), page: 0 }));
    getAllSells({ ...query, size: parseInt(value), page: 0 });
  };

  const HandlegetOrdersByProducts = async (
    queryProps: getAllProps = queryProducts,
    id: string,
  ) => {
    const queryParam = {
      ...queryProducts,
      filter: {
        key: 'owner ' + queryProducts.filter.key,
        value: `${id} ` + queryProducts.filter.value,
      },
    };

    // /products/all key: owner value: storeId _

    const response = await api.get('/products/all', {
      params: queryParam,
    });

    setProducts(response.data.result);

    setTotalItems(response.data.result.length);
  };

  const handlePageChangeBySingleProducts = (
    event: any,
    newPage: number,
    id: string,
  ): void => {
    setQueryProducts((queryProducts) => ({ ...queryProducts, page: newPage }));
    HandlegetOrdersByProducts({ ...queryProducts, page: newPage }, id);
  };

  const handleLimitChangeBySingleProducts = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    const { value } = event.target;

    setQueryProducts((queryProducts) => ({
      ...queryProducts,
      size: parseInt(value),
      page: 0,
    }));
    HandlegetOrdersByProducts(
      { ...queryProducts, size: parseInt(value), page: 0 },
      id,
    );
  };

  const handlePageChangeBySingleOrder = (
    event: any,
    newPage: number,
    id: string,
  ): void => {
    setQuery((query) => ({ ...query, page: newPage }));
    HandlegetOrdersByStore({ ...query, page: newPage }, id);
  };

  const handleLimitChangeBySingleOrder = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    const { value } = event.target;

    setQuery((query) => ({ ...query, size: parseInt(value), page: 0 }));
    HandlegetOrdersByStore({ ...query, size: parseInt(value), page: 0 }, id);
  };

  const handlePageChangeListOrderClients = (
    id: string,
    event: any,
    newPage: number,
  ): void => {
    setQuery((query) => ({ ...query, page: newPage }));
    getUserOrders(id);
  };

  const handleLimitChangeListOrderClients = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value), page: 0 }));
    getUserOrders(id);
  };

  const handleSearchByQuery = (e) => {
    e.preventDefault();
    getAllSells();
  };

  const handleSearchByQueryBySeller = (e, id) => {
    e.preventDefault();
    getOrdersByStore(id);
  };
  const handleSearchByProductySeller = (e, id) => {
    e.preventDefault();
    getProductsStore(id);
  };

  const handleSelectQueryChange = (
    event?: ChangeEvent<HTMLInputElement>,
    id?: string,
  ): void => {
    setQuery((state) => ({
      ...state,
      filter: {
        key: 'paymentStatus',
        value: `${event.target.value}`,
      },
    }));
  };

  const handleSelectSearchByQuery = async (e) => {
    {
      e.target.value === 'all'
        ? getAllSells()
        : await getAllSells({
            ...query,
            filter: { key: 'paymentStatus', value: `${e.target.value}` },
          });
    }
  };

  const handleSearchByQueryCLientOrder = (id) => {
    getUserOrders(id);
  };

  const getMySells = useCallback(async () => {
    try {
      const queryParam = { ...query, page: query.page + 1 };

      const response = await api.get('/sales/all', { params: queryParam });

      setOrders(response.data.result);

      if (isMountedRef.current) {
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getMyBuys = useCallback(
    async (queryProps: getAllProps = query) => {
      try {
        const queryParam = {
          ...queryProps,
          filter: { ...queryProps.filter },
        };

        const response = await api.get('/users/getMyBuys', {
          params: queryParam,
        });

        setOrders(response.data.result);

        if (isMountedRef.current) {
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef],
  );

  const getMyBuy = useCallback(
    async (id) => {
      try {
        const response = await api.get(`/sales/${id}`);

        setOrder(response.data);
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef],
  );

  const getBuyClient = async (id?) => {
    const queryParam = {
      ...query,
      filter: {
        key: query.filter.key + ' _id',
        value: query.filter.value + ` ${id}`,
      },
    };

    const response = await api.get('/sales/all', {
      params: queryParam,
    });

    response.data.result.map((listOrders) =>
      listOrders._id === id ? setOrder(listOrders) : null,
    );
  };

  const getOrderByStore = async (id) => {
    const queryParam = {
      ...query,
      filter: {
        key: 'storeIds ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const response = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrders(response.data.result);
  };

  const getOrderByClient = async (id) => {
    const queryParam = {
      ...query,
      filter: {
        key: 'userId ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const response = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrders(response.data.result);
  };

  const values: props = {
    getMySells,
    getMyBuys,
    getMyBuy,
    orders,
    order,
    setOrders,
    query,
    setQuery,
    handleQueryChange,
    handleSearchByQuery,
    handleSearchByQueryCLientOrder,
    handleSelectAllOrders,
    handlePageChange,
    handleLimitChange,
    selectedItems,
    setSelectedOrders,
    handleDeleteCompleted,
    handleConfirmDelete,
    closeConfirmDelete,
    handleSelectOneclient,
    openConfirmDelete,
    setOrder,
    getOrderByStore,
    getAllSells,
    getBuyClient,
    totalItems,
    getUserOrders,
    getOrdersByStore,
    getProductsStore,
    product,
    products,
    categories,
    handleSelectSearchByQuery,
    handleSelectQueryChange,
    handleLimitChangeListOrderClients,
    handlePageChangeListOrderClients,
    storeList,
    handleStoreGetAll,
    handleSetSelectStore,
    handleClientGetAll,
    clientList,
    handleSetSelectClient,
    handleLimitChangeBySingleOrder,
    handlePageChangeBySingleOrder,
    handleLimitChangeBySingleProducts,
    handlePageChangeBySingleProducts,
    handleSearchByQueryBySeller,
    queryProducts,
    handleQueryChangeProducts,
    handleSearchByProductySeller,
    handleSetSelectStoreOrState,
  };

  return (
    <AdminOrderContext.Provider value={values}>
      {children}
    </AdminOrderContext.Provider>
  );
};

export const useAdminOrders = () => useContext(AdminOrderContext);

type props = {
  product: Partial<ProductType>;
  products: ProductType[];
  categories: Category[];
  getAllSells: any;
  handleSetSelectStoreOrState: any;
  getUserOrders: any;
  getOrdersByStore: any;
  getMySells: any;
  handleSetSelectClient: any;
  getMyBuys: any;
  getMyBuy: any;
  handleLimitChangeBySingleOrder: any;
  handlePageChangeBySingleOrder: any;
  getBuyClient;
  getProductsStore: any;
  orders: any;
  order: any;
  setOrders: any;
  setOrder: any;
  query: getAllProps;
  queryProducts: getAllProps;
  getOrderByStore: any;
  setQuery: React.Dispatch<SetStateAction<getAllProps>>;
  handleQueryChange: any;
  handleSelectQueryChange: any;
  handleSearchByQuery: any;
  handleSearchByQueryBySeller: any;
  handleSearchByProductySeller: any;
  handleSearchByQueryCLientOrder: any;
  handleSelectAllOrders: any;
  handlePageChange: any;
  handleLimitChange: any;
  selectedItems: any;
  setSelectedOrders: any;
  handleDeleteCompleted: any;
  openConfirmDelete: any;
  handleConfirmDelete: any;
  closeConfirmDelete: any;
  handleSelectOneclient: any;
  handleSelectSearchByQuery: any;
  totalItems: number;
  handleLimitChangeListOrderClients: any;
  handlePageChangeListOrderClients: any;
  storeList: Partial<Store[]>;
  clientList: Partial<AdminClientType[]>;
  handleStoreGetAll: any;
  handleSetSelectStore: any;
  handleClientGetAll: any;
  handleLimitChangeBySingleProducts: any;
  handlePageChangeBySingleProducts: any;
  handleQueryChangeProducts: any;
};

export const initialSalesState: Partial<Sales> = {
  _id: '',
  sellers: [],
  userId: '',
  addressId: '',
  payment: {
    amount: 0,
    paymentMethod: '',
    cardToken: '',
    id: '',
  },
  coupons: [],
  totalValue: 0,
  totalDiscount: 0,
  totalItems: 0,
  billingType: '',
  paymentStatus: '',
  paymentId: '',
  storeIds: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export type Sales = {
  _id?: string;
  sellers: StoreMapped[];
  userId: string;
  addressId: string;
  payment?: {
    amount: number;
    paymentMethod: string;
    cardToken: string;
    id: string;
  };
  coupons: string[];
  totalValue: number;
  totalDiscount: number;
  totalItems: number;
  billingType: string;
  paymentStatus?: string;
  paymentId?: string;
  storeIds: string[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ProductSaleInfo = {
  productId: string;
  amount: number;
};

export type StoreMapped = {
  storeId: string;
  products: ProductsReturn[];
  totalPrice: number;
  walletId: string;
  totalDiscount: number;
  coupons: string[];
};

export type paymentTypes =
  | 'PENDING'
  | 'RECEIVED'
  | 'CONFIRMED'
  | 'OVERDUE'
  | 'REFUNDED'
  | 'REFUND_REQUESTED'
  | 'CHARGEBACK_REQUESTED'
  | 'CHARGEBACK_DISPUTE'
  | 'AWAITING_CHARGEBACK_REVERSAL'
  | 'DUNNING_REQUESTED'
  | 'AWAIT_RISK_ANALYSIS';

export type billingType =
  | 'BOLETO'
  | 'CREDIT_CARD'
  | 'UNDEFINED'
  | 'TRANSFER'
  | 'DEPOSIT'
  | 'PIX';
