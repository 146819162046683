import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import * as Imports from './Imports';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { SolicitationStatus } from 'src/components/solicitation/SolicitationStatus';
import Tests from 'src/components/tests';
import { EditProfileProvider } from 'src/contexts/EditProfileContext';
import { SellerOrdersProvider } from 'src/contexts/SellerOrdersContext';
import { SellerProductsProvider } from 'src/contexts/SellerProductsContext';
import { SolicitationProvider } from 'src/contexts/SolicitationsContext';
import { UserDocumentsProvider } from 'src/contexts/UserDocumentsContext';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const userRoutes = [
  {
    path: 'user',

    children: [
      {
        path: '',
        element: <Navigate to="myAccount" replace />,
      },
      {
        path: 'myAccount',
        children: [
          {
            path: '',
            element: <Imports.MyAccount />,
          },
          {
            path: 'edit',
            element: (
              <EditProfileProvider>
                <Imports.EditAccount />
              </EditProfileProvider>
            ),
          },
        ],
      },
      {
        path: 'checkout',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Imports.Checkout />,
          },
        ],
      },
      {
        path: 'tests',
        element: <Tests />,
      },

      // {
      //   path: "chatbox",
      //   element: <Imports.ChatBox />,
      // },
      {
        path: 'files',
        element: (
          <UserDocumentsProvider>
            <Outlet />
          </UserDocumentsProvider>
        ),
        children: [
          {
            path: '',
            element: <Imports.Files />,
          },
        ],
      },
      {
        path: 'banking',
        element: <Imports.Banking />,
      },
      {
        path: 'products',
        element: (
          <SellerProductsProvider>
            <Outlet />
          </SellerProductsProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Product />,
          },
          { path: ':id', element: <Imports.CreateProduct /> },
          { path: 'create', element: <Imports.CreateProduct /> },
          {
            path: 'preview/:id',
            element: <Imports.SingleProduct />,
          },
          {
            path: 'shop',
            element: <Imports.ShopProducts />,
          },
          {
            path: 'wishlist',
            element: <Imports.WishList />,
          },
          {
            path: 'politics',
            element: <Imports.Politics />,
          },
        ],
      },

      {
        path: 'requests',
        element: (
          <SellerOrdersProvider>
            <UserDocumentsProvider>
              <Outlet />
            </UserDocumentsProvider>
          </SellerOrdersProvider>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />,
          },
          {
            path: 'list',
            element: <Imports.Requests />,
          },

          {
            path: ':id',
            element: <Imports.SingleRequests />,
          },
          {
            path: 'invoices/:id',
            element: <Imports.InvoiceOrdersRequests />,
          },
        ],
      },

      {
        path: 'storeSolicitation',
        element: (
          <SolicitationProvider>
            <SolicitationStatus />
          </SolicitationProvider>
        ),
      },
      {
        path: 'checkout',
        element: <Imports.Checkout />,
      },
      {
        path: 'chat',
        element: <Imports.Messenger />,
      },
      {
        path: 'calendar',
        element: <Imports.Calendar />,
      },
    ],
  },
];

export default userRoutes;
