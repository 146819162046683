import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Divider,
  Drawer,
  alpha,
  darken,
  lighten,
  styled,
  useTheme,
} from '@mui/material';

import Logo from 'src/components/Logo';
import { useNotifications } from 'src/contexts/NotificationsContext';
import { useUser } from 'src/contexts/UserContext';
import SidebarFooter from './SidebarFooter';
import SidebarMenu from './SidebarMenu';
import SidebarTopSection from './SidebarTopSection';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.black[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
`,
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext<any>(SidebarContext);
  const { user } = useUser();
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  const {
    globalAllNotifications,
    sellerAllNotifications,
    userAllNotifications,
    notification,
  } = useNotifications();

  const verifySomeNotification =
    globalAllNotifications.length > 0 ||
    sellerAllNotifications.length > 0 ||
    userAllNotifications.length > 0;

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'light'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'light' ? theme.sidebar.boxShadow : 'none',
        }}
      >
        <Scrollbar>
          <Logo />
          <Divider
            sx={{
              background: theme.colors.alpha.black[10],
            }}
          />

          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.black[10],
          }}
        />
        <SidebarFooter />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'light'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Divider
              sx={{
                mt: 3,
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarTopSection />
            <Divider
              sx={{
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <SidebarFooter />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
