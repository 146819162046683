export const ptProductsJSON = {
  "Regular price": "Preço regular",
  "Regular price here ...": "Preço regular aqui ...",
  "Sale price": "Preço de venda",
  "Sale price here ...": "Preço de venda aqui ...",
  "Tax Status": "Situação fiscal",
  Taxable: "Tributável",
  "Tax Class": "Classe de Taxa",
  "Stock quantity here ...": "Quantidade em estoque aqui...",
  "This field helps identify current stocks of products":
    "Este campo ajuda a identificar os estoques atuais de produtos",
  "Stock Status": "Status do Estoque",
  "In stock": "Em estoque",
  "Out of stock": "Fora de estoque",
  " Back in stock soon": "De volta ao estoque em breve",
  "Sold individually": "Vendido Separadamente",
  "Enable this to only allow one of this item to be bought in a single order":
    "Habilite isso para permitir que apenas um deste item seja comprado em um único pedido",
  "Your have the weight units set to kilograms in your app settings":
    "Você tem as unidades de peso definidas em quilogramas nas configurações do aplicativo",
  "Write length ...": "Digite o Comprimento...",
  "Write width ...": "Digite a Largura ...",
  "Product title here...": "Declare o Nome do Produto aqui",
  "Select product tags...": "Selecione tags do produto...",
  "There are no product tags": "Não há etiquetas de produtos",
  "Edit Product": "Editar Produto",
  "Create Product": "Criar Produto",
  "Add new product": "Adicionar novo produto",
  "Fill in the fields below to create a new product":
    "Preencha os campos abaixo para criar um novo produto",
  Price: "Preço",
  "Product name": "Nome do produto",
  Stock: "Estoque",
  Rating: "Avaliações",
  Orders: "Nº Pedidos",
  "Changed in": "Alterado em",
  "Products - Management": "Produtos - Gestão",
  "  Verified purchase": "compra verificada",
  "Do you really want to delete this product":
    "Você realmente deseja excluir este produto ?",
  Weight: "Peso",
  Width: "Largura",
  Lenght: "Comprimento",
  "Product title...": "Título do produto...",
  "Search by product...": "Pesquise por produto...",
  "Use this page to manage your products, the fast and easyss way.":
    "Use esta página para gerenciar seus produtos de forma rápida e fácil.",
  Products: "Produtos",
  "Products List": "Lista de produtos",
  "View Product": "Vêr o produto",
  "Search by product name...": "Procurar por produto... ",
  "Go back to all products": "Voltar para todos os produtos",
  "Virtual Product": "Produto virtual",
  "Product Images": "Imagens do produto",
  "Product Tags": "Etiquetas de produtos",
  "Product Details": "Detalhes do produto",
  "Use this page to manage your products , the fast and easy way.":
    "Use esta página para gerenciar seus produtos de maneira rápida e fácil.",
  "Create Product - Commerce Management": "Criar Produto - Gestão Comercial",
  "Manage products": "Gerenciar produtos",
  "Write here and confirm to add a tag...":
    "Escreva aqui e confirme para adicionar a tag...",
  "Clear Categories": "Limpar categorias",
  "Active Product": "Ativar produto",
  "You have uploaded": "Você fez upload de",
  files: "arquivo(s)",
  "List Products": "Lista de produtos",
};
