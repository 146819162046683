import { LaunchTwoTone } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { ClientType, client } from 'src/models/client';
import { formatReal, translateTextTable } from 'src/utils/functions';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink } from 'react-router-dom';
import { icons } from 'src/assets/imgs';
import { ListOrderProductsDialog } from 'src/components/Dialogs/ListOrderProductsDialog';
import { Sales } from 'src/contexts/SellerOrdersContext';
import { pathCurrentOrNextNavigate } from 'src/utils/functions';
import { DataSourceType } from '../Results';

export const clientsDataSource: DataSourceType[] = [
  {
    key: 'name',
    alignItems: 'left',
    visible: true,
    title: 'Name',
    render: (data) => {
      return (
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              height: '45px',
              width: '45px',
            }}
          >
            <ImgWrapper
              src={`${data.clientImg || icons.avatarCircle}`}
              alt="produtoImage"
            />
          </Box>
          <Box
            pl={1}
            sx={{
              width: 60,
            }}
          >
            <Typography sx={{ textTransform: 'uppercase' }}>
              {' '}
              {data.clientName}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    key: 'orders',
    alignItems: 'left',
    visible: true,
    title: 'Orders',
    render: (data) => {
      return <Typography>{data.totalOrders}</Typography>;
    },
  },
  {
    key: 'clientPhone',
    alignItems: 'left',
    visible: true,
    title: 'Client Phone',
  },
  {
    key: 'clientEmail',
    alignItems: 'left',
    visible: true,
    title: 'Client Email',
  },
  {
    key: 'totalBuyed',
    alignItems: 'left',
    visible: true,
    title: 'Total Buyed',
    render: (data) => {
      return <Typography>{formatReal(data.totalBuyed)}</Typography>;
    },
  },
  {
    key: 'actions',
    alignItems: 'right',
    visible: true,
    title: 'Actions',
    render: (data) => {
      return (
        <Typography noWrap sx={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title={'Visualizar'} arrow>
            <IconButton
              component={RouterLink}
              to={`${pathCurrentOrNextNavigate()}/clients/show/${
                data.clientId
              }`}
              color="primary"
            >
              <LaunchTwoTone fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
      );
    },
  },
];

export const clientsListOrders: DataSourceType[] = [
  {
    key: 'id',
    alignItems: 'left',
    visible: true,
    title: 'Order',
  },
  {
    key: 'products',
    alignItems: 'center',
    visible: true,
    title: 'Products',
    render: (data) => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ListOrderProductsDialog data={data} review={false}>
            <IconButton size="medium">
              <VisibilityIcon fontSize="medium" sx={{ color: '#aa53fc' }} />
            </IconButton>
          </ListOrderProductsDialog>
        </Box>
      );
    },
  },
  {
    key: 'paymentStatus',
    visible: true,
    title: 'Status de Pagamento',
    alignItems: 'center',

    render: (orders) => {
      let textColor = '';
      let bgColor = '';

      if (
        orders?.sale.paymentStatus === 'RECEIVED' ||
        orders?.sale.paymentStatus === 'CONFIRMED'
      ) {
        bgColor = 'rgb(46, 125, 50)';
        textColor = 'white';
      }
      if (orders?.sale.paymentStatus === 'OVERDUE') {
        bgColor = 'rgb(211, 47, 47)';
        textColor = 'white';
      }
      if (
        orders?.sale.paymentStatus === 'PENDING' ||
        orders?.sale.paymentStatus === 'undefined' ||
        orders?.sale.paymentStatus === '' ||
        orders?.sale.paymentStatus === null
      ) {
        bgColor = 'rgb(255,	215	,0)';
        textColor = 'black';
      }

      if (
        orders?.sale.paymentStatus !== 'RECEIVED' &&
        orders?.sale.paymentStatus !== 'CONFIRMED' &&
        orders?.sale.paymentStatus !== 'OVERDUE'
      ) {
        bgColor = 'rgb(255,	215	,0)';
        textColor = 'black';
      }

      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Chip
            label={translateTextTable(`${orders?.sale.paymentStatus}`)}
            style={{
              background: bgColor,
              color: textColor,
              fontWeight: 600,
            }}
          />
        </Box>
      );
    },
  },
  {
    key: 'totalValue',
    alignItems: 'left',
    visible: true,
    title: 'Total',
    render: (data) => {
      return <Typography>{formatReal(data.totalValue)}</Typography>;
    },
  },
  {
    key: 'storeIds',
    alignItems: 'right',
    visible: true,
    title: 'Order',
    render: (data) => {
      return (
        <Typography noWrap sx={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title={'editar'} arrow>
            <IconButton
              component={RouterLink}
              to={`/dashboard/seller/orders/${data.id}`}
              color="primary"
            >
              <LaunchTwoTone fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
      );
    },
  },
];

export const clientsListOrdersAdmin: DataSourceType[] = [
  {
    key: 'name',
    alignItems: 'center',
    visible: true,
    title: 'Name',
    render: (data) => {
      return (
        <TableCell key={data._id + 'name'}>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                borderRadius: '50%',
                overflow: 'hidden',
                height: '45px',
                width: '45px',
              }}
            >
              <ImgWrapper
                src={`${data.img || icons.avatarCircle}`}
                alt="produtoImage"
              />
            </Box>
            <Box
              pl={1}
              sx={{
                width: 60,
              }}
            >
              <Typography sx={{ textTransform: 'uppercase' }}>
                {' '}
                {data.userInfo.name}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      );
    },
  },
  {
    key: 'email',
    alignItems: 'center',
    visible: true,
    title: 'Client Email',
    render: (data) => {
      return (
        <TableCell key={data._id}>
          <Typography>{data.userInfo.email}</Typography>
        </TableCell>
      );
    },
  },
  {
    key: 'totalValue',
    alignItems: 'left',
    visible: true,
    title: 'Total',
    render: (data) => {
      return <TableCell>{formatReal(data.totalValue)}</TableCell>;
    },
  },
  {
    key: 'actions',
    alignItems: 'center',
    visible: true,
    title: 'Actions',
    render: (data) => {
      return (
        <TableCell>
          <Typography noWrap>
            <Tooltip title={'editar'} arrow>
              <IconButton
                component={RouterLink}
                to={`${pathCurrentOrNextNavigate()}/clients/show/${data._id}`}
                color="primary"
              >
                <LaunchTwoTone fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </TableCell>
      );
    },
  },
];

export const initialClientState: Partial<ClientType> = {
  clientEmail: 'string',
  clientId: 'string',
  clientImg: 'string',
  clientName: 'string',
  clientPhone: 'string',
  gatewayPagId: 'string',
  orders: [],
  totalBuyed: 0,
};

export const applyFilters = (clients: client[], query: string): client[] => {
  return clients.filter((client) => {
    let matches = true;

    if (query) {
      const properties = ['name'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

export const applyPagination = (
  clients: ClientType[],
  page: number,
  limit: number,
): ClientType[] => {
  return clients.slice(page * limit, page * limit + limit);
};

export const applyPaginationListOrders = (
  clients: Sales[],
  page: number,
  limit: number,
): Sales[] => {
  return clients.slice(page * limit, page * limit + limit);
};

const ImgWrapper = styled('img')(
  ({ theme }) => `
      height:45px;
      width:45px;
      borderRadius:50%;
`,
);
