import { Category, Product as ProductType } from 'ecommersys/dist/Entities';
import {
  ChangeEvent,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SelectChangeEvent } from '@mui/material';
import { getAllProps } from 'ecommersys/dist/interfaces';
import { t } from 'i18next';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { globalsRequests } from 'src/services/queryes/globalsRequest';
import { sdk } from '../sdkProvider';

const SellerProductsContext = createContext<props>(null);

export const SellerProductsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [product, setProduct] =
    useState<Partial<ProductType>>(initialProductState);
  const [selectedItems, setSelectedProducts] = useState<string[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [query, setQuery] = useState<getAllProps>({
    refresh: false,
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });

  const [totalItems, setTotalItems] = useState(0);

  const navigate = useNavigate();

  const tagInputRef = useRef<any>();

  const getProduct = (id: string) => {
    if (!id) return setProduct(initialProductState);
    sdk.Global.getSingleProduct(
      { key: '_id', value: id },
      (res: ProductType) => {
        if (!res.shippingInfo) {
          res.shippingInfo = {
            height: null,
            width: null,
            weight: null,
            length: null,
          };
        }

        if (!res.shippingInfo.weight) {
          res.shippingInfo.weight = '';
        }

        if (!res.shippingInfo.height) {
          res.shippingInfo.height = '';
        }

        if (!res.shippingInfo.width) {
          res.shippingInfo.width = '';
        }

        if (!res.shippingInfo.length) {
          res.shippingInfo.length = '';
        }

        if (!res.price) res.price = '';
        if (!res.regularPrice) res.regularPrice = '';

        if (!res.stockInfo)
          res.stockInfo = { qnt: 0, sku: '', SoldIndividually: true };
        if (!res.stockInfo.qnt) res.stockInfo.qnt = 0;
        if (!res.stockInfo.sku) res.stockInfo.sku = '';
        if (!res.stockInfo.SoldIndividually)
          res.stockInfo.SoldIndividually = true;

        if (!res.virtualProduct) res.virtualProduct = false;
        if (!res.tags) res.tags = [];
        if (!res.discount) res.discount = [];
        if (!res.hangTags) res.hangTags = [];
        if (!res.owner) res.owner = '';
        if (!res.categories) res.categories = [];
        if (!res.imgs) res.imgs = [];
        if (!res.isActive) res.isActive = true;
        if (!res.status) res.status = false;
        if (!res.description) res.description = '';
        if (!res.name) res.name = '';

        setProduct(() => res);
        // updateRefValues();
      },
    );
  };

  const updateProductState = (e) => {
    const { name, value, checked } = e.target;
    const shippingInfo = ['height', 'width', 'weight', 'length'];
    const stockInfo = ['qnt', 'sku', 'SoldIndividually'];
    const booleanInside = [
      'isActive',
      'status',
      'virtualProduct',
      'SoldIndividually',
    ];

    if (shippingInfo.includes(name)) {
      return setProduct((state) => ({
        ...state,
        shippingInfo: { ...state.shippingInfo, [name]: value },
      }));
    }
    if (stockInfo.includes(name)) {
      return setProduct((state) => ({
        ...state,
        stockInfo: {
          ...state.stockInfo,
          [name]: booleanInside.includes(name) ? checked : value,
        },
      }));
    }
    if (booleanInside.includes(name)) {
      return setProduct((state) => ({ ...state, [name]: checked }));
    }

    return setProduct((state) => ({ ...state, [name]: value }));
  };

  const submitValues = useCallback(() => {
    if (product.name === '') {
      toast('Ops, seu produto precisa de um nome!', {
        type: 'error',
        delay: 3000,
      });
      return;
    }

    if (product.regularPrice <= 0) {
      toast('Ops, valor de produto inválido!', {
        type: 'error',
        delay: 3000,
      });
      return;
    }

    if (product.stockInfo.sku === '' || product.stockInfo.qnt === 0) {
      toast('Ops, os campos de inventário não foram preenchidos!', {
        type: 'error',
        delay: 3000,
      });
      return;
    }

    if (
      !product.shippingInfo.height ||
      !product.shippingInfo.weight ||
      !product.shippingInfo.width
    ) {
      toast('Ops, os campos de envio não foram preenchidos!', {
        type: 'error',
        delay: 3000,
      });
      return;
    }

    if (product._id) {
      sdk.Seller.dashboard.product.updateSingleProduct(
        {
          productId: `${product._id}`,
          data: product,
        },
        (result) => {
          toast('Produto publicado com sucesso!', { type: 'success' });
          setTimeout(() => {
            navigate('/dashboard/seller/products', { replace: true });
          }, 1000);
        },
      );
    } else {
      sdk.Seller.dashboard.product.createSingleProduct(
        product as ProductType,
        (result) => {
          toast('Produto publicado com sucesso!', { type: 'success' });
          setTimeout(() => {
            navigate('/dashboard/seller/products', { replace: true });
          }, 1000);
        },
      );
    }
  }, [product]);

  const handleDeleteImg = (index) => {
    let imgs = Array.from(product.imgs);
    imgs.splice(index, 1);
    setProduct((product) => ({ ...product, imgs }));
  };

  const handleDeleteTag = (productTagToDelete: string) => () => {
    setProduct((product) => ({
      ...product,
      tags: product.tags.filter(
        (productTag) => productTag !== productTagToDelete,
      ),
    }));
    toast('Tag removida com sucesso!', { type: 'success' });
  };

  const getCategories = () => {
    sdk.Global.getAllGlobalCategories({}, (res) => {
      setCategories(res.result);
    });
  };

  const HandleDeleteCompleted = (productId: string) =>
    sdk.Seller.dashboard.product.updateSingleProduct(
      {
        productId,
        data: { isActive: false },
      },
      (res) => {
        const returnText = t('Produto deletado com sucesso');
        getSellerProducts();
        toast(returnText, { type: 'success' });
      },
    );

  const handleClearCategories = () => {
    setProduct((state) => ({ ...state, categories: [] }));
  };

  const verifyCategoryChecked = (category: Category) =>
    product.categories.filter((item) => item.split('/')[0] === category._id)
      .length > 0;

  const handleSelectLabel = (category: Category, index: number) => {
    const containsCategory = verifyCategoryChecked(category);

    let newCategories = Array.from(product.categories);

    if (!containsCategory) {
      newCategories.push(`${category._id}/${category.name}`);
      if (!product.categories.includes(`${category.hierarchy}`))
        newCategories.push(`${category.hierarchy}`);
    } else {
      const findIndex = newCategories.indexOf(
        `${category._id}/${category.name}`,
      );
      newCategories.splice(findIndex, 1);
    }

    setProduct((product) => ({ ...product, categories: newCategories }));
  };

  const getSellerProducts = (getInactiveProducts = false) => {
    if (getInactiveProducts) {
      query.filter.key = (query.filter.key + ' ignoreActiveKey').trim();
      query.filter.value = (query.filter.value + ' true').trim();
    }

    sdk.Seller.dashboard.product.getMyProducts(query, (res) => {
      setProducts(res.result);

      setTotalItems(res.totalItems);
      setQuery((query) => ({
        ...query,
        page: res.thisPage,
        size: res.pageSize,
        refresh: false,
      }));
    });
  };

  const addTag = () => {
    // updateRefValues();
    let tags = Array.from(product.tags);
    const value = tagInputRef.current.value;
    if (value?.length < 2)
      return toast('Tag precisa ter no mínimo 2 caracteres!', {
        type: 'error',
      });
    if (tags.includes(value)) {
      return toast('Tag existente!', { type: 'error' });
    }
    tags.push(value);
    setProduct((product) => ({
      ...product,
      tags,
    }));
    toast('Tag adicionada com sucesso!', { type: 'success' });
    tagInputRef.current.value = '';
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (event.target.value === '') {
      setQuery((state) => ({
        refresh: true,
        page: 0,
        size: 10,
        filter: {
          key: '',
          value: '',
          fields: '',
        },
      }));
      return;
    }

    setQuery((state) => ({
      ...state,
      filter: {
        ...state.filter,
        key: 'name',
        value: event.target.value.replaceAll(' ', '|esp|'),
      },
      page: 0,
      refresh: true,
    }));
  };

  const handleSearchByQuery = (e) => {
    e.preventDefault();
    getSellerProducts();
  };

  const handleSelectAllProducts = (): void => {
    const selectAll: string[] = products.map(
      (product) => product._id as string,
    );
    setSelectedProducts((selecteds) =>
      selecteds.length === 0 ? selectAll : [],
    );
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage, refresh: true }));
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value), page: 0 }));
  };

  const updateState = (
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | SelectChangeEvent<any>,
  ) => {
    // updateRefValues();
    // setProduct((state) => ({ ...state, [name]: value }));
  };

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'video/mp4': ['.mp4'],
    },
    onDropAccepted(files) {
      files.map(async (file) => {
        const loadToast = toast.loading('Adicionando imagem do produto');
        try {
          const formData = new FormData();
          formData.append('img', file);

          const uploadSingle = await globalsRequests.uploadImage(formData);

          let imgs = Array.from(product.imgs);

          imgs.push(uploadSingle);

          setProduct((state: any) => ({ ...state, imgs }));
          toast.update(loadToast, {
            type: 'success',
            isLoading: false,
            autoClose: 1000,
            render: 'Imagem adicionada com sucesso!',
          });

          acceptedFiles.push(file);
        } catch (err) {
          toast.update(loadToast, {
            type: 'error',
            isLoading: false,
            autoClose: 1000,
            render: 'error',
          });
        }
      });
    },
  });

  const values: props = {
    /** state */
    product,
    query,
    products,
    totalItems,
    categories,
    setQuery,
    setProduct,
    setProducts,
    submitValues,
    setCategories,
    selectedItems,
    setSelectedProducts,
    /** refs */
    tagInputRef,
    /** metodos */
    addTag,
    getProduct,
    updateState,
    getCategories,
    handleDeleteImg,
    handleQueryChange,
    handleLimitChange,
    handlePageChange,
    handleDeleteTag,
    handleSelectLabel,
    getSellerProducts,
    handleSearchByQuery,
    verifyCategoryChecked,
    HandleDeleteCompleted,
    handleSelectAllProducts,
    handleClearCategories,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    updateProductState,
  };

  useEffect(() => {
    if (query.refresh) {
      getSellerProducts();
    }
  }, [query]);

  return (
    <SellerProductsContext.Provider value={values}>
      {children}
    </SellerProductsContext.Provider>
  );
};

export const useSellerProducts = () => useContext(SellerProductsContext);

type props = {
  product: Partial<ProductType>;
  products: ProductType[];
  categories: Category[];
  setProduct: React.Dispatch<SetStateAction<Partial<ProductType>>>;
  setCategories: React.Dispatch<SetStateAction<Category[]>>;
  query: getAllProps;
  setQuery: React.Dispatch<SetStateAction<getAllProps>>;
  setProducts: React.Dispatch<SetStateAction<ProductType[]>>;
  setSelectedProducts: any;
  addTag: any;
  totalItems: number;
  submitValues: any;
  getProduct: (id: string) => void;
  updateState: any;
  getCategories: any;
  handleDeleteImg: any;
  handleQueryChange: any;
  handleLimitChange: any;
  handlePageChange: any;
  handleDeleteTag: any;
  handleSelectLabel: any;
  getSellerProducts: any;
  handleSearchByQuery: any;
  verifyCategoryChecked: any;
  HandleDeleteCompleted: any;
  handleSelectAllProducts: any;
  handleClearCategories: Function;
  tagInputRef: any;
  selectedItems: any;
  isDragActive: any;
  isDragAccept: any;
  isDragReject: any;
  getRootProps: any;
  getInputProps: any;
  updateProductState: any;
};

export const initialProductState: Partial<ProductType> = {
  name: null,
  description: null,
  price: null,
  regularPrice: null,
  isActive: true,
  shippingInfo: {
    weight: null,
    height: null,
    width: null,
    length: null,
  },
  status: false,
  categories: [],
  imgs: [],
  discount: [],
  hangTags: [],
  owner: '',
  stockInfo: { qnt: 0, sku: '', SoldIndividually: true },
  virtualProduct: false,
  tags: [],
};
