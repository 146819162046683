import { sdk } from "../sdkProvider";
// import { t } from "i18next";
import {
  ChangeEvent,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

import { getAllProps } from "ecommersys/dist/interfaces";

import { useTranslation } from "react-i18next";

import { Zoom } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "ecommersys/dist/Entities/notification.entitie";

const AdminNotificationsContext = createContext<props>(null);

export const AdminNotificationsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [notifications, setNotifications] = useState<
    Partial<notificationTypes>[]
  >([]);
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();

  const handleNotificationGetAll = async () => {
    sdk.Admin.notificationGetAll({}, (res) => {
      setNotifications(res.result);
    });
  };

  const handleNotificationGetSingle = async (notifyId) => {
    return await sdk.Admin.notificationGetSingle({
      key: "_id",
      value: notifyId,
    });
  };

  const handleNotificationAdd = async (notification) => {
    if (notification._id) {
      handleNotificationUpdateOne(notification);
      return;
    }

    sdk.Admin.notificationAdd(notification, (res) => {
      toast("Notificação adicionada com sucesso!", {
        type: "success",
      });

      setTimeout(() => {
        navigate("/dashboard/admin/notifications", { replace: true });
      }, 1000);
    });
  };

  const handleNotificationUpdateOne = async (notification) => {
    const loading = toast.loading("Atualizando notificação!");
    sdk.Admin.notificationUpdateOne(
      {
        notifyId: notification?._id,
        data: notification,
      },
      (res) => {
        toast.update(loading, {
          render: "Notificação atualizada com sucesso!",
          isLoading: false,
          type: "success",
          autoClose: 2000,
          delay: 1000,
        });

        setTimeout(() => {
          navigate("/dashboard/admin/notifications", { replace: true });
        }, 1000);
      }
    );
  };

  const handleNotificationDelete = async (notifyId) => {
    return await sdk.Admin.notificationDelete(
      {
        notifyId: notifyId,
      },
      (res) => {
        toast("Notificação deletada com sucesso!", {
          type: "success",
        });

        handleNotificationGetAll();
      }
    );
  };

  const values: props = {
    notifications,
    handleNotificationGetSingle,
    handleNotificationGetAll,
    handleNotificationAdd,
    handleNotificationUpdateOne,
    handleNotificationDelete,
  };

  return (
    <AdminNotificationsContext.Provider value={values}>
      {children}
    </AdminNotificationsContext.Provider>
  );
};

export const useAdminNotifications = () =>
  useContext(AdminNotificationsContext);

type props = {
  notifications: Partial<notificationTypes>[];
  handleNotificationGetSingle: any;
  handleNotificationGetAll: any;
  handleNotificationAdd: any;
  handleNotificationUpdateOne: any;
  handleNotificationDelete: any;
};
