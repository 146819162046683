import moment from 'moment-timezone';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';
Numeral.locale('pt-br');

export const convertToMonetary = (price: string) => {
  const productValor = Numeral(parseFloat(price)).format('0,0.[00]');

  return 'R$ ' + productValor;
};

export const converToLocalTime = (serverDate: any) => {
  const verifyDate =
    typeof serverDate === 'string' ? new Date(serverDate) : serverDate;
  var dt = new Date(Date.parse(verifyDate));
  var localDate = dt;

  var gmt = localDate;
  var min = gmt.getTime() / 1000 / 60; // convert gmt date to minutes
  var localNow = new Date().getTimezoneOffset(); // get the timezone
  // offset in minutes
  var localTime = min - localNow; // get the local time

  var dateStr: Date = new Date(localTime * 1000 * 60);
  // dateStr = dateStr.toISOString("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // this will return as just the server date format i.e., yyyy-MM-dd'T'HH:mm:ss.SSS'Z'

  return dateStr.toISOString().split('.')[0];
};

export const convertDateToDisplay = (data: string | Date) => {
  const verifyDate = typeof data === 'string' ? new Date(data) : data;

  return verifyDate.toISOString().split('T')[0];
};

export const convertDateTimeToDisplay = (data: string | Date) => {
  const verifyDate =
    typeof data === 'string'
      ? new Date(new Date(data).toString().split('GMT')[0] + ' UTC')
      : data;

  return verifyDate.toISOString().split('.')[0];
};

export const showDateString = (date: Date) => {
  return new Date(date).toLocaleDateString();
};

export const showDateTimeString = (date: Date) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const convertDateForDatabase = (date: Date) => {
  new Date(date).toISOString();
};

export const formatReal = (price: any) => {
  if (price === undefined || price === null) return null;

  return price.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const convertNumberWithDecimals = (str: string) => {
  var re = /\b(\d+)(\d{2})\b/;
  var subst = '$1.$2';
  var result = str.replace(re, subst);
  return result;
};

export const maskCpfCnpj = (v: string) => {
  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    v = v.substring(0, 14); // limita em 14 números
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return v;
};

export const pathCurrentOrNextNavigate = (
  url?: 'user' | 'seller' | 'admin',
) => {
  const pathNavigate = window.location.href;

  const validateingPastUrl = ['user', 'seller', 'admin'].includes(url)
    ? url
    : '';

  if (pathNavigate.includes('dashboard/user'))
    return `/${window.location.pathname.split('/')[1]}/${
      validateingPastUrl || 'user'
    }`;
  else if (pathNavigate.includes('dashboard/seller'))
    return `/${window.location.pathname.split('/')[1]}/${
      validateingPastUrl || 'seller'
    }`;
  else if (pathNavigate.includes('dashboard/admin'))
    return `/${window.location.pathname.split('/')[1]}/${
      validateingPastUrl || 'admin'
    }`;
  else {
    return window.location.origin;
  }
};

export const currentTypeUserNavigate = () => {
  const pathNavigate = window.location.href;

  if (pathNavigate.includes('dashboard/user')) return 'user';

  if (pathNavigate.includes('dashboard/seller')) return 'seller';

  if (pathNavigate.includes('dashboard/admin')) return 'admin';

  return 'global';
};

export const translateTextTable = (text: string) => {
  if (text === 'RECEIVED') {
    return 'Recebido';
  }
  if (text === 'CONFIRMED') {
    return 'Confirmado';
  }
  if (text === 'OVERDUE') {
    return 'Atrasado';
  }
  if (text === 'PENDING') {
    return 'Pendente';
  }
  if (text === 'undefined') {
    return 'Pendente';
  }

  if (text === 'REFUNDED') {
    return 'Devolvido';
  }

  if (text === 'Waiting') {
    return 'Aguardando';
  }

  if (text === 'Active') {
    return 'Ativo';
  }

  if (text === 'Validate') {
    return 'Validar';
  }

  if (text === 'released') {
    return 'Em Rota';
  }

  if (text === 'delivered') {
    return 'Entregue';
  }

  if (text === '') {
    return 'Aguardando...';
  }
};

export const statusActive = (text: boolean) => {
  if (text === true) {
    return 'Ativo';
  }
  if (text === false) {
    return 'Desativado';
  }
};

export const handleTelefone = (tel) => {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;

  var str = tel.replace(/[^0-9]/g, '');

  var telefole = '';

  if (str.length === 13) {
    telefole = tel.replace(/[^0-9]/g, '').slice(2, 13);
  }
  if (str.length === 11) {
    telefole = tel.replace(/[^0-9]/g, '').slice(0, 11);
  }

  const result = telefole.replace(regex, '($1)$2-$3');

  return result;
};
