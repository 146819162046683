import { ShoppingCart } from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { imgs } from 'src/assets/imgs';
import { useCart } from 'src/contexts/UserCartContext';
import { useUser } from 'src/contexts/UserContext';
import useViewport from 'src/hooks/useViewport';
import { UserLink } from './UserLink';

const TopHomeMenu = () => {
  const phone = useMediaQuery(`(max-width:500px)`);
  const { user } = useUser();
  const { setOpen, open } = useCart();
  const isMobile = useViewport().isMobile;

  return (
    <section className={`home_menuHome`}>
      <div className={`center-menu ${isMobile ? 'menus-mobile' : ''}`}>
        <Link to="/" className="logo-link">
          <img
            src={imgs.logo}
            alt="logo"
            className={`${isMobile ? 'mobile-logo' : ''} `}
          />
        </Link>
        <Box className={`-Menus`}>
          {links.map((item: any) => {
            if (item.name === 'cart') {
              if (!user) return null;

              return (
                <Link
                  to={item.to}
                  key={item.name}
                  onClick={() => setOpen(!open)}
                >
                  {item.icon}
                </Link>
              );
            }

            // eslint-disable-next-line no-unreachable
            return (
              <Link
                to={item.to}
                onClick={() => (item.onClick ? item.onClick() : null)}
                key={item.name}
              >
                {item.name}
              </Link>
            );
          })}
          <section className="-icons">
            {links.map((item: any) => {
              if (item.authRequired === true && !user) return null;
            })}
            {!user ? (
              <Link
                to="/login"
                style={{
                  background: '#c52bf2',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                Fazer login / criar conta
              </Link>
            ) : (
              <UserLink user={user} />
            )}
          </section>
        </Box>
      </div>
    </section>
  );
};

const links = [
  { name: 'QUEM SOMOS', to: '/quem-somos' },
  {
    name: 'cart',
    to: '#',
    icon: <ShoppingCart />,
    authRequired: true,
  },
  // { name: "BLOG", to: "/blog" },
  // { name: "CONTATO", to: "contato" },
];

export { TopHomeMenu };
