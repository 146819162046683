import { AccountStep } from './AccountStep';
import { ConfirmEmail } from './ConfirmEmail';
import { FinishStep } from './FinishStep';
import { FiscalStep } from './FiscalStep';

export const Steppers = [
  {
    number: 1,
    component: <AccountStep />,
    title: 'Informações pessoais',
  },
  {
    number: 2,
    component: <ConfirmEmail />,
    title: 'Confirmar email',
  },
  {
    number: 3,
    component: <FiscalStep />,
    title: 'Detalhes da empresa',
  },
  {
    number: 4,
    component: <FinishStep />,
    title: 'Registro completo',
  },
];
