import { CartResponse, Coupon } from 'ecommersys/dist/Entities';
import {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { sdk } from 'src/sdkProvider';
import { useUser } from './UserContext';

import { useNavigate } from 'react-router-dom';
import { api } from 'src/services/axiosInstance';
// import { useWebsocket } from './WebsocketContext';
import { useSDK } from './sdkContext';

const CartContext = createContext<CartValues>(null);

export const CartProvider = ({ children }: React.PropsWithChildren) => {
  const [cart, setCart] = useState<CartResponse>(initialCart);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const { connected } = useSDK();
  const { user } = useUser();
  const [pix, setPix] = useState<PixReturn>(null);
  const [boleto, setBoleto] = useState<Object | null>(null);
  const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
  const [
    uploadReceptToAllowedFinalizeOrder,
    setUploadReceptToAllowedFinalizeOrder,
  ] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [billingType, setBillingType] = useState<billingTypes>('PIX');
  const [transaction, setTransaction] = useState(null);
  const [statusPaymentConfirm, setStatusPaymentConfirm] =
    useState<boolean>(false);
  const [uploadInjectable, setUploadInjectable] = useState<
    Partial<uploadInjectableType>[]
  >([]);
  const [shipping, setShipping] = useState<Partial<shippingType>[]>([]);

  const navigate = useNavigate();
  // const { socket } = useWebsocket();

  const getMyCart = async () => {
    if (connected) {
      await sdk.User.cart.getMyCart(
        (res) => {
          setCart(res);
        },
        () => {},
      );
    }
  };

  const updateCarCount = (condition: string, index: number) => {
    if (index) {
      const updateProduct = Array.from(cart.products);

      updateProduct[index] = {
        ...updateProduct[index],
        amount:
          condition === 'increment'
            ? updateProduct[index].amount + 1
            : condition === 'remove'
            ? updateProduct[index].amount - updateProduct[index].amount
            : updateProduct[index].amount - 1,
      };

      if (updateProduct[index].amount === 0) updateProduct.splice(index, 1);

      let totalPrice = cart.totalPrice;

      totalPrice = updateProduct[index]
        ? condition === 'increment'
          ? totalPrice + +updateProduct[index].price
          : totalPrice - +updateProduct[index].price
        : null;

      setCart((state) => ({ ...state, products: updateProduct, totalPrice }));
    } else {
      getMyCart();
    }
  };

  const incrementProduct = async (productId: string, qnt?: number) => {
    const quantity = qnt ? qnt : 1;

    if (quantity >= 1) {
      await api.patch(`/carts/incrementProduct/${cart._id}`, {
        amount: quantity,
        productId: productId,
        cartId: cart._id,
      });

      const productIndex = cart.products?.findIndex(
        (product) => product._id === productId,
      );
      updateCarCount('increment', productIndex === -1 ? 0 : productIndex);
    }
  };

  const decrementProduct = (productId: string, qnt?: number) => {
    sdk.User.cart.decrementProduct(
      { productId, cartId: cart._id, amount: qnt || 1 },
      (res) => {
        // updateCarCount("decrement", index);
        const productIndex = cart.products.findIndex(
          (product) => product._id === productId,
        );
        updateCarCount('decrement', productIndex);
      },
    );
  };

  const removeProduct = async (productId: string, qnt?: number) => {
    // sdk.User.cart.decrementProduct(
    //   { productId, cartId: cart._id, amount: qnt - 1 || 1 },
    //   (res) => {
    //     // updateCarCount("decrement", index);
    //     const productIndex = cart.products.findIndex(
    //       (product) => product._id === productId
    //     );
    //     updateCarCount("remove", productIndex);
    //   }
    // );

    // patch carts/removeProduct/cartId e no body vc manda {cartId:string,productId:string}
    await api.patch(`/carts/removeProduct/${cart._id}`, {
      cartId: cart._id,
      productId: productId,
    });
    const productIndex = cart.products.findIndex(
      (product) => product._id === productId,
    );
    updateCarCount('remove', productIndex);
  };

  const insertCoupon = (id: string) => {
    sdk.User.cart.insertCoupon(
      { couponId: id },
      (res) => {
        getMyCart();
      },
      () => {
        toast('Não foi possível validar cupom inserido', { type: 'warning' });
      },
    );
  };

  // const confirmPaymentPix = (paymentId: string) => {
  //   socket
  //     .off(`confirmPix/${paymentId}`)
  //     .on(`confirmPix/${paymentId}`, (data) => {
  //       setStatusPaymentConfirm(
  //         ['CONFIRMED', 'RECEIVED'].includes(data.payment.status),
  //       );
  //       socket.off(`confirmPix/${paymentId}`);

  //       setTimeout(() => {
  //         navigate(`/dashboard/user/requests/list`, {
  //           replace: true,
  //         });
  //       }, 1000);
  //     });
  // };

  const handleGenPag = async (
    cardPaymentSelected?: string,
    installmentSelected?: number,
    asaasByPass?: boolean,
  ) => {
    setLoadingTransaction(true);

    const cardData = {
      cardToken: cardPaymentSelected || '',
      installmentCount: installmentSelected || '',
    };

    try {
      const gen = await api.post('/sales/genPayment', {
        billingType,
        documents: uploadInjectable,
        ...cardData,
        shipping,
      });

      setTransaction(gen.data);

      if (billingType === 'BOLETO') {
        setBoleto(gen.data);
        setLoadingTransaction(false);
        setStatusPaymentConfirm(true);
        toast('Boleto gerado com sucesso!', { type: 'success' });
      }

      if (billingType === 'CREDIT_CARD') {
        if (['CONFIRMED'].includes(gen.data.generatePayment.status)) {
          setLoadingTransaction(false);
          setStatusPaymentConfirm(true);

          setTimeout(() => {
            navigate(`/dashboard/user/requests/list`, {
              replace: true,
            });
          }, 3000);
        }

        toast('Compra efetuada com sucesso!', { type: 'success' });
      }

      if (billingType === 'PIX') {
        // confirmPaymentPix(gen.data.insertOnRepo._id);
        setLoadingTransaction(false);
        setPix(gen.data.getQRCodePix);
      }

      if (billingType === 'BY_PASS_ASAAS') {
        setLoadingTransaction(false);
        toast('Pré pedido efetuado com sucesso! Entraremos em contato', {
          type: 'success',
        });

        setTimeout(() => {
          navigate(`/dashboard/user/requests/${gen.data.orders[0].id}`, {
            replace: true,
          });
        }, 3000);
      }
    } catch (err) {
      setLoadingTransaction(false);
      toast('Erro ao Concluir Pagamento!', { type: 'error' });
    }
  };

  useEffect(() => {
    if (user?._id) getMyCart();
  }, [user]);

  const values: CartValues = {
    cart,
    getMyCart,
    incrementProduct,
    decrementProduct,
    coupons,
    insertCoupon,
    pix,
    boleto,
    setPix,
    transaction,
    loadingTransaction,
    setLoadingTransaction,
    billingType,
    setBillingType,
    handleGenPag,
    statusPaymentConfirm,
    uploadInjectable,
    setUploadInjectable,
    uploadReceptToAllowedFinalizeOrder,
    setUploadReceptToAllowedFinalizeOrder,
    shipping,
    setShipping,
    setOpen,
    open,
    removeProduct,
  };
  return <CartContext.Provider value={values}>{children}</CartContext.Provider>;
};

export const useCart = () => useContext(CartContext);

type CartValues = {
  cart: CartResponse;
  getMyCart: () => void;
  incrementProduct: (productId: string, qnt?: number) => void;
  decrementProduct: (productId: string, qnt?: number) => void;
  removeProduct: (productId: string, qnt?: number) => void;
  coupons: Coupon[];
  insertCoupon: (id: string) => void;
  pix: PixReturn;
  boleto: any;
  transaction: any;
  setPix: React.Dispatch<SetStateAction<PixReturn>>;
  loadingTransaction: boolean;
  setLoadingTransaction: React.Dispatch<SetStateAction<boolean>>;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  open: boolean;
  billingType: billingTypes;
  setBillingType: React.Dispatch<SetStateAction<billingTypes>>;
  handleGenPag: (
    cardPaymentSelected?: string,
    installmentSelected?: number,
    asaasByPass?: boolean,
  ) => void;
  statusPaymentConfirm: boolean;
  uploadInjectable: Partial<uploadInjectableType>[];
  uploadReceptToAllowedFinalizeOrder: boolean;
  setUploadInjectable: React.Dispatch<
    SetStateAction<Partial<uploadInjectableType>[]>
  >;
  shipping: Partial<shippingType>[];
  setShipping: React.Dispatch<SetStateAction<Partial<shippingType>[]>>;
  setUploadReceptToAllowedFinalizeOrder: (allowed: boolean) => void;
};

const initialCart: CartResponse = {
  owner: '',
  isActive: false,
  totalPrice: 0,
  products: [],
  coupons: [],
  totalDiscount: 0,
};

export type PixReturn = {
  success: true;
  encodedImage: string;
  expirationDate: string;
  payload: string;
};

export type billingTypes =
  | 'BOLETO'
  | 'CREDIT_CARD'
  | 'UNDEFINED'
  | 'TRANSFER'
  | 'DEPOSIT'
  | 'PIX'
  | 'BY_PASS_ASAAS';

export type uploadInjectableType = {
  docId: string;
  productId: string[];
  docUrl: string;
};

export type shippingType = {
  storeId: string;
  shippingSelected: Object;
};
