import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { IMaskInput } from "react-imask";
import { Link } from "react-router-dom";
import { Link as LinkExternal } from "@mui/material";
import { imgs } from "../../assets/imgs";
import { useRef, useState } from "react";
import { useEffect } from "react";

const Footer = () => {
  const [telefone, setTelefone] = useState("");
  const PhoneMask = useRef(null);
  const handleContactSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const objData = {
      nome: data.get("nome"),
      telefone: telefone || PhoneMask.current.value,
      email: data.get("email"),
      mensagem: data.get("mensagem"),
    };
  };

  useEffect(() => {
    if (PhoneMask.current) {
      const imask = PhoneMask.current;
      imask.className = "textInput";
      imask.placeholder = "(00) 00000-0000";
    }
  }, [PhoneMask]);

  return (
    <section className="home_footer">
      <Box className="bottomFooter">
        <img src={imgs.logo} alt="logo" />
        <h1>TODOS DIREITOS RESERVADOS - CLINIKS 2022</h1>
        <Box>
          <Link to="/">
            <Button>POLÍTICA DE PRIVACIDADE</Button>
          </Link>
          |
          <Link to="/">
            <Button>TERMOS DE SERVIÇO</Button>
          </Link>
        </Box>
      </Box>
    </section>
  );
};
export { Footer };
