import styled, { css } from 'styled-components';

export const RegisterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AreaSteps = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;

export const AreaStep = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const StepNumber = styled.div`
  ${({ active, completed }: { active?: boolean; completed?: boolean }) => css`
    width: 20px;
    height: 20px;
    background: ${active || completed ? '#1976d2' : '#9c9c9c'};
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 12px;

    p {
      color: #fff;
      font-weight: 300;
    }
  `}
`;

export const StepTitle = styled.div`
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
`;

export const AreaButtons = styled.div`
  width: 100%;

  ${({ mobile }: { mobile?: boolean }) =>
    mobile &&
    css`
      display: flex;
      flex-direction: column-reverse;
    `}

  ${({ mobile }: { mobile?: boolean }) =>
    !mobile &&
    css`
      display: flex;
      justify-content: space-between;
    `}
`;
