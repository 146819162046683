import { ProductsReturn, Store, User } from 'ecommersys/dist/Entities';
import {
  ChangeEvent,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Zoom } from '@mui/material';
import { getAllProps } from 'ecommersys/dist/interfaces';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { ClientType } from 'src/models/client';
import { api } from 'src/services/axiosInstance';
import { sdk } from '../sdkProvider';

const SellerOrdersContext = createContext<props>(null);

export const SellerOrdersProvider = ({ children }: React.PropsWithChildren) => {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { getMyUser } = useAuth();

  const [order, setOrder] = useState<Partial<Orders>>(initialOrdersState);
  const [orders, setOrders] = useState<Orders[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const [storeName, setStoreName] = useState<Orders[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [limit, setLimit] = useState<number>(5);
  const [totalItems, setTotalItems] = useState(0);

  const [page, setPage] = useState({ page: 0, totalPage: 0 });

  const [uploadInjectable, setUploadInjectable] = useState<
    Partial<uploadInjectableType>[]
  >([]);

  const [clientList, serClientsList] = useState<ClientType[]>([]);

  const [query, setQuery] = useState<getAllProps>({
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });

  const [selectedItems, setSelectedOrders] = useState<string[]>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [storeList, setStoreList] = useState<Partial<Store[]>>([]);
  const [selectStore, setSelectStore] = useState<Partial<Store> | null>(null);

  const handleStoreGetAll = (filter) => {
    sdk.Global.getAllSellers({ filter, page: 0, size: 1000 }, (res) => {
      if (res.result.length === 0) {
        toast('Nenhuma loja encontrada para essa pesquisa!', {
          type: 'info',
          delay: 5000,
        });
      } else {
        setStoreList(res.result);
      }
    });
  };

  const getMyBuys = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter },
    };

    setLoader(true);
    const response = await api.get('/users/getMyBuys', {
      params: queryParam,
    });

    setLoader(false);

    setOrders(response.data.result);

    if (isMountedRef.current) {
    }
  };

  const getMyCLientBuys = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter },
    };

    const response = await api.get('/users/getMyBuys', {
      params: {
        page: 0,
        size: 1000,
        filter: {
          key: '',
          value: '',
          fields: '',
        },
      },
    });

    setTotalItems(response.data.result.length);

    setStoreName(response.data.result);

    if (isMountedRef.current) {
    }
  };

  const handleSetSelectStore = (name: string) => {
    const filterStoreSelect: Partial<Store> = storeList.find(
      (item) => item.name === name,
    );

    if (filterStoreSelect?._id) {
      getOrderByStore(filterStoreSelect._id);
    } else {
      getMyBuys();
    }
  };

  const handleClientGetAll = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter, fields: 'userInfo username img' },
    };

    const getClients = await api.get('/clients/myClients', {
      params: queryParam,
    });

    serClientsList(getClients.data);
  };

  const handleSetSelectClient = (name: string) => {
    const filterClientSelect: Partial<ClientType> = clientList.find(
      (item) => item.clientName === name,
    );

    if (filterClientSelect?.clientId) {
      getOrderByClient(filterClientSelect.clientName);
    } else {
      getMySells();
    }
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the order'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Zoom,
    });
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '') {
      setQuery((state) => ({
        ...state,
        filter: {
          key: '',
          value: '',
        },
      }));

      return;
    }

    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        key: 'id',
        value: event.target.value,
      },
    }));
  };

  const handleQueryChangeClient = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        key: 'client.userInfo.name',
        value: event.target.value,
      },
    }));
  };

  const handleSelectAllOrders = (): void => {
    const selectAll: string[] = orders.map((order) => order._id as string);
    setSelectedOrders((selecteds) => (selecteds.length === 0 ? selectAll : []));
  };

  const handleSelectOneclient = (
    event: ChangeEvent<HTMLInputElement>,
    clientId: string,
  ): void => {
    if (!selectedItems.includes(clientId)) {
      setSelectedOrders((prevSelected) => [...prevSelected, clientId]);
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((id) => id !== clientId),
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage }));

    getMySells({ ...query, page: newPage });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value) }));

    getMySells({ ...query, size: parseInt(value), page: 0 });
  };

  const handlePageChangeOrder = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage }));
    getMyBuys({ ...query, page: newPage });
  };

  const handleLimitChangeOrder = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value) }));
    getMyBuys({ ...query, size: parseInt(value), page: 0 });
  };

  const handleSearchByQuery = (e) => {
    e.preventDefault();
    getMySells();
  };
  const handleSearchByQueryRequests = (e) => {
    e.preventDefault();
    getMyBuys();
  };

  function compare(a, b) {
    return (
      new Date(b?.sale?.createdAt).getTime() -
      new Date(a?.sale?.createdAt).getTime()
    );
  }

  const getMySells = async (queryProps: getAllProps = query) => {
    const user: User = await getMyUser();
    setLoader(true);

    if (user) {
      const queryParam = {
        ...queryProps,
        filter: {
          key: 'storeId ' + query.filter.key,
          value: `${user.storeId} ` + query.filter.value,
        },
      };

      const response = await api.get('/orders/all', {
        params: queryParam,
      });

      setLoader(false);
      setPage({
        page: response.data.thisPage,
        totalPage: response.data.totalPage,
      });

      setOrders(response.data.result.sort(compare));

      setTotalItems(response.data.totalItems);
    }
  };

  const getMySellsByState = async (status) => {
    const user: User = await getMyUser();
    setLoader(true);
    if (user) {
      const queryParamSales = {
        ...query,
        filter: {
          key: 'storeId',
          value: `${user.storeId}`,
        },
        paymentStatus: status,
      };

      const sales = await api.get('/orders/all', {
        params: queryParamSales,
      });

      setLoader(false);
      setOrders(sales.data.result);
    }
  };

  const getMySale = useCallback(
    async (id) => {
      try {
        const user: User = await getMyUser();
        const response = await api.get(`/sales/${id}`);

        let getMySaleByStoreId = response.data;

        getMySaleByStoreId.sellers = getMySaleByStoreId.sellers.filter(
          (seller) => {
            if (seller.storeId === user.storeId) {
              getMySaleByStoreId.totalValue = seller.products.reduce(
                (sum, totalBuyPerSeller) => {
                  return (
                    parseFloat(sum) + parseFloat(totalBuyPerSeller.totalValue)
                  );
                },
                0,
              );

              return true;
            }
          },
        );

        getMySaleByStoreId.storeIds = getMySaleByStoreId.storeIds.filter(
          (store) => store === user.storeId,
        );

        setOrder(getMySaleByStoreId);
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef],
  );

  const getMySell = async (id) => {
    const queryParam = {
      filter: { key: 'id', value: id },
    };

    const responsresponseORders = await api.get('/orders/all', {
      params: queryParam,
    });

    const orderFind = responsresponseORders.data.result.find(
      (order) => order.id === id,
    );

    setOrder(orderFind);
  };

  const getMyBuy = async (id) => {
    const queryParam = {
      filter: { key: 'id', value: id },
    };

    const response = await api.get('/users/getMyBuys', {
      params: queryParam,
    });

    const myBuy = response.data.result.find((order) => order.id === id);
    setOrder(myBuy);
    if (isMountedRef.current) {
    }
  };

  const getBuyClient = async (id?) => {
    const queryParam = {
      ...query,
      filter: {
        key: '_id ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const getOrders = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrder(getOrders.data.result[0]);
  };
  const getOrderByStore = async (id) => {
    const queryParam = {
      ...query,
      filter: {
        key: 'storeIds ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const response = await api.get('/users/getMyBuys', {
      params: queryParam,
    });

    setOrders(response.data.result);
  };

  const getOrderByClient = async (name) => {
    const user: User = await getMyUser();
    setLoader(true);
    if (user) {
      const queryParamSales = {
        ...query,
        filter: {
          key: 'storeId userName ' + query.filter.key,
          value: `${user.storeId} ` + `${name} ` + query.filter.value,
        },
      };

      const response = await api.get('/orders/all', {
        params: queryParamSales,
      });

      setLoader(false);

      setOrders(response.data.result);
    }
  };

  const getMySellsByStateShipping = async (status) => {
    const user: User = await getMyUser();

    if (user) {
      const queryParamSales = {
        ...query,
        filter: {
          key: 'storeId ' + 'shippingStatus',
          value: `${user.storeId} ` + `${status}`,
        },
        // paymentStatus: status,
      };

      const sales = await api.get('/orders/all', {
        params: queryParamSales,
      });

      setOrders(sales.data.result);
    }
  };

  const handleSelectSearchByQuery = async (e) => {
    e === 'PENDING' || e === 'OVERDUE' || e === 'CONFIRMED'
      ? await getMySellsByState(e)
      : getMySells();
  };

  const handleSelectStateShippingByQuery = async (e) => {
    e === 'delivered' || e === 'released' || e === 'pending'
      ? await getMySellsByStateShipping(e)
      : getMySells();
  };

  const handleUpdateOrderPresciptions = async (id) => {
    await api.post(`/sales/updateDocument/${id}`, {
      document: uploadInjectable[0],
    });
    window.location.reload();
  };

  const values: props = {
    loader,
    getMySells,
    handleUpdateOrderPresciptions,
    getMySell,
    getMyBuys,
    getMyBuy,
    orders,
    order,
    setOrders,
    query,
    setQuery,
    handleQueryChange,
    handleSearchByQuery,
    handleSelectAllOrders,
    handlePageChange,
    handleLimitChange,
    selectedItems,
    handleSelectStateShippingByQuery,
    setSelectedOrders,
    handleDeleteCompleted,
    getMyCLientBuys,
    handleConfirmDelete,
    closeConfirmDelete,
    handleSelectOneclient,
    openConfirmDelete,
    setOrder,
    getOrderByStore,
    handleSelectSearchByQuery,
    getBuyClient,
    handleSearchByQueryRequests,
    totalItems,
    handleQueryChangeClient,
    getMySale,
    selectStore,
    handleClientGetAll,
    handleSetSelectClient,
    clientList,
    storeList,
    handleStoreGetAll,
    handleSetSelectStore,
    uploadInjectable,
    setUploadInjectable,
    handlePageChangeOrder,
    handleLimitChangeOrder,
    setStoreName,
    storeName,
    page,
  };

  return (
    <SellerOrdersContext.Provider value={values}>
      {children}
    </SellerOrdersContext.Provider>
  );
};

export const useSellerOrders = () => useContext(SellerOrdersContext);

type props = {
  loader: boolean;
  handleClientGetAll: any;
  handleSetSelectClient: any;
  clientList: Partial<ClientType[]>;
  totalItems: number;
  getMySells: any;
  getMySell: any;
  handleStoreGetAll: any;
  handleSelectStateShippingByQuery: any;
  handleSetSelectStore: any;
  getMyBuys: any;
  getMyBuy: any;
  getBuyClient;
  orders: any;
  storeName: any;
  order: any;
  page: any;
  getMyCLientBuys: any;
  setOrders: any;
  setStoreName: any;
  handleSearchByQueryRequests: any;
  setOrder: any;
  query: getAllProps;
  getOrderByStore: any;
  setQuery: React.Dispatch<SetStateAction<getAllProps>>;
  handleQueryChange: any;
  handleSearchByQuery: any;
  handleSelectAllOrders: any;
  handlePageChange: any;
  handleLimitChangeOrder: any;
  handlePageChangeOrder: any;
  handleUpdateOrderPresciptions: any;
  handleLimitChange: any;
  selectedItems: any;
  setSelectedOrders: any;
  handleDeleteCompleted: any;
  openConfirmDelete: any;
  handleConfirmDelete: any;
  closeConfirmDelete: any;
  handleSelectOneclient: any;
  handleQueryChangeClient: any;
  handleSelectSearchByQuery: any;
  getMySale: any;
  selectStore: Partial<Store>;
  uploadInjectable: Partial<uploadInjectableType>[];
  setUploadInjectable: React.Dispatch<
    SetStateAction<Partial<uploadInjectableType>[]>
  >;
  storeList: Partial<Store[]>;
};

export const initialOrdersState: Partial<Sales> = {
  _id: '',
  sellers: [],
  userId: '',
  documents: [],
  addressId: '',
  clientAddress: {
    address: '',
    city: '',
    complement: '',
    country: '',
    district: '',
    isActive: false,
    number: '',
    owner: '',
    state: '',
    email: '',
  },
  payment: {
    amount: 0,
    paymentMethod: '',
    cardToken: '',
    id: '',
  },
  coupons: [],
  totalValue: 0,
  totalDiscount: 0,
  totalItems: 0,
  billingType: '',
  paymentStatus: '',
  paymentId: '',
  storeIds: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const initialOrders: Partial<Orders> = {
  _id: '',
  coupons: [],
  meLogs: [],
  products: [],
  userId: '',
  saleId: '',
  shipping: [],
  shippingstatus: '',
  totalValue: 0,
  totalDiscount: 0,
  userName: '',
  sale: [],
};

export type Sales = {
  _id?: string;
  sellers: StoreMapped[];
  userId: string;
  addressId: string;
  clientAddress: {
    address: string;
    city: string;
    complement: string;
    country: string;
    district: string;
    isActive: boolean;
    number: string;
    owner: string;
    state: string;
    email: string;
  };
  documents: any;
  payment?: {
    amount: number;
    paymentMethod: string;
    cardToken: string;
    id: string;
  };
  coupons: string[];
  totalValue: number;
  totalDiscount: number;
  totalItems: number;
  billingType: string;
  paymentStatus?: string;
  paymentId?: string;
  storeIds: string[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type Orders = {
  _id?: string;
  coupons?: string[];
  meLogs?: string[];
  products?: ProductsReturn[];
  userId?: string;
  saleId?: string;
  shipping?: string[];
  shippingstatus?: string;
  totalValue?: number;
  totalDiscount?: number;
  userName: string;
  sale: Sales[];
};

export type ProductSaleInfo = {
  productId: string;
  amount: number;
};

export type StoreMapped = {
  storeId: string;
  products: ProductsReturn[];
  totalPrice: number;
  walletId: string;
  totalDiscount: number;
  coupons: string[];
};

export type uploadInjectableType = {
  docId: string;
  productId: string[];
  docUrl: string;
};

export type paymentTypes =
  | 'PENDING'
  | 'RECEIVED'
  | 'CONFIRMED'
  | 'OVERDUE'
  | 'REFUNDED'
  | 'REFUND_REQUESTED'
  | 'CHARGEBACK_REQUESTED'
  | 'CHARGEBACK_DISPUTE'
  | 'AWAITING_CHARGEBACK_REVERSAL'
  | 'DUNNING_REQUESTED'
  | 'AWAIT_RISK_ANALYSIS';

export type billingType =
  | 'BOLETO'
  | 'CREDIT_CARD'
  | 'UNDEFINED'
  | 'TRANSFER'
  | 'DEPOSIT'
  | 'PIX';

export type clientAddress = {
  address: string;
  city: string;
  complement: string;
  country: string;
  district: string;
  isActive: boolean;
  number: string;
  owner: string;
  state: string;
  email: string;
};

// export type Shipping = {
//   configs: string[];
//   estimated: string[];
//   packages: string[];
// };

// //configsShipping
// export type ConfigsShipping = {
//   additional_services: Additional_services[];
//   company: Company[];
//   currency: string;
//   name: string;
// };

// export type Additional_services = {
//   collect: boolean;
//   own_hand: boolean;
//   receipt: boolean;
// };
// export type Company = {
//   id: string;
//   name: string;
//   picture: string;
// };

// //estimated
// export type Estimated = {
//   custom_delivery_range: MaxMin[];
//   custom_delivery_time: number;
//   custom_price: number;
//   delivery_range: MaxMin[];
//   delivery_time: number;
// };

// export type MaxMin = {
//   max: number;
//   min: number;
// };
