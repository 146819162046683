import { filesize } from 'filesize';
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UploadMessage } from 'src/components/Forms/styles';
import { api } from 'src/services/axiosInstance';
import { useUser } from './UserContext';

const RegisterContext = createContext<any>(null);

const RegisterProvider = ({ children }: React.PropsWithChildren) => {
  const [loadingCodigo, setLoadingCodigo] = useState(false);

  const [confirmEmail, setConfirmEmail] = useState(false);

  const [imgPreview, setImgPreview] = useState(null);

  const [loading, setLoading] = useState(false);

  const [codigo, setCodigo] = useState(false);

  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const lastStep = 3;

  const [AuthData, setAuthData] = useState<authDataType>({
    username: '',
    password: '',
    confirmPassword: '',
    check: true,
    img: null,
    userInfo: {
      name: '',
      lastName: '',
      ie: '',
      cpf: '',
      rg: '',
      cnpj: '',
      cnae: '',
      personType: 'juridica',
      enterpriseName: '',
      enterpriseSocial: '',
      address: '',
      number: '',
      phone: '',
      complement: '',
      birthDate: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      fantasyName: '',
      instagram: '',
    },
  });

  const { login } = useUser();

  const refs = {
    dateMaskRef: useRef<any>(),
    cepMaskRef: useRef<any>(),
    cpfMaskRef: useRef<any>(),
    codigoRef: useRef<any>(),
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (AuthData.userInfo.personType === 'juridica') {
      if (
        !AuthData.username ||
        !AuthData.password ||
        !AuthData.userInfo.name ||
        !AuthData.userInfo.lastName ||
        !AuthData.userInfo.address ||
        !AuthData.userInfo.city ||
        !AuthData.userInfo.state ||
        !AuthData.userInfo.zipCode ||
        !AuthData.userInfo.fantasyName ||
        !AuthData.userInfo.email ||
        !AuthData.userInfo.phone ||
        !AuthData.userInfo.cnpj ||
        !AuthData.userInfo.enterpriseSocial
      ) {
        setLoading(false);

        return toast('Preencha todos os dados para continuar...', {
          type: 'error',
        });
      }
    }

    if (AuthData.userInfo.personType === 'fisica') {
      if (
        !AuthData.username ||
        !AuthData.password ||
        !AuthData.userInfo.name ||
        !AuthData.userInfo.lastName ||
        !AuthData.userInfo.address ||
        !AuthData.userInfo.city ||
        !AuthData.userInfo.state ||
        !AuthData.userInfo.zipCode ||
        !AuthData.userInfo.cpf ||
        !AuthData.userInfo.rg ||
        !AuthData.userInfo.email ||
        !AuthData.userInfo.phone
      ) {
        setLoading(false);

        return toast('Preencha todos os dados para continuar...', {
          type: 'error',
        });
      }
    }

    if (!AuthData.check) {
      setLoading(false);
      return toast('Precisa Concordar com os termos para continuar...', {
        type: 'error',
      });
    }

    const form = new FormData();

    for (let data in AuthData) {
      if (data !== 'userInfo') {
        form.append(data, AuthData[data]);
      } else {
        for (let userData in AuthData.userInfo) {
          form.append(`${data}[${userData}]`, AuthData.userInfo[userData]);
        }
      }
    }
    form.append(
      `affiliatedCode`,
      window.localStorage.getItem('affilatedCode') || '',
    );
    try {
      const postUser = await api.post('/users', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (!postUser.data.username) throw new Error();
      toast('Usuário criado com sucesso! Redirecionando...', {
        type: 'success',
      });
      setLoading(false);
      setTimeout(async () => {
        await login({
          username: AuthData.username,
          password: AuthData.password,
        });
        navigate('/', { replace: true });

        setTimeout(async () => {
          window.location.reload();
        });
      }, 2000);
    } catch (err) {
      setLoading(false);
      toast(err.response.data.message, {
        type: 'error',
      });
    }
  };

  const ChangePassword = async (
    event: React.FormEvent<HTMLFormElement>,
    values: { email: string; password: string; confirmPassword: string },
  ) => {
    event.preventDefault();
    setLoading(true);

    if (!values?.password || !values?.email || !values?.confirmPassword) {
      setLoading(false);
      return toast('Preencha todos os dados para continuar...', {
        type: 'error',
      });
    }

    try {
      if (values?.password === values?.confirmPassword) {
        const changePassword = await api.post('/auth/changePassword', {
          email: values.email,
          password: values.password,
        });

        if (!changePassword.data) throw new Error();
        toast('Senha atualizada com sucesso! Redirecionando...', {
          type: 'success',
        });
        setLoading(false);
        setTimeout(() => {
          navigate('/', { replace: true });
          document.location.reload();
        }, 1000);
      } else {
        toast('As senhas devem ser iguais!', {
          type: 'error',
        });
      }
    } catch (err) {
      toast('Não foi possível atualizar a senha.', {
        type: 'error',
      });

      setLoading(false);
    }
  };

  const UpdateAuthData = (e: any) => {
    const { name, value } = e?.target;
    const userInfoNames = [
      'name',
      'lastName',
      'cpf',
      'cnpj',
      'ie',
      'rg',
      'cnae',
      'enterpriseName',
      'enterpriseSocial',
      'address',
      'number',
      'birthDate',
      'complement',
      'city',
      'phone',
      'state',
      'zipCode',
      'email',
      'personType',
      'fantasyName',
      'instagram',
    ];

    if (userInfoNames.includes(name)) {
      const username = name === 'email' ? { username: value } : null;
      return setAuthData({
        ...AuthData,
        ...username,
        userInfo: { ...AuthData.userInfo, [name]: value },
      });
    }
    setAuthData({ ...AuthData, [name]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFile = (e: any) => {
    if (e) {
      const newUploadedFiles: IFile = {
        file: e,
        id: e.name,
        name: e.name,
        readableSize: filesize(e.size),
        preview: URL.createObjectURL(e),
      };
      setImgPreview(newUploadedFiles);
      UpdateAuthData({ target: { name: 'img', value: newUploadedFiles.file } });
    }
  };

  const confirmUserEmailExist = async () => {
    const getUserFromEmail = await api.post('/auth/accountExistence', {
      username: AuthData.username,
    });

    return getUserFromEmail.data;
  };

  const handleCreateCodigo = async (isChangePassword?: Boolean) => {
    setLoadingCodigo(true);

    try {
      const confirmUserEmail = await confirmUserEmailExist();
      if (confirmUserEmail && !isChangePassword) {
        setLoadingCodigo(false);

        return toast(
          'Já existe um usuário com esse e-mail, acesse com as credenciais ou insira um novo e-mail.',
          { type: 'error' },
        );
      }

      await api.post('/users/createEmailToken', {
        email: AuthData.userInfo.email,
      });

      setCodigo(true);
      setLoadingCodigo(false);
      toast(
        'Código enviado com sucesso! Confirme em seu e-mail para continuar.',
        { type: 'success' },
      );
    } catch (err) {
      toast(
        'Não foi possível enviar o código, verifique o e-mail e tente novamente.',
        { type: 'error' },
      );
      setLoadingCodigo(false);
    }
    setLoadingCodigo(false);
  };

  const handlRecoveryCode = async (isChangePassword?: Boolean) => {
    setLoadingCodigo(true);

    try {
      const confirmUserEmail = await confirmUserEmailExist();
      if (confirmUserEmail && !isChangePassword) {
        setLoadingCodigo(false);

        return toast(
          'Já existe um usuário com esse e-mail, acesse com as credenciais ou insira um novo e-mail.',
          { type: 'error' },
        );
      }

      await api.post('/users/password-recovery', {
        email: AuthData.userInfo.email,
      });

      setCodigo(true);
      setLoadingCodigo(false);
      toast(
        'Código enviado com sucesso! Confirme em seu e-mail para continuar.',
        { type: 'success' },
      );
    } catch (err) {
      toast(
        'Não foi possível enviar o código, verifique o e-mail e tente novamente.',
        { type: 'error' },
      );
      setLoadingCodigo(false);
    }
    setLoadingCodigo(false);
  };

  const handleSubmitCodigo = async (recoveryPass = false) => {
    setLoadingCodigo(true);
    const code = refs.codigoRef.current.value;
    try {
      if (!code && code.length !== 4) {
        setLoadingCodigo(false);

        return '';
      }

      await api.post('/users/confirmEmailToken', {
        email: AuthData.userInfo.email,
        code,
      });

      if (!recoveryPass) {
        const form = new FormData();

        for (let data in AuthData) {
          if (data !== 'userInfo') {
            form.append(data, AuthData[data]);
          } else {
            for (let userData in AuthData.userInfo) {
              form.append(`${data}[${userData}]`, AuthData.userInfo[userData]);
            }
          }
        }
        form.append(
          `affiliatedCode`,
          window.localStorage.getItem('affilatedCode') || '',
        );

        await api.post('/users', form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      setConfirmEmail(true);
      setLoadingCodigo(false);

      toast('Código confirmado com sucesso!', { type: 'success' });

      return true;
    } catch (err) {
      setLoadingCodigo(false);
      toast(
        'Não foi possível confirmar o código, verifique o e-mail e tente novamente.',
        { type: 'error' },
      );

      return false;
    }
  };

  const onDrop = useCallback(
    (files: any) => {
      getFile(files[0]);
    },
    [getFile],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      // accept: ["image/jpeg", "image/jppeg", "image/png", "image/gif"],
      onDrop,
    });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique aqui ou arraste a imagem para inserir
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Tipo de arquivo não suportado
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte as imagens aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);

  const values = {
    lastStep,
    step,
    imgPreview,
    setImgPreview,
    setAuthData,
    loading,
    confirmEmail,
    AuthData,
    UpdateAuthData,
    handleSubmit,
    renderDragMessage,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    handleCreateCodigo,
    handlRecoveryCode,
    handleSubmitCodigo,
    refs,
    loadingCodigo,
    setStep,
    codigo,
    ChangePassword,
    confirmUserEmailExist,
  };
  return (
    <RegisterContext.Provider value={values}>
      {children}
    </RegisterContext.Provider>
  );
};

const useRegister = () => useContext<useUserValues>(RegisterContext);

export { RegisterProvider, useRegister };

type useUserValues = {
  lastStep: any;
  step: any;
  imgPreview: any;
  loading: any;
  setImgPreview: any;
  confirmEmail: any;
  setAuthData: any;
  AuthData: authDataType;
  UpdateAuthData: any;
  handleSubmit: any;
  renderDragMessage: any;
  getRootProps: any;
  getInputProps: any;
  confirmUserEmailExist: any;
  isDragActive: any;
  isDragReject: any;
  handleCreateCodigo: any;
  handleSubmitCodigo: any;
  handlRecoveryCode: any;
  refs: any;
  loadingCodigo: any;
  setStep: any;
  codigo: any;
  ChangePassword: any;
};

export interface IFile {
  id: string;
  name: string;
  readableSize: any;
  preview: string;
  file: File | null;
}

export type authDataType = {
  username: string;
  password: string;
  confirmPassword?: string;
  check: boolean;
  img: any;
  userInfo: {
    name: string;
    lastName: string;
    cpf: string;
    rg: string;
    ie: string;
    cnpj: string;
    cnae: string;
    personType: 'juridica' | 'fisica';
    enterpriseSocial: string;
    enterpriseName: string;
    address: string;
    number: string;
    phone: string;
    complement: string;
    birthDate: string;
    city: string;
    state: string;
    zipCode: string;
    email: string;
    fantasyName: string;
    instagram: string;
  };
};
