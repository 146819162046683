import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { imgs } from 'src/assets/imgs';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 0, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <img style={{ height: '80px' }} src={imgs.logoDegrade} alt="logo" />
    </LogoWrapper>
  );
}

export default Logo;
