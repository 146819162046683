/* eslint-disable */
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
// import { Stepper } from "./registerSteps/stepper";
import { useRegister } from 'src/contexts/RegisterContext';
import useViewport from 'src/hooks/useViewport';
import * as Style from './FormRegister.style';
import { Steppers } from './registerSteps/stepper';

import CloseIcon from '@mui/icons-material/Close';

export const FormRegister = ({ onClose }: { onClose?: any }) => {
  const {
    handleSubmit,
    AuthData,
    handleCreateCodigo,
    step,
    lastStep,
    confirmEmail,
    setStep,
    loading,
    refs,
    handleSubmitCodigo,
    confirmUserEmailExist,
  } = useRegister();

  const viewport = useViewport();

  const confirmStepRegister = async () => {
    if (step === 0) {
      if (AuthData.password !== AuthData.confirmPassword) {
        return toast('Senhas não conferem, digite novamente para continuar', {
          type: 'warning',
        });
      }
      if (
        !AuthData.userInfo.name ||
        !AuthData.username ||
        !AuthData.userInfo.lastName
      ) {
        return toast('Preencha todos os dados para continuar', {
          type: 'warning',
        });
      }

      const confirmExistEmail = await confirmUserEmailExist();
      if (!confirmExistEmail) {
        if (!confirmEmail) {
          handleCreateCodigo();
        }
        return setStep((step) => step + 1);
      }
      return toast('Email existente!', {
        type: 'warning',
      });
    }
    if (step === 1) {
      if (!confirmEmail) {
        let resCode = await handleSubmitCodigo();
        if (!resCode) return;
      }
      setStep((step) => step + 1);
    }

    if (step === 2) {
      if (
        // !AuthData.userInfo.enterpriseName ||
        !AuthData.userInfo.address ||
        !AuthData.userInfo.zipCode ||
        !AuthData.userInfo.city ||
        !AuthData.userInfo.number ||
        !AuthData.userInfo.state
      ) {
        return toast('Preencha todos os dados para continuar', {
          type: 'warning',
        });
      }

      if (AuthData.userInfo.personType === 'juridica') {
        if (
          !AuthData.userInfo.cnpj ||
          !AuthData.userInfo.enterpriseSocial ||
          !AuthData.userInfo.fantasyName
        ) {
          return toast('Preencha todos os dados para continuar', {
            type: 'warning',
          });
        }
      } else {
        if (!AuthData.userInfo.cpf) {
          return toast('Preencha todos os dados para continuar', {
            type: 'warning',
          });
        }
      }

      setStep((step) => step + 1);
    }
  };

  return (
    <Style.RegisterContainer>
      <Style.AreaSteps>
        {Steppers.map((stepper, index) => {
          return (
            <Style.AreaStep>
              <Style.StepNumber
                active={index === step}
                completed={step > index}
              >
                <p>{step > index ? '✓' : stepper.number}</p>
              </Style.StepNumber>
              <Style.StepTitle>
                <p>{stepper.title}</p>
              </Style.StepTitle>
            </Style.AreaStep>
          );
        })}
        <Box
          sx={{
            right: 0,
            position: 'absolute',
            top: 0,
          }}
        >
          <IconButton onClick={() => onClose && onClose(true)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Style.AreaSteps>

      <div>{Steppers[step].component}</div>

      <Style.AreaButtons mobile={viewport.isMobile}>
        <Button
          variant={step === 0 ? 'contained' : 'outlined'}
          onClick={() => setStep((step) => step - 1)}
          sx={{
            border: step !== 0 && 'rgba(127,0,250,0.7) solid 1px',
            color: step !== 0 && 'rgba(127,0,250,0.7)',
            '&:hover': { color: 'rgba(127,0,250,0.6)' },
            fontFamily: 'Inter',
            mb: '10px',
            mt: '10px',
          }}
          disabled={step === 0 ? true : false}
        >
          Voltar
        </Button>

        {loading && (
          <Button type="submit" fullWidth sx={{ maxHeight: '36.5px' }}>
            <CircularProgress />
          </Button>
        )}

        {!loading && (
          <Button
            type={step < lastStep ? 'button' : 'submit'}
            variant="contained"
            onClick={step < lastStep ? confirmStepRegister : handleSubmit}
            sx={{
              background: 'rgba(127,0,250,0.7)',
              '&:hover': { background: 'rgba(127,0,250,0.6)' },
              fontFamily: 'Inter',
              mb: '10px',
              mt: '10px',
            }}
            color="success"
          >
            {step < lastStep ? 'Próxima Etapa' : 'Criar conta'}
          </Button>
        )}
      </Style.AreaButtons>
    </Style.RegisterContainer>
  );
};
