const getUserToken = () => {
  const token = localStorage.getItem('accessToken');
  return token;
};

const updateUserToken = (value: string) => {
  localStorage.setItem('accessToken', value);
};

const removeUserToken = () => {
  localStorage.removeItem('accessToken');
  return (window.location.pathname = '/login');
};

export { getUserToken, removeUserToken, updateUserToken };
