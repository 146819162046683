import {
  Store,
  categoryCommissionType,
  productCommissionType,
  storeCommissionType,
} from 'ecommersys/dist/Entities';
import { ChangeEvent, createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { sdk } from '../../sdkProvider';

import { GlobalCommissionType } from 'ecommersys/dist/Entities';
import { useUser } from 'src/contexts/UserContext';

import { useTranslation } from 'react-i18next';

const AdminCommissionContext = createContext<props>(null);

export const AdminCommissionProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { t }: { t: any } = useTranslation();

  const { user } = useUser();

  const [globalSingleCommission, setGlobalSingleCommission] = useState<
    Partial<GlobalCommissionType>
  >(initialSingleCommission);

  const [storeSingleCommission, setStoreSingleCommission] =
    useState<Partial<storeCommissionType> | null>(null);

  const [categories, setCategories] = useState<categoryCommissionType[]>([]);
  const [products, setProducts] = useState<productCommissionType[]>([]);

  const [storeList, setStoreList] = useState<Partial<Store[]>>([]);
  const [selectStore, setSelectStore] = useState<Partial<Store> | null>(null);

  /**
   * Commission Global Functions
   */

  const handleUpdateGlobalSingleCommission = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;

    return setGlobalSingleCommission((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleGetGlobalSingleCommission = () =>
    sdk.Admin.comissionGlobalGetSingle(
      { key: '_id', value: '6466802a2b32e14f35994afe' },
      (res) => {
        setGlobalSingleCommission(res);
      },
    );

  const handleConfirmCode = async (code: number, handleClose: Function) => {
    sdk.Global.confirmEmailToken(
      { email: user.userInfo.email, code },
      (res) => {
        sdk.Admin.comissionGlobalUpdateOne(
          {
            commissionId: '6466802a2b32e14f35994afe',
            data: globalSingleCommission,
          },
          (res) => {
            toast('Dados atualizados com sucesso!', {
              type: 'success',
              delay: 4000,
            });

            handleClose();
          },
        );
      },
      (err) => {
        toast(
          'Não foi possível confirmar o código, verifique o e-mail e tente novamente.',
          { type: 'error', delay: 4000 },
        );
      },
    );
  };

  const handleSendEmailToken = () => {
    sdk.Global.sendEmailToken(
      { email: user.userInfo.email },
      (res) => {
        toast('Você receberá um código de confirmação por email!', {
          type: 'info',
          delay: 4000,
        });
      },
      (err) => {
        toast(
          'Não conseguimos enviar o código por email, acione o suporte da plataforma.',
          { type: 'error', delay: 4000 },
        );
      },
    );
  };

  /**
   * Commission Store Functions
   */

  const handleUpdateStoreSingleCommission = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;

    return setStoreSingleCommission((state) => ({
      ...state,
      storeId: selectStore._id,
      [name]: value,
    }));
  };

  const handleSetSelectStore = (name: string) => {
    const filterStoreSelect: Partial<Store> = storeList.find(
      (item) => item.name === name,
    );
    setSelectStore(filterStoreSelect);

    if (filterStoreSelect?._id) {
      handleStoreGetSingleCommission(filterStoreSelect._id);
    }
  };

  const handleStoreGetAll = (filter) => {
    sdk.Global.getAllSellers({ filter, page: 0, size: 9999999 }, (res) => {
      if (!res.result || res.result.length === 0) {
        toast('Nenhuma loja encontrada para essa pesquisa!', {
          type: 'info',
          delay: 5000,
        });
      } else {
        setStoreList(res.result);
      }
    });
  };

  const handleStoreGetSingleCommission = async (_id: string) => {
    sdk.Admin.comissionStoreGetSingle(
      { key: 'storeId', value: _id },
      (res) => {
        setStoreSingleCommission((state) => ({ ...state, ...res }));
      },
      (err) => {
        setStoreSingleCommission({
          storeId: null,
          commissionType: globalSingleCommission.commissionType,
          fixed: globalSingleCommission.fixed,
          percentage: globalSingleCommission.percentage,
        });
      },
    );
  };

  const handleStoreAddCommission = async (handleClose: Function) => {
    if (storeSingleCommission?._id) {
      handleStoreUpdateOne();
      handleClose();
      return;
    }

    sdk.Admin.comissionStoreAdd(
      { ...storeSingleCommission, storeId: selectStore._id },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 3000,
        });

        handleClose();
      },
    );
  };

  const handleStoreUpdateOne = async (handleClose?: Function) => {
    sdk.Admin.comissionStoreUpdateOne(
      {
        commissionId: storeSingleCommission?._id,
        data: storeSingleCommission,
      },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 4000,
        });

        if (handleClose) {
          handleClose();
        }
      },
    );
  };

  /**
   * Commission Category Functions
   */

  const getAllCategoriesBySeller = () => {
    sdk.Admin.comissionCategoriesByStore(selectStore._id, (res) => {
      setCategories(res);
    });
  };

  const handleCategoryAddCommission = async (
    category,
    handleClose: Function,
  ) => {
    if (category._id) {
      handleCategoryUpdateOne(category, handleClose);
      return;
    }

    sdk.Admin.comissionCategoryAdd(
      { ...category, storeId: selectStore._id },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 3000,
        });

        handleClose();
      },
    );
  };

  const handleCategoryUpdateOne = async (category, handleClose?: Function) => {
    sdk.Admin.comissionCategoryUpdateOne(
      {
        commissionId: category?._id,
        data: category,
      },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 4000,
        });

        if (handleClose) {
          handleClose();
        }
      },
    );
  };

  /**
   * Commission Products Functions
   */

  const getAllProductsBySeller = () => {
    sdk.Admin.comissionProductsByStore(selectStore._id, (res) => {
      setProducts(res);
    });
  };

  const handleProductAddCommission = async (product, handleClose: Function) => {
    if (product._id) {
      handleProductUpdateOne(product, handleClose);
      return;
    }

    sdk.Admin.comissionProductAdd(
      { ...product, storeId: selectStore._id },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 3000,
        });

        handleClose();
      },
    );
  };

  const handleProductUpdateOne = async (product, handleClose?: Function) => {
    sdk.Admin.comissionProductUpdateOne(
      {
        commissionId: product?._id,
        data: product,
      },
      (res) => {
        toast('Operação realizada com sucesso!', {
          type: 'success',
          delay: 4000,
        });

        if (handleClose) {
          handleClose();
        }
      },
    );
  };

  const values: props = {
    // Global Cliniks
    globalSingleCommission,
    handleGetGlobalSingleCommission,
    handleUpdateGlobalSingleCommission,
    handleConfirmCode,
    handleSendEmailToken,
    // Store
    selectStore,
    setSelectStore,
    storeList,
    setStoreList,
    handleStoreGetAll,
    handleStoreUpdateOne,
    storeSingleCommission,
    handleUpdateStoreSingleCommission,
    handleStoreAddCommission,
    handleStoreGetSingleCommission,
    handleSetSelectStore,
    // Category
    categories,
    getAllCategoriesBySeller,
    handleCategoryAddCommission,
    handleCategoryUpdateOne,
    // Product
    products,
    getAllProductsBySeller,
    handleProductAddCommission,
    handleProductUpdateOne,
  };

  return (
    <AdminCommissionContext.Provider value={values}>
      {children}
    </AdminCommissionContext.Provider>
  );
};

export const useAdminCommission = () => useContext(AdminCommissionContext);

type props = {
  // Global Cliniks
  globalSingleCommission: Partial<GlobalCommissionType>;
  handleGetGlobalSingleCommission: () => void;
  handleUpdateGlobalSingleCommission: any;
  handleConfirmCode: any;
  handleSendEmailToken: any;
  // Store
  selectStore: Partial<Store>;
  setSelectStore: any;
  storeList: Partial<Store[]>;
  setStoreList: any;
  handleStoreGetAll: any;
  handleStoreUpdateOne: any;
  storeSingleCommission: Partial<storeCommissionType>;
  handleUpdateStoreSingleCommission: any;
  handleStoreAddCommission: any;
  handleStoreGetSingleCommission: any;
  handleSetSelectStore: any;
  // Category
  categories: Partial<categoryCommissionType[]>;
  getAllCategoriesBySeller: any;
  handleCategoryAddCommission: any;
  handleCategoryUpdateOne: any;
  // Product
  products: Partial<productCommissionType[]>;
  getAllProductsBySeller: any;
  handleProductAddCommission: any;
  handleProductUpdateOne: any;
};

const initialSingleCommission: Partial<GlobalCommissionType> = {
  commissionType: 'percentage',
  percentage: 10,
  fixed: 0,
  scalable: [],
};
