import { Add, Remove } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  Typography,
  useTheme,
} from '@mui/material';
import { ProductsReturn, Store } from 'ecommersys/dist/Entities';
import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { icons } from 'src/assets/imgs';
import { useCart } from 'src/contexts/UserCartContext';
import { sdk } from 'src/sdkProvider';
import { L } from 'src/types/languageType';
import { formatReal } from 'src/utils/functions';

export const CartProduct = ({ product }: { product: ProductsReturn }) => {
  const theme = useTheme();
  const storeName = useRef(null);
  const { incrementProduct, decrementProduct, removeProduct } = useCart();
  const { t }: L = useTranslation();

  const getSellerName = async (): Promise<Store> =>
    await sdk.Global.getSingleSellers(
      { key: '_id', value: product.owner },
      (res) => {
        storeName.current.innerHTML = `Loja: ${res.name}`;
      },
    );

  useEffect(() => {
    if (product) getSellerName();
  }, [product]);

  if (!product)
    return <Typography>Você não possui produtos adicionados</Typography>;

  return (
    <ListItem key={product.productId}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'}>
          <ListItemAvatar>
            <Avatar
              sx={{ width: 50, height: 50 }}
              variant="square"
              alt={`Avatar n°${product.productId + 1}`}
              src={product.imgs[0] || icons.acordoIco}
            />
          </ListItemAvatar>

          <Grid container>
            <Grid
              item
              sm={10}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                {product.name.substring(0, 13)}
                {product.name?.length > 13 ? '...' : ''}
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                <b style={{ color: 'lime-green' }}>{product.amount}x</b>
                {formatReal(product.price)}
              </Typography>

              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 500,
                }}
                ref={storeName}
              ></Typography>
              {product.discountValue && (
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 300,
                  }}
                >
                  <b style={{ color: 'lime-green' }}>{t('discount')}: </b>
                  {formatReal(product.discountValue * product.amount)}
                </Typography>
              )}
            </Grid>
            <Box
              sx={{
                width: 10,
                height: 10,
                display: 'flex',
                marginLeft: '10px',
                justifyContent: 'right',
                alignItems: 'right',
                p: 0,
                m: 0,
              }}
            >
              <Avatar
                sx={{
                  width: 5,
                  height: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                  background: theme.colors.primary.light,
                  fontSize: 10,
                }}
                alt={`Avatar n°${product._id + 1}`}
              >
                {` ${product.amount}`}
              </Avatar>
            </Box>
          </Grid>

          <Grid item sm={2}>
            <Button onClick={() => incrementProduct(product._id)}>
              <Add fontSize="small" />
            </Button>
            <Button onClick={() => decrementProduct(product._id)}>
              <Remove fontSize="small" />
            </Button>
          </Grid>
        </Box>
        <Box>
          <Button onClick={() => removeProduct(product._id, product.amount)}>
            Remover Produto
          </Button>
        </Box>
      </Box>
    </ListItem>
  );
};
