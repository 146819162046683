import { storeInfo } from 'ecommersys/dist/Entities';
import {
  ChangeEvent,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { getAllProps } from 'ecommersys/dist/interfaces';

import { Zoom } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { initialClientState } from 'src/components/Dashboard/Clients/configs/tableConfig';
import useRefMounted from 'src/hooks/useRefMounted';
import { AdminClientType, ClientType } from 'src/models/client';
import { api } from 'src/services/axiosInstance';
import { Sales } from './SellerOrdersContext';
import { initialProductState } from './SellerProductsContext';

const SellerClientsContext = createContext<props>(null);

export const SellerClientsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { t }: { t: any } = useTranslation();

  const [client, setClient] = useState<Partial<ClientType>>(initialClientState);
  const [clients, setClients] = useState<ClientType[]>([]);

  const [store, setStore] = useState<Partial<storeInfoType>>(initialStoreState);
  const [stores, setStores] = useState<storeInfoType[]>([]);

  const [orders, setOrders] = useState<Sales[]>([]);

  const [order, setOrder] = useState<Partial<Sales>>(initialProductState);

  const [adminClients, setAdminClients] = useState<AdminClientType[]>([]);

  const [loader, setLoader] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [selectedItems, setSelectedClients] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const isMountedRef = useRefMounted();

  const [query, setQuery] = useState<getAllProps>({
    page: 0,
    size: 10,
    filter: {
      key: '',
      value: '',
      fields: '',
    },
  });

  //CLientes do Seller
  const getSellerclients = async (queryProps: getAllProps = query) => {
    setLoader(true);
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter },
    };

    const getClients = await api.get('/clients/myClients', {
      params: queryParam,
    });

    setClients(getClients.data);
    setTotalItems(getClients.data.length);
    setLoader(false);
  };

  const getSellerclient = async (id: string) => {
    clients?.map((orders) =>
      orders.clientId === id ? setClient(orders) : null,
    );
  };

  //Clientes All
  const getUserClients = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter, fields: 'userInfo username img' },
    };

    const getClients = await api.get('/users/all', { params: queryParam });

    setTotalItems(getClients.data.totalItems);
    setAdminClients(getClients.data.result);
  };

  const getUserOrders = async (id?: string) => {
    setLoader(true);
    const queryParam = {
      ...query,
      filter: {
        key: 'userId ' + query.filter.key,
        value: `${id} ` + query.filter.value,
      },
    };

    const getOrders = await api.get('/orders/all', {
      params: queryParam,
    });

    setOrders(getOrders.data.result);
    setLoader(false);
  };

  const getUserOrder = async (id: string) => {
    const queryParam = {
      ...query,
      filter: { key: '_id', value: id },
    };

    const getOrders = await api.get('/sales/all', {
      params: queryParam,
    });

    setOrders(getOrders.data.result);
  };

  const getBuyClient = async (id: string) => {
    orders.map((order) => (order._id === id ? setOrder(order) : null));
  };

  //Seller
  const getStores = async (queryProps: getAllProps = query) => {
    const queryParam = {
      ...queryProps,
      filter: { ...queryProps.filter },
    };

    const getListstores = await api.get('/sellers/all', {
      params: queryParam,
    });

    setStores(getListstores.data.result);
    setTotalItems(getListstores.data.totalItems);
  };

  const getStore = async (id) => {
    stores?.map((listStores) =>
      listStores._id === id ? setStore(listStores) : null,
    );
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const HandleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the coupon'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Zoom,
    });
  };

  const handleSelectAllCLients = (): void => {
    const selectAll: string[] = clients.map(
      (client) => client.clientId as string,
    );
    setSelectedClients((selecteds) =>
      selecteds.length === 0 ? selectAll : [],
    );
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        key: 'clientName',
        value: event.target.value,
      },
    }));
  };

  const handleQueryChangeOrder = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    setQuery((state) => ({
      ...state,
      filter: {
        key: '_id',
        value: event.target.value,
      },
    }));
  };

  const handleSearchByQuery = (e) => {
    e.preventDefault();
    getSellerclients();
  };

  const handleSearchByQueryCLient = (id) => {
    getUserOrders(id);
  };

  const handleSelectAllclients = (): void => {
    const selectAll: string[] = clients.map(
      (client) => client.clientId as string,
    );

    setSelectedClients((selecteds) =>
      selecteds.length === 0 ? selectAll : [],
    );
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setQuery((query) => ({ ...query, page: newPage }));
    getSellerclients({ ...query, page: newPage });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery((query) => ({ ...query, size: parseInt(value), page: 0 }));
    getSellerclients({ ...query, size: parseInt(value), page: 0 });
  };

  const values: props = {
    loader,
    client,
    clients,
    setClients,
    query,
    setQuery,
    getSellerclients,
    getSellerclient,
    getUserClients,
    handleQueryChange,
    handleSearchByQuery,
    handleSelectAllclients,
    handlePageChange,
    handleLimitChange,
    selectedItems,
    setSelectedClients,
    HandleDeleteCompleted,
    handleSelectAllCLients,
    handleConfirmDelete,
    adminClients,
    getStores,
    getUserOrders,
    stores,
    orders,
    getBuyClient,
    order,
    handleQueryChangeOrder,
    totalItems,
    getStore,
    store,
    setOrders,
    handleSearchByQueryCLient,
  };

  return (
    <SellerClientsContext.Provider value={values}>
      {children}
    </SellerClientsContext.Provider>
  );
};

export const useSellerClients = () => useContext(SellerClientsContext);

type props = {
  loader: boolean;
  order: any;
  orders: Sales[];
  setOrders: any;
  store: Partial<storeInfoType>;
  client: Partial<ClientType>;
  stores: storeInfoType[];
  adminClients: AdminClientType[];
  getStores: any;
  getUserOrders: any;
  getStore: any;
  clients: ClientType[];
  setClients: React.Dispatch<SetStateAction<ClientType[]>>;
  query: getAllProps;
  setQuery: React.Dispatch<SetStateAction<getAllProps>>;
  getSellerclients: any;
  getUserClients: any;
  getSellerclient: any;
  handleQueryChange: any;
  handleQueryChangeOrder: any;
  handleSearchByQuery: any;
  handleSelectAllclients: any;
  handlePageChange: any;
  handleLimitChange: any;
  selectedItems: any;
  setSelectedClients: any;
  HandleDeleteCompleted: any;
  handleSelectAllCLients: any;
  handleConfirmDelete: any;
  getBuyClient: any;
  totalItems: number;
  handleSearchByQueryCLient: any;
};

export type objRefs = {
  name: MutableRefObject<any>;
  description: MutableRefObject<any>;
  price: MutableRefObject<any>;
  regularPrice: MutableRefObject<any>;
  status: MutableRefObject<any>;
  shippingInfo: {
    weight: MutableRefObject<any>;
    height: MutableRefObject<any>;
    width: MutableRefObject<any>;
  };
  stockInfo: {
    qnt: MutableRefObject<any>;
    sku: MutableRefObject<any>;
    SoldIndividually: MutableRefObject<any>;
  };
  virtualProduct: MutableRefObject<any>;
};

export type storeInfoType = {
  asaasApiKey: string;
  asaasID: string;
  asaasWalletId: string;
  banner: string;
  img: string;
  isActive: string;
  messages: string[];
  name: string;
  openOrders: string[];
  owner: string;
  products: string[];
  productsCount: number;
  salesHistory: string[];
  salesToSend: string[];
  segments: string[];
  statistics: string[];
  storeInfo: storeInfo;
  _id: string;
  createdAt: string;
  updatedAt: string;
  wallet: string[];
  __v: string;
};

export const initialStoreState: Partial<storeInfoType> = {
  asaasApiKey: '',
  asaasID: '',
  asaasWalletId: '',
  banner: '',
  img: '',
  isActive: '',
  messages: [],
  name: '',
  openOrders: [],
  owner: '',
  products: [],
  productsCount: 0,
  salesHistory: [],
  salesToSend: [],
  segments: [],
  statistics: [],
  storeInfo: {
    cnpj: '',
    cnae: '',
    address: '',
    number: '',
    cpf: '',
    complement: '',
    birthDate: '',
    enterpriseSocial: '',
    enterpriseName: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    district: '',
    zipCode: '',
    email: '',
  },
  _id: '',
  createdAt: '',
  updatedAt: '',
  wallet: [],
  __v: '',
};
