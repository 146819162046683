import { Box, Dialog, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Check } from "@mui/icons-material";
import { notificationTypes } from "ecommersys/dist/Entities/notification.entitie";
import DraftsIcon from "@mui/icons-material/Drafts";
export const PopUpDefault = ({
  data,
  review,
  handleReadNotification,
}: {
  data?: Partial<notificationTypes>;
  review?: boolean;
  handleReadNotification?: any;
}) => {
  const [open, setOpen] = useState(true);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const toggleOpen = () => setOpen((state) => !state);
  return (
    <>
      <Dialog
        sx={{
          height: "100vh",
        }}
        PaperProps={{
          style: { minWidth: "30vw", minHeight: "20vw", padding: "1rem" },
        }}
        open={open}
      >
        <Box display={"flex"} justifyContent={"right"}>
          <IconButton
            size="medium"
            onClick={() => {
              setOpen(false);
              handleReadNotification(data._id);
            }}
            sx={{ mt: 1, mr: 1, maxWidth: 50, maxHeight: 50 }}
          >
            <HighlightOffIcon fontSize="large" sx={{ color: "#aa53fc" }} />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mb="15px"
        >
          <DraftsIcon
            htmlColor="#aa53fc"
            sx={{ width: "120px", height: "120px" }}
          />

          <Typography variant="h2">{data.title}</Typography>
          <Typography fontSize="18px" variant="body1">
            {data.message}
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};
