import { Outlet } from "react-router-dom";

function RegisterLayout() {
  return (
    <section style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <Outlet />
    </section>
  );
}
export { RegisterLayout };
