import { Check } from '@mui/icons-material';
import { Box, Divider, FormLabel, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useRegister } from 'src/contexts/RegisterContext';

export const FinishStep = () => {
  const { AuthData, UpdateAuthData } = useRegister();

  const confirmName = (name: string) => {
    const names = {
      name: 'Nome',
      lastName: 'Sobrenome',
      email: 'Email',

      cpf: 'CPF',
      cnpj: 'CNPJ',
      cnae: 'CNAE',
      enterpriseName: 'Nome Fantasia',
      enterpriseSocial: 'Razão Social',

      personType: 'Tipo',

      birthDate: 'Data de Nascimento',

      phone: 'Telefone',

      zipCode: 'CEP',
      address: 'Endereço',
      number: 'Número',
      district: 'Bairro',
      complement: 'Complemento',
      city: 'Cidade',
      state: 'Estado',
      country: 'País',
    };
    return names[name];
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {Object.keys(AuthData).map((item, index) => {
          if (
            item !== 'password' &&
            item !== 'confirmPassword' &&
            item !== 'check' &&
            item !== 'username' &&
            item !== 'img'
          ) {
            return (
              <Box sx={{ mt: 2, width: '100%' }}>
                <Typography
                  sx={{
                    fontWeight: 900,
                    mb: 1,
                    textTransform: 'uppercase',
                    fontFamily: 'Inter',
                    fontSize: '0.9em',
                  }}
                  variant="h4"
                >
                  Dados:
                </Typography>
                <Divider sx={{ my: 1 }}></Divider>
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Inter',
                    fontSize: '0.9em',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 900,
                      mr: 1,
                      textTransform: 'uppercase',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    Nome:{' '}
                  </Typography>{' '}
                  {AuthData.userInfo.name}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Inter',
                    fontSize: '0.9em',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 900,
                      mr: 1,
                      textTransform: 'uppercase',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    email:
                  </Typography>{' '}
                  {AuthData.userInfo.email}
                </Typography>
                {AuthData.userInfo.personType !== 'juridica' && (
                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        mr: 1,
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      cpf:
                    </Typography>{' '}
                    {AuthData.userInfo.cpf}
                  </Typography>
                )}

                {AuthData.userInfo.personType === 'juridica' && (
                  <Fragment>
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 900,
                          mr: 1,
                          textTransform: 'uppercase',
                          fontFamily: 'Inter',
                          fontSize: '0.9em',
                        }}
                      >
                        CNPJ:
                      </Typography>{' '}
                      {AuthData.userInfo.cnpj}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 900,
                          mr: 1,
                          textTransform: 'uppercase',
                          fontFamily: 'Inter',
                          fontSize: '0.9em',
                        }}
                      >
                        Razão Social:
                      </Typography>{' '}
                      {AuthData.userInfo.enterpriseSocial}
                    </Typography>

                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 900,
                          mr: 1,
                          textTransform: 'uppercase',
                          fontFamily: 'Inter',
                          fontSize: '0.9em',
                        }}
                      >
                        Nome Fantasia:
                      </Typography>{' '}
                      {AuthData.userInfo.fantasyName}
                    </Typography>
                  </Fragment>
                )}
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Inter',
                    fontSize: '0.9em',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 900,
                      mr: 1,
                      textTransform: 'uppercase',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    Tipo:
                  </Typography>{' '}
                  {AuthData.userInfo.personType}
                </Typography>

                <Box mt={1}>
                  <Typography
                    sx={{
                      fontWeight: 900,
                      mt: 4,
                      mb: 1,
                      textTransform: 'uppercase',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                    variant="h4"
                  >
                    Endereço:
                  </Typography>
                  <Divider sx={{ my: 1 }}></Divider>
                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        mr: 1,
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      Rua:
                    </Typography>
                    {AuthData.userInfo.address}
                  </Typography>

                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        mr: 1,
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      Número:
                    </Typography>{' '}
                    {AuthData.userInfo.number}
                  </Typography>

                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        mr: 1,
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      Complemento:
                    </Typography>{' '}
                    {AuthData.userInfo.complement}
                  </Typography>

                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Inter',
                      fontSize: '0.9em',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        mr: 1,
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontSize: '0.9em',
                      }}
                    >
                      CEP:
                    </Typography>{' '}
                    {AuthData.userInfo.zipCode}
                  </Typography>
                </Box>
              </Box>
            );
          }
        })}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '5px',
          cursor: 'pointer',
        }}
        onClick={() =>
          UpdateAuthData({ target: { name: 'check', value: !AuthData.check } })
        }
      >
        <div
          style={{
            width: '25px',
            height: '25px',
            border: '2px solid #ccc',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 5px 0 0',
          }}
        >
          {AuthData.check ? <Check color="success" /> : null}
        </div>
        <FormLabel sx={{ cursor: 'pointer', fontFamily: 'Inter' }}>
          Concordo com os termos e condições.
        </FormLabel>
      </div>
    </>
  );
};
