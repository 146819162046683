import { createContext, useContext, useState } from 'react';
// import { sdk } from "src/sdkProvider/cliniks";

const DashboardContext = createContext(null);

export const DashboardProvider = ({ children }) => {
  const [sellerData, setSellerData] = useState();

  const values = { sellerData, setSellerData };

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
