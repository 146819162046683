/* eslint-disable */
import { ArrowUpward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { TopHomeMenu } from 'src/components/Menus/topHomeMenu';

import { useUser } from 'src/contexts/UserContext';

const HomeLayout = ({ children }: any) => {
  const [scrolled, setScrolled] = useState(false);
  const { loading } = useUser();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [loading]);

  window.addEventListener('scroll', (e) => {
    window.pageYOffset > 0 ? setScrolled(true) : setScrolled(false);
  });

  return (
    <div className="homeLayout">
      <Helmet>
        <title>Cliniks</title>
      </Helmet>

      <HomeLayoutComponent.topMenu />
      {children || <Outlet />}
      <HomeLayoutComponent.footer />

      <button
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: scrolled ? 1 : 0,
          pointerEvents: scrolled ? 'all' : 'none',
          cursor: 'pointer',
          transition: 'opacity ease 0.7s',
          width: '40px',
          height: '40px',
          background: 'white',
          border: 'rgba(0,0,0,0.5) solid 1px',
          color: 'rgba(0,0,0,0.5)',
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ArrowUpward />
      </button>
    </div>
  );
};

let HomeLayoutComponent = {
  topMenu: () => <TopHomeMenu />,
  footer: () => <Footer />,
};

export { HomeLayout };
