import {
  Avatar,
  Box,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
export const ListOrderProductsDialog = ({
  children,
  data,
  review,
}: {
  children: React.ReactNode;
  data: any;
  review?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { t }: { t: any } = useTranslation();

  const toggleOpen = () => setOpen((state) => !state);
  return (
    <>
      <Box onClick={toggleOpen}>{children}</Box>
      <Dialog
        sx={{
          height: '100vh',
        }}
        PaperProps={{
          style: { minWidth: '30vw', padding: '1rem' },
        }}
        open={open}
        onClose={toggleOpen}
      >
        <Box display={'flex'} justifyContent={'right'}>
          <IconButton
            size="medium"
            onClick={() => setOpen(false)}
            sx={{ mr: 2, maxWidth: 50, maxHeight: 50 }}
          >
            <HighlightOffIcon fontSize="large" sx={{ color: '#aa53fc' }} />
          </IconButton>
        </Box>

        <Grid
          container
          sx={{
            p: 2,
          }}
        >
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h3">
              {t(`Order`)} #{data?.id || data?.orderId}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">{t(`List Products`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                maxWidth: 600,
                bgcolor: 'background.paper',
                m: 0,
              }}
            >
              {data.sellers &&
                data.sellers[0] &&
                data.sellers?.map((listItems) => (
                  <>
                    {listItems?.products?.map((products) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={`${products.imgs[0]}`}></Avatar>
                        </ListItemAvatar>
                        <Box>
                          <Typography variant="h5"> {products.name}</Typography>
                          <Box display={'flex'}>
                            <Typography variant="subtitle1">
                              {products.categories.map((listCategorires) => (
                                <Box>
                                  <Typography>
                                    {listCategorires.split('/')[1]}
                                  </Typography>
                                </Box>
                              ))}
                            </Typography>
                          </Box>
                          <Typography variant="subtitle1">
                            Quantidade: {products.amount}
                          </Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </>
                ))}

              {data?.products?.map((listItems) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={`${listItems.imgs[0]}`}></Avatar>
                  </ListItemAvatar>
                  <Box>
                    <Typography variant="h5"> {listItems.name}</Typography>
                    <Box display={'flex'}>
                      <Typography variant="subtitle1">
                        {listItems.categories.map((listCategorires) => (
                          <Box>
                            <Typography>
                              {listCategorires.split('/')[1]}
                            </Typography>
                          </Box>
                        ))}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        {listItems.amount}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
