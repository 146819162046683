import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSolicitation } from 'src/contexts/SolicitationsContext';
import { useUser } from 'src/contexts/UserContext';
import { UserDocumentsProvider } from 'src/contexts/UserDocumentsContext';
import { SellerSolicitationForm } from '../Forms/SellerSolicitation';

const steps = ['Solicitando', 'Aguardando Autorização', 'Loja Criada!'];

export const SolicitationStatus = () => {
  const [activeStep, setActiveStep] = useState<number | null>(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const navigate = useNavigate();

  const { status, singleSolicitation, verify } = useSolicitation();
  const { user } = useUser();

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (status) setActiveStep(1);

    if (!singleSolicitation) return setActiveStep(0);
    if (
      singleSolicitation._id &&
      singleSolicitation.owner._id === user._id &&
      singleSolicitation.isActive
    ) {
      return setActiveStep(2);
    }
  }, [status, singleSolicitation]);

  useEffect(() => {
    verify();
  }, []);

  if (activeStep === 2) navigate('/dashboard/seller/store', { replace: true });

  // if (!activeStep) return <CircularProgress />;

  return (
    <Grid xs={12} p={2} sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length && (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      )}
      {activeStep === 0 && (
        <UserDocumentsProvider>
          <SellerSolicitationForm />
        </UserDocumentsProvider>
      )}
      {activeStep === 1 && (
        <Grid
          container
          component={Card}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          p={5}
          gap={2}
        >
          <Box
            sx={{
              p: 3,
              width: '200px',
              height: '200px',
              borderRadius: '50%',
              display: 'grid',
              placeItems: 'center',
              background: 'rgba(150,240,150,0.5)',
            }}
          >
            <Check
              color="success"
              fontSize="large"
              sx={{ width: '120px', height: '120px' }}
            />
          </Box>
          <Typography variant="h1" color="rgba(50,200,50,1)">
            Solicitação realizada com sucesso!
          </Typography>
          <Typography variant="body1" color="rgba(0,0,0,0.5)">
            Aguarde a análise e retorno da nossa equipe.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
