import { DeleteTwoTone, LaunchTwoTone } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Chip,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { Coupon } from 'ecommersys/dist/Entities';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmDialog } from 'src/components/Dialogs/ConfirmDialog';
import { CouponDialong } from 'src/components/Dialogs/CouponDialong';
import {
  pathCurrentOrNextNavigate,
  showDateTimeString,
  statusActive,
} from 'src/utils/functions';
import { BoxConfirm } from '../../Policies/BoxConfirm';
import { DataSourceType } from '../Results';

export const couponsDataSource: DataSourceType[] = [
  {
    key: 'name',
    visible: true,
    title: 'Name',
  },
  {
    key: 'type',
    visible: true,
    title: 'Type',
  },
  {
    key: 'used',
    visible: true,
    title: 'Amount',
  },
  {
    key: 'owner',
    visible: false,
    title: 'Store',
  },
  {
    key: 'limitForUse',
    visible: true,
    title: 'Limit of use',
  },
  {
    key: 'usedCoupons',
    visible: true,
    title: 'Used',
  },
  {
    key: 'clientsAssigned',
    visible: true,
    title: 'Client Assigned',
    render: (coupon) => {
      return (
        <TableCell>
          {coupon.clientsAssigned.map((clients) => `${clients.split('/')[1]} `)}
        </TableCell>
      );
    },
  },

  {
    key: 'endDate',
    visible: true,
    title: 'Expiration date',
    render: (coupon) => {
      return <TableCell>{showDateTimeString(coupon.endDate)} </TableCell>;
    },
  },
  {
    key: 'isActive',
    visible: true,
    title: 'Status',
    render: (coupon) => {
      let textColor = '';
      let bgColor = '';

      if (coupon?.isActive === true) {
        bgColor = 'rgb(46, 125, 50)';
        textColor = 'white';
      }
      if (coupon?.isActive === false) {
        bgColor = 'rgb(211, 47, 47)';
        textColor = 'white';
      }

      return (
        <TableCell>
          <Box display="flex" alignItems="center" justifyContent="left">
            <Chip
              label={statusActive(coupon.isActive)}
              style={{
                background: bgColor,
                color: textColor,
                fontWeight: 600,
              }}
            />
          </Box>
        </TableCell>
      );
    },
  },
  {
    key: 'actions',
    visible: true,
    title: 'Actions',
    render: (coupon: Coupon, __, handleConfirmDelete: Function) => {
      return (
        <TableCell align="center">
          <Typography noWrap sx={{ display: 'flex', flexDirection: 'row' }}>
            <CouponDialong data={coupon} review={false}>
              <Tooltip title={'Show'} arrow>
                <IconButton color="primary">
                  <LaunchTwoTone fontSize="small" />
                </IconButton>
              </Tooltip>
            </CouponDialong>

            <Tooltip title={'Edit'} arrow>
              <IconButton
                component={RouterLink}
                to={`${pathCurrentOrNextNavigate()}/coupons/${coupon._id}`}
                color="primary"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <ConfirmDialog
              component={(handleClose) => {
                return (
                  <BoxConfirm
                    title="Coupom"
                    confirmDelete={() => handleConfirmDelete(coupon._id)}
                    handleClose={handleClose}
                  />
                );
              }}
            >
              <Tooltip title={'Delete'} arrow>
                <IconButton color="primary">
                  <DeleteTwoTone fontSize="small" />
                </IconButton>
              </Tooltip>
            </ConfirmDialog>
          </Typography>
        </TableCell>
      );
    },
  },
];

export const headerConfig: headerType = {
  _id: 'id',
  code: 'Code',
  type: 'Type',
  used: 'Amount',
  owner: 'Store',
  limitForUse: 'Limit of use',
  endDate: 'Expiration date',
  name: 'Actions',
};

type headerType = {
  _id: string;
  code: string;
  type: string;
  used: string;
  owner: string;
  limitForUse: string;
  endDate: string;
  name: string;
};

export const initialCoupomState: Partial<Coupon> = {
  name: '',
  type: 'fixed',
  used: 0,
  limitForUse: 0,
  productsAssigned: [],
  storesAssigned: [],
  clientsAssigned: [],
  categoriesAssigned: [],
  description: '',
  value: '',
  minValue: '',
  maxValue: '',
  isCashBack: false,
  isFreeShipping: false,
  endDate: new Date(),
  startDate: new Date(),
};

export const applyFilters = (coupons: Coupon[], query: string): Coupon[] => {
  return coupons.filter((coupon) => {
    let matches = true;

    if (query) {
      const properties = ['name'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (coupon[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

export const applyPagination = (
  products: Coupon[],
  page: number,
  limit: number,
): Coupon[] => {
  return products.slice(page * limit, page * limit + limit);
};
